import { Entity, Type } from "@jobilla/entity";
import { Company } from "./company";

export class UserInvite extends Entity {
  id!: number;
  name!: string;
  email!: string;

  @Type(() => Inviter)
  invitedBy!: Inviter;

  @Type(Company)
  company!: Company;
}

export class Inviter extends Entity {
  name!: string;
  email!: string;
}
