import { useEffect, useRef } from "react";
import { Fetcher } from "react-router-dom";

export function useOnFetcherSuccess(fetcher: Fetcher, callback: () => void) {
  const hasBeenSubmitting = useRef(false);

  useEffect(() => {
    if (hasBeenSubmitting.current && fetcher.state !== "submitting") {
      if (fetcher.data.status >= 200 && fetcher.data.status < 300) {
        callback();
      }

      hasBeenSubmitting.current = false;
    }

    if (!hasBeenSubmitting.current && fetcher.state === "submitting") {
      hasBeenSubmitting.current = true;
    }
  }, [hasBeenSubmitting, fetcher.state]);
}

export function useOnFetcherFail(fetcher: Fetcher, callback: () => void) {
  const hasBeenSubmitting = useRef(false);

  useEffect(() => {
    if (hasBeenSubmitting.current && fetcher.state !== "submitting") {
      if (fetcher.data.status >= 400) {
        callback();
      }

      hasBeenSubmitting.current = false;
    }

    if (!hasBeenSubmitting.current && fetcher.state === "submitting") {
      hasBeenSubmitting.current = true;
    }
  }, [hasBeenSubmitting, fetcher.state]);
}
