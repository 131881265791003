import { Await, useLoaderData } from "react-router-dom";
import { addHours, isPast, parseISO } from "date-fns";
import { Suspense } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";
import * as DL from "@/components/definition-list";
import StyledLink from "@/components/styled-link";
import Date, { DateDisplayType } from "@/components/date";
import { OrderItemStatus } from "@/entities/order-item-status";
import { Unit } from "@/entities/unit";
import PrimaryAnchor from "@/components/primary-anchor";
import { PrimaryLink } from "@/components/primary-link";
import { Tooltip } from "@/components/tooltip";
import { SpinnerIcon } from "@/components/icons/spinner";

const CAMPAIGN_REQUEST_CRITICAL_LIMIT_HOURS = 48;

export function Basics() {
  const { job, orderItem, units } = useLoaderData() as OrderItemDetailsData;

  if (!orderItem.campaignContext?.id) {
    return <p className="italic">There are no campaign details provided.</p>;
  }

  if (!orderItem.order) {
    return <p className="italic">Could not load order data.</p>;
  }

  const isCampaignRequestOlderThanLimit = isPast(
    addHours(parseISO(orderItem.campaignContext.createdAt), CAMPAIGN_REQUEST_CRITICAL_LIMIT_HOURS),
  );

  return (
    <>
      <DL.Root>
        <DL.Item label="Company name">
          {orderItem.order.company ? (
            <StyledLink to={`/companies/${orderItem.order.company.id}`}>
              {orderItem.order.company.name}
            </StyledLink>
          ) : (
            <span className="italic">Could not find company data.</span>
          )}
        </DL.Item>

        <DL.Item
          label={<Tooltip content="Job name in the campaign request">Job name (requested)</Tooltip>}
        >
          {orderItem.campaignContext.jobName}
        </DL.Item>

        {job instanceof Promise && (
          <DL.Item
            label={<Tooltip content="Title of the created recruitment">Job name (created)</Tooltip>}
          >
            <Suspense
              fallback={
                <span className="inline-flex items-center gap-2">
                  <SpinnerIcon className="w-3 h-3 animate-spin" /> <em>Loading job details</em>
                </span>
              }
            >
              <Await resolve={job}>{(resolvedJob) => resolvedJob.name}</Await>
            </Suspense>
          </DL.Item>
        )}

        <DL.Item
          label={
            <Tooltip content="Job location in the campaign request">
              Job location (requested)
            </Tooltip>
          }
        >
          {orderItem.campaignContext.jobLocation ?? (
            <span className="text-zinc-400">Not defined</span>
          )}
          {orderItem.campaignContext.boosterToken?.type === "range_booster" && " (+ Range booster)"}
        </DL.Item>

        {job instanceof Promise && (
          <DL.Item
            label={
              <Tooltip content="Location of the created recruitment">
                Job location (created)
              </Tooltip>
            }
          >
            <Suspense
              fallback={
                <span className="inline-flex items-center gap-2">
                  <SpinnerIcon className="w-3 h-3 animate-spin" /> <em>Loading job details</em>
                </span>
              }
            >
              <Await resolve={job}>
                {(resolvedJob) =>
                  resolvedJob.location ?? <span className="text-zinc-400">Not defined</span>
                }
              </Await>
            </Suspense>
          </DL.Item>
        )}

        <DL.Item label="Duration">
          {orderItem.campaignContext.duration}
          {orderItem.campaignContext.boosterToken?.type === "duration_booster" &&
            " (+ Duration booster)"}
        </DL.Item>

        <DL.Item label="Request time">
          <Date
            date={orderItem.campaignContext.createdAt}
            display={DateDisplayType.RELATIVE}
            renderContent={(dateString) => (
              <span
                className={
                  isCampaignRequestOlderThanLimit
                    ? "text-red-600"
                    : orderItem.status === OrderItemStatus.New
                    ? "text-green-500"
                    : ""
                }
              >
                {dateString}
              </span>
            )}
          />
        </DL.Item>

        <DL.Item label="Media Source">
          {orderItem.campaignContext.mediaSource?.replace(/_/g, " ") ?? (
            <span className="text-zinc-400">No media source</span>
          )}
        </DL.Item>

        <DL.Item label="Assigned unit">
          <Suspense fallback={<span className="italic">Loading unit data...</span>}>
            <Await
              resolve={units}
              errorElement={<span className="italic">Could not load unit data.</span>}
            >
              {(resolvedUnits: Unit[]) => {
                const unit = resolvedUnits.find(
                  (unit) => unit.id === orderItem.campaignContext?.unitId,
                );
                return unit ? (
                  unit.name
                ) : (
                  <>
                    <p className="italic">Unknown unit</p>
                    <p className="font-mono text-xs text-zinc-400">
                      {orderItem.campaignContext?.unitId}
                    </p>
                  </>
                );
              }}
            </Await>
          </Suspense>
        </DL.Item>

        <DL.Item label="Notes">
          {orderItem.campaignContext.notes?.trim().length ? (
            <div className="bg-zinc-50 p-2 whitespace-pre-wrap">
              {orderItem.campaignContext.notes}
            </div>
          ) : (
            <span className="text-zinc-400">No notes</span>
          )}
        </DL.Item>
      </DL.Root>

      <p>
        <RecruitmentButton />
      </p>
    </>
  );
}

function RecruitmentButton() {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  if (orderItem.campaignContext?.jobId) {
    return (
      <PrimaryAnchor
        href={`${import.meta.env.VITE_TAS_APP_URL}/c/${orderItem.order?.company?.id}/jobs/${
          orderItem.campaignContext.jobId
        }`}
        target="_blank"
      >
        Go to recruitment
      </PrimaryAnchor>
    );
  }

  return (
    <PrimaryLink size="lg" to={`/order-items/${orderItem.id}/recruitment`}>
      <PlusIcon className="w-5 h-5" />
      <span>Create recruitment</span>
    </PrimaryLink>
  );
}
