import { ReactNode, RefObject, TextareaHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "@/components/forms/label";
import { useActionErrors } from "@/hooks/useActionErrors";
import { mergeRefs } from "@/components/forms/merge-refs";
import RequiredAsterisk from "./required-asterisk";

export type MultilineTextFieldProps = {
  name: string;
  errors?: string[] | null;
  label?: ReactNode;
  description?: ReactNode;
  inputRef?: RefObject<HTMLTextAreaElement>;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export function TextareaField({
  name,
  errors,
  label,
  description,
  className,
  inputRef,
  ...props
}: MultilineTextFieldProps) {
  const actionErrors = useActionErrors();

  const { register, formState } = useFormContext() || { register: () => {} };

  const { ref, ...rest } = register(name, { value: props.defaultValue }) || {};

  const validationError = formState?.errors?.[name ?? ""]
    ? [formState?.errors?.[name ?? ""]?.message]
    : null;

  errors = errors ?? validationError ?? actionErrors?.[name ?? ""];

  const baseInputClasses = [
    "block w-full rounded-md ring-stone-300 border-0 ring-1 focus:ring-1 focus:ring-purple-500 focus:outline-0 transition duration-200 disabled:bg-stone-50 disabled:cursor-not-allowed disabled:text-stone-500",
    errors?.length ? "ring-red-600 bg-red-50" : "focus:bg-purple-50",
  ];

  return (
    <div>
      {label && (
        <Label htmlFor={props.id}>
          {label} {props.required && <RequiredAsterisk />}
        </Label>
      )}

      <textarea
        {...rest}
        name={name}
        ref={inputRef ? mergeRefs(inputRef, ref) : ref}
        className={[...baseInputClasses, className].join(" ")}
        {...props}
      />

      {errors?.map((errorText) => (
        <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      ))}

      {description && <p className="text-sm mt-2 text-stone-500">{description}</p>}
    </div>
  );
}
