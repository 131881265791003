import { PropsWithChildren } from "react";

export type ContentArgs = PropsWithChildren<{
  multiColumnLayout?: boolean;
}>;

export function Root({ children }: PropsWithChildren<NonNullable<unknown>>) {
  return (
    <div className="px-8 py-16 flex flex-col gap-16 overflow-x-hidden min-h-screen">{children}</div>
  );
}

export function Content({ children, multiColumnLayout = false }: ContentArgs) {
  return multiColumnLayout ? (
    <div className="flex items-start gap-6 xl:gap-12 2xl:gap-16">{children}</div>
  ) : (
    <>{children}</>
  );
}
