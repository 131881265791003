import { Suspense, useEffect } from "react";
import {
  Await,
  Outlet,
  ShouldRevalidateFunction,
  useAsyncValue,
  useLoaderData,
} from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import StyledLink from "@/components/styled-link";
import { InvoiceSummary } from "@/routes/invoices.$invoice/summary";
import { InvoiceItems } from "@/routes/invoices.$invoice/items";
import { InvoiceNotes } from "@/routes/invoices.$invoice/notes";
import { BillingDetailsBanner } from "@/routes/invoices.$invoice/banner";
import { InvoiceStatusWithHistoryTimeline } from "@/routes/invoices.$invoice/invoice-status";
import { InvoiceStatusVoid } from "@/routes/invoices.$invoice/invoice-status-void";
import { InvoiceBillingDetails } from "@/routes/invoices.$invoice/billing-details";
import { InvoiceStatus } from "@/entities/invoice-status";
import { Billing } from "@/entities/billing";
import { Company } from "@/entities/company";
import {
  preventRevalidationForFailedSubmissions,
  preventRevalidationForModalNavigation,
} from "@/helpers/revalidation-fns";
import * as Page from "@/components/page";
import { NetsuiteField } from "@/routes/invoices.$invoice/netsuite-field";
import { type InvoiceDetailsData, loader } from "./loader";

export { loader };

export const shouldRevalidate: ShouldRevalidateFunction = (params) => {
  return (
    preventRevalidationForFailedSubmissions(params) ??
    preventRevalidationForModalNavigation(params) ??
    params.defaultShouldRevalidate
  );
};

export default function InvoiceDetailsPage() {
  const { invoice, company } = useLoaderData() as InvoiceDetailsData;
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture("Invoice Viewed", {
      invoiceStatus: invoice.status,
      billingDetailsAvailable: invoice.customer instanceof Billing,
    });
  }, [posthog]);

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>
          <Suspense fallback="Invoice for ...">
            <Await resolve={company}>
              {(resolvedCompany) => `Invoice for ${resolvedCompany.name}`}
            </Await>
          </Suspense>
        </Page.Title>
      </Page.Heading>

      <Page.Content multiColumnLayout>
        <Page.Main>
          <section className="flex flex-col gap-6">
            <p className="text-2xl font-medium">
              Invoice #{invoice.numericId} for Order{" "}
              <StyledLink to={`/orders/${invoice.order.id}`}>#{invoice.order.numericId}</StyledLink>
            </p>

            {!invoice.customer && invoice.status !== InvoiceStatus.Void && <BillingDetailsBanner />}

            <InvoiceSummary />
          </section>

          {invoice.status !== InvoiceStatus.Void && (
            <section>
              <p className="text-2xl font-medium">Invoice items</p>
              <div className="mt-10 flex flex-col gap-4">
                <InvoiceItems />
              </div>
            </section>
          )}

          <section>
            <p className="text-2xl font-medium">Order notes</p>
            {invoice.order.notes ? (
              <p className="mt-6 whitespace-pre-line bg-yellow-100 p-6 shadow-sm rounded-md">
                {invoice.order.notes}
              </p>
            ) : (
              <p className="italic mt-6 whitespace-pre-line bg-yellow-100 p-6 shadow-sm rounded-md">
                No order notes recorded.
              </p>
            )}
          </section>

          <section>
            <p className="text-2xl font-medium">Notes</p>
            <InvoiceNotes />
          </section>
        </Page.Main>

        <Page.ColumnSeparator />

        <Page.Sidebar>
          {invoice.status !== InvoiceStatus.Void ? (
            <>
              <NetsuiteField />
              <InvoiceStatusWithHistoryTimeline />
            </>
          ) : (
            <InvoiceStatusVoid />
          )}

          <p className="font-bold">Customer</p>
          <Suspense fallback={null}>
            <Await resolve={company}>
              <CompanyLink />
            </Await>
          </Suspense>
          <InvoiceBillingDetails />
        </Page.Sidebar>
      </Page.Content>

      <Outlet />
    </Page.Root>
  );
}

function CompanyLink() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const company = useAsyncValue() as Company;
  const companyName = invoice.customer ? invoice.customer.name : company.name;
  return (
    <p className="text-sm">
      <StyledLink to={`/companies/${company.id}`}>{companyName}</StyledLink>
    </p>
  );
}
