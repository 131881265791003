import { ReactNode, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useLoaderData } from "react-router-dom";
import clsx from "clsx";
import TextField from "@/components/forms/text-field";
import { Label } from "@/components/forms/label";
import { OrdersNewPageLoaderData } from "@/routes/orders.create/route";
import { User } from "@/entities/user";
import { TooltipBox } from "@/components/tooltip-box";
import { useActionErrors } from "@/hooks/useActionErrors";

export type UserSelectionFieldProps<IsOptional extends boolean> = {
  description?: ReactNode;
  label?: string;
  name: string;
  onChange: (value: IsOptional extends true ? User | null : User) => void;
  optional?: IsOptional;
  value: IsOptional extends true ? User | null : User;
};

export function UserSelectionField<IsOptional extends boolean = false>({
  description,
  label,
  name,
  onChange,
  optional,
  value,
}: UserSelectionFieldProps<IsOptional>) {
  const [isDialogShown, setIsDialogShown] = useState(false);
  const errors = useActionErrors()?.[name];

  return (
    <div>
      <input type="hidden" name={name} value={value?.id ?? ""} />

      {label && <Label>{label}</Label>}

      <div className="relative">
        {value && optional && (
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-zinc-300 rounded-full hover:bg-zinc-400"
                // Can't tell TypeScript that this is a valid use of `null` here. If you know,
                // please fix this, and tell me how. Thanks! — Hakan Aktas.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={() => onChange(null)}
              >
                <XMarkIcon className="w-5 h-5 p-0.5 text-white" />
              </button>
            </Tooltip.Trigger>

            <Tooltip.Portal>
              <Tooltip.Content sideOffset={5}>
                <TooltipBox>Remove selection</TooltipBox>
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        )}

        <Dialog.Root open={isDialogShown} onOpenChange={setIsDialogShown}>
          <Dialog.Trigger asChild>
            <button
              type="button"
              className={clsx(
                "flex items-center justify-between space-x-1 w-full py-2 px-3 text-slate-700 text-left rounded-md border-0 ring-1 focus:ring-1 focus:ring-purple-500 focus:outline-0 transition duration-200 disabled:bg-stone-50 disabled:cursor-not-allowed disabled:text-stone-500",
                optional && "pr-10",
                errors?.length ? "ring-red-600 bg-red-50" : "ring-stone-300 bg-white",
              )}
            >
              {value ? <SelectedUserDetails selectedUser={value} /> : "Select a seller"}
            </button>
          </Dialog.Trigger>

          <UserSelectionDialog
            isDialogShown={isDialogShown}
            setIsDialogShown={setIsDialogShown}
            selectedUser={value ?? null}
            // Can't tell TypeScript that this is a valid use of `null` here. If you know,
            // please fix this, and tell me how. Thanks! — Hakan Aktas.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSelectedUser={onChange}
          />
        </Dialog.Root>
      </div>

      {errors?.map((errorText) => (
        <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      )) ?? null}

      {description && <p className="text-sm mt-2 text-stone-500">{description}</p>}
    </div>
  );
}

function SelectedUserDetails({ selectedUser }: { selectedUser: User }) {
  return (
    <>
      <span className="flex flex-col">
        <span className="truncate">{selectedUser.name}</span>
        <span className="tracking-tight text-sm text-slate-500">{selectedUser.email}</span>
      </span>
      <span className="flex-shrink-0 text-slate-500 text-sm">Click to change</span>
    </>
  );
}

export type UserSelectionDialogProps = {
  isDialogShown: boolean;
  setIsDialogShown: (value: boolean) => void;
  selectedUser: User | null;
  setSelectedUser: (value: User | null) => void;
};

function UserSelectionDialog({
  isDialogShown,
  setIsDialogShown,
  setSelectedUser,
}: UserSelectionDialogProps) {
  const { users: usersPromise } = useLoaderData() as OrdersNewPageLoaderData;
  const [search, setSearch] = useState("");
  const [usersToDisplay, setUsersToDisplay] = useState<User[]>([]);

  useEffect(() => {
    usersPromise.then((users) => {
      setUsersToDisplay(
        users.filter((user) => user.name.toLowerCase().includes(search.toLowerCase())),
      );
    });
  }, [search, usersPromise]);

  return (
    <AnimatePresence initial={false}>
      {isDialogShown && (
        <Dialog.Portal forceMount>
          <Dialog.Overlay asChild>
            <motion.div
              className="fixed inset-0 z-40 bg-black/30"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, when: "beforeChildren" }}
            />
          </Dialog.Overlay>

          <Dialog.Content asChild>
            <motion.div
              className="fixed z-50 inset-0 overflow-x-hidden overflow-y-auto py-16 flex items-start justify-center"
              // We use the style explicit here because otherwise Framer-motion adds the Auto value.
              style={{ pointerEvents: "none" }}
            >
              <motion.div
                className="bg-white rounded-xl shadow-xl min-w-[300px] w-full max-w-[700px] pointer-events-auto"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{
                  duration: 0.25,
                  ease: [0.17, 0.67, 0.26, 1.64],
                }}
              >
                <div className="flex items-start justify-between px-12 pt-12">
                  <Dialog.Title className=" text-2xl font-bold text-stone-800">
                    Select a seller
                  </Dialog.Title>

                  <Dialog.Close>
                    <XMarkIcon className="w-5 h-5 text-stone-500" />
                  </Dialog.Close>
                </div>

                <div className="px-12 pt-6 pb-8">
                  <div className="-mx-3">
                    <TextField
                      name="search"
                      placeholder="Type to narrow down the list"
                      value={search}
                      onInput={(event) => setSearch(event.currentTarget.value)}
                      autoFocus
                    />
                  </div>

                  <ScrollArea.Root type="scroll" className="-mx-2 mt-4 h-64">
                    <ScrollArea.Viewport className="w-full h-full">
                      <div className="flex flex-col divide-y pr-8">
                        {usersToDisplay.map((user) => (
                          <button
                            key={user.id.toString()}
                            type="button"
                            className="group block py-2 px-2 text-left truncate rounded hover:bg-indigo-600 hover:text-white"
                            onClick={() => {
                              setIsDialogShown(false);
                              setSelectedUser(user);
                            }}
                          >
                            <span className="block">{user.name}</span>
                            <span className="block text-slate-500 group-hover:text-white">
                              {user.email}
                            </span>
                          </button>
                        ))}
                      </div>
                    </ScrollArea.Viewport>

                    <ScrollArea.Scrollbar
                      forceMount
                      className="w-2 bg-stone-50 rounded-full"
                      orientation="vertical"
                    >
                      <ScrollArea.Thumb className="bg-stone-300 rounded-full" />
                    </ScrollArea.Scrollbar>
                  </ScrollArea.Root>
                </div>
              </motion.div>
            </motion.div>
          </Dialog.Content>
        </Dialog.Portal>
      )}
    </AnimatePresence>
  );
}
