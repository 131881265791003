import {
  BriefcaseIcon,
  CalendarDaysIcon,
  GlobeEuropeAfricaIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/solid";
import { Await, useLoaderData } from "react-router-dom";
import { ReactNode, Suspense } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { RichRadio } from "@/components/forms/rich-radio";
import { Label } from "@/components/forms/label";
import { CampaignRequestPageLoaderData } from "@/routes/order-items.$orderItem.campaign-request/route";
import { useActionErrors } from "@/hooks/useActionErrors";
import { OrderItemStatus } from "@/entities/order-item-status";
import { Token } from "@/entities/token";

export function Booster() {
  const { orderItem } = useLoaderData() as CampaignRequestPageLoaderData;
  const errors = useActionErrors();

  const canChooseBooster = ![OrderItemStatus.Active, OrderItemStatus.Completed].includes(
    orderItem.status,
  );

  return (
    <div>
      <Label>Booster</Label>
      <p className="text-sm text-stone-500">
        Boosters are optional add-ons that can be added to a campaign to increase its reach.
        Customers must purchase boosters before they are used. Campaigns can only have one booster
        at a time, and each purchased booster can only be used once.
      </p>

      {!canChooseBooster && (
        <div className="mt-4 flex items-center bg-red-100 p-2 text-sm text-red-900">
          <ExclamationTriangleIcon className="w-6 h-6 text-red-800 mr-2" />
          <p>
            It is not allowed to change booster selection when campaign is at &quot;Active&quot; or
            &quot;Completed&quot; stage.
          </p>
        </div>
      )}

      <div className="grid grid-cols-4 gap-4 mt-4">
        <RichRadio
          float
          name="booster"
          value=""
          readOnly={!canChooseBooster}
          defaultChecked={
            orderItem.campaignContext ? orderItem.campaignContext.boosterType === null : true
          }
        >
          <div className="text-center py-8">
            <BriefcaseIcon className="inline-block w-8 h-8 text-stone-700" />

            <p className="mt-3 font-medium">No booster</p>
            <p className="text-stone-500 text-sm">The campaign will run as normal.</p>
          </div>
        </RichRadio>

        <BoosterOption boosterType="range_booster">
          <GlobeEuropeAfricaIcon className="inline-block w-8 h-8 text-stone-700" />

          <p className="mt-3 font-medium">Range</p>
          <p className="text-stone-500 text-sm">
            The campaign&apos;s marketing range will be extended, attracting candidates from more
            locations.
          </p>
        </BoosterOption>

        <BoosterOption boosterType="duration_booster">
          <CalendarDaysIcon className="inline-block w-8 h-8 text-stone-700" />

          <p className="mt-3 font-medium">Duration</p>
          <p className="text-stone-500 text-sm">
            The campaign&apos;s duration will be extended, giving it more time to reach its target
            audience.
          </p>
        </BoosterOption>

        <BoosterOption boosterType="contact_booster">
          {/* Wrapping icon within div here because this phone icon is not the same scale as
            other icons, and it looks ugly without this. */}
          <div className="mx-auto w-8 h-8 flex items-center justify-center">
            <PhoneArrowUpRightIcon className="inline-block w-6 h-6 text-stone-700" />
          </div>

          <p className="mt-3 font-medium">Candidate contacting</p>
          <p className="text-stone-500 text-sm">
            Jobillans will contact candidates on behalf of the customer, increasing the
            campaign&apos;s success rate.
          </p>
        </BoosterOption>
      </div>

      {errors?.booster?.map((errorText) => (
        <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      ))}
    </div>
  );
}

function BoosterOption({
  boosterType,
  children,
}: {
  boosterType: Exclude<Token["type"], "campaign" | "campaign_lite" | "campaign_pro">;
  children: ReactNode;
}) {
  const {
    orderItem,
    countAvailableDurationToken,
    countAvailableRangeToken,
    countAvailableCandidateContactToken,
  } = useLoaderData() as CampaignRequestPageLoaderData;

  const countOfAvailableTokens = {
    range_booster: countAvailableRangeToken,
    duration_booster: countAvailableDurationToken,
    contact_booster: countAvailableCandidateContactToken,
  }[boosterType];

  const canChooseBooster = ![OrderItemStatus.Active, OrderItemStatus.Completed].includes(
    orderItem.status,
  );

  const booster = function <T extends boolean>(
    isLoading: T,
    count?: T extends true ? number : undefined,
  ) {
    return (
      <RichRadio
        float
        name="booster"
        value={boosterType}
        readOnly={
          isLoading ||
          (count === 0 && orderItem.campaignContext?.boosterType !== boosterType) ||
          !canChooseBooster
        }
        defaultChecked={orderItem.campaignContext?.boosterType === boosterType}
      >
        <div className="text-center py-8">
          {children}

          <p className="mt-4 text-stone-500 text-sm">
            {isLoading ? "Loading tokens" : `${count} available`}
          </p>
        </div>
      </RichRadio>
    );
  };

  return (
    <Suspense fallback={booster(true)}>
      <Await resolve={countOfAvailableTokens}>{(count) => booster(false, count)}</Await>
    </Suspense>
  );
}
