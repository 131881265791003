import { Entity, Type } from "@jobilla/entity";
import { capitalize } from "lodash";
import { NetsuiteAttachment } from "@/entities/netsuite-attachment";
import { PaymentTerms } from "./payment-terms";
import { InvoiceStatus } from "./invoice-status";
import { InvoiceStatusHistory } from "./invoice-status-history";
import { Money } from "./money";
import { Currency } from "./currency";
import { Billing } from "./billing";
import { Order } from "./order";
import { InvoiceItem } from "./invoice-item";
import { InvoiceNote } from "./invoice-note";
import { InvoiceCondition } from "./invoice-condition";
import { InvoiceTransferDetails } from "./invoice-transfer-details";

export class Invoice extends Entity {
  id!: string;
  numericId!: number;
  orderId!: string;
  orderNumericId!: number;
  overrideEinvoice = false;
  payeeId!: string;
  customerId!: string;
  stripeId!: string;
  currency = Currency.EUR;
  status!: InvoiceStatus;
  date?: string;
  latestDate?: string;
  condition!: InvoiceCondition;
  description!: string;
  paymentTerm = PaymentTerms.Days14;
  reference!: string;
  isVatReverseCharge = false;
  updatedAt!: string;

  @Type(NetsuiteAttachment)
  attachments!: NetsuiteAttachment[];

  @Type(InvoiceTransferDetails)
  transferDetails!: InvoiceTransferDetails | null;

  @Type(Money)
  amount!: Money;

  @Type(Money)
  amountPaid!: Money;

  @Type(() => Order)
  order!: Order;

  @Type(Billing)
  customer!: Billing | null;

  @Type(InvoiceItem)
  items: InvoiceItem[] = [];

  @Type(InvoiceStatusHistory)
  historicStatuses: InvoiceStatusHistory[] = [];

  @Type(InvoiceNote)
  notes: InvoiceNote[] = [];

  get tooltip(): string {
    return `${this.amount} - ${this.statusForHumans}`;
  }

  get statusForHumans(): string {
    return capitalize(this.status).replace(/_/g, " ");
  }

  get canBeEdited() {
    return [
      InvoiceStatus.New,
      InvoiceStatus.ConfirmBillingDetails,
      InvoiceStatus.PendingInformation,
      InvoiceStatus.ReadyToSend,
    ].includes(this.status);
  }

  get latestOrderedAttachments(): NetsuiteAttachment[] {
    return this.attachments.slice(0).reverse();
  }
}
