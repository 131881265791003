import * as React from "react";
import clsx from "clsx";

export type DangerButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const DangerButton = React.forwardRef<HTMLButtonElement, DangerButtonProps>(
  function DangerButtonWithoutRef({ children, size = "md", ...props }: DangerButtonProps, ref) {
    const sizeBasedStyles = {
      xs: "py-1 px-2 text-xs",
      sm: "py-1.5 px-3 text-sm",
      "sm-square": "py-1.5 px-1.5 text-sm",
      md: "py-2 px-4 text-sm",
      lg: "py-3 px-6 text-sm",
      xl: "py-4 px-8",
    };

    const baseClasses = [
      "inline-flex items-center justify-center",
      "bg-rose-600 text-white font-medium rounded-lg leading-none tracking-wide",
      "whitespace-nowrap",
      sizeBasedStyles[size],
      "transition duration-200",
    ];

    return (
      <button
        ref={ref}
        {...props}
        className={clsx(
          baseClasses,
          "enabled:hover:bg-gradient-to-bl enabled:hover:from-rose-600 enabled:hover:to-rose-700",
          "disabled:opacity-40 disabled:cursor-not-allowed",
        )}
      >
        {children}
      </button>
    );
  },
);
