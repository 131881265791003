import { useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { SpinnerIcon } from "@/components/icons/spinner";

export function LoadingIndicator() {
  const transition = useNavigation();

  return (
    <AnimatePresence>
      {transition.state === "loading" && (
        <motion.div
          className="px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, delay: 0.6 }}
        >
          <SpinnerIcon className="w-8 h-8 text-white animate-spin" />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
