import { forwardRef, ReactNode } from "react";

type TooltipBoxProps = { children: ReactNode };

export const TooltipBox = forwardRef<HTMLDivElement, TooltipBoxProps>(function TooltipBoxWithoutRef(
  { children },
  ref,
) {
  return (
    <div
      ref={ref}
      className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
    >
      {children}
    </div>
  );
});
