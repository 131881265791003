import clsx from "clsx";

export function StyledHtmlContent({ content }: { content: string }) {
  return (
    <div
      className={clsx(
        "prose",
        "prose-a:underline prose-a:decoration-indigo-200 prose-a:decoration-2 prose-a:text-violet-900 prose-a:font-medium prose-a:underline-offset-1",
      )}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
