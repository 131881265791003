import { api } from "@/api/base";

export type PermissionCheckResponse = {
  data: {
    is_allowed: boolean;
  };
};

export async function fetchUserPermissionFor(
  action: string,
  resource: string,
  resourceId: string | number,
) {
  const response = await api
    .get("v1/me/permissions/check", {
      searchParams: {
        action,
        resource,
        resource_id: resourceId,
        ignore_soft_rule: true,
      },
    })
    .json<PermissionCheckResponse>();
  return response.data.is_allowed;
}
