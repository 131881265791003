import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { InventorySummary } from "@/entities/inventory-summary";

export async function fetchCompanyInventory(companyId: number): Promise<InventorySummary> {
  const response = await api.get(`v1/companies/${companyId}/inventory-summary`);

  // TODO: should make this more explanatory for user
  if (response.status >= 300) {
    throw new Response("Something went wrong.", {
      status: response.status,
    });
  }

  const json = await response.json<{ data: PartialPropsJson<InventorySummary> }>();
  return EntityBuilder.buildOne(InventorySummary, json.data);
}
