import { useLoaderData } from "react-router-dom";
import { HubspotCompany } from "@/entities/hubspot-company";
import SidebarBox from "@/routes/companies.$company/sidebar-box";
import SecondaryLink from "@/components/secondary-link";
import { StyledAnchor } from "@/components/styled-anchor";
import type { CompanyDetailsData } from "./loader";

export default function CompanyHubspotLink() {
  const { company } = useLoaderData() as CompanyDetailsData;

  const isLinked = company.hubspot instanceof HubspotCompany;

  return (
    <SidebarBox
      title="Hubspot"
      action={<SecondaryLink to="hubspot-link">{isLinked ? "Change" : "Link"}</SecondaryLink>}
    >
      {isLinked ? <Linked hubspot={company.hubspot} /> : <NotLinked />}
    </SidebarBox>
  );
}

function Linked({ hubspot }: { hubspot: HubspotCompany }) {
  return (
    <>
      <p className="text-sm text-slate-700">
        Linked to #{hubspot.id} ({hubspot.name}). Deals will sync automatically.
      </p>
      <p className="mt-4 text-sm">
        <StyledAnchor href={hubspot.url} target="_blank" rel="noreferrer noopener">
          View in Hubspot &rarr;
        </StyledAnchor>
      </p>
    </>
  );
}

function NotLinked() {
  return (
    <p className="text-sm text-slate-700">
      This company is not yet linked to Hubspot. Link it now to automatically sync orders and deals
      with Hubspot.
    </p>
  );
}
