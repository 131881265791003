import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { Token } from "@/entities/token";
import { api } from "@/api/base";

export async function fetchCompanyTokens(companyId: number): Promise<Token[]> {
  const response = await api.get(`v1/companies/${companyId}/consumable-tokens`);

  // TODO: should make this more explanatory for user
  if (response.status >= 300) {
    throw new Response("Something went wrong.", {
      status: response.status,
    });
  }

  const json = await response.json<{ data: PartialPropsJson<Token>[] }>();
  return EntityBuilder.buildMany(Token, json.data);
}
