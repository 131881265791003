import { Entity } from "@jobilla/entity";

export enum Relation {
  Parent = "PARENT",
  Child = "CHILD",
}
export class CompanyRelation extends Entity {
  public companyId!: number;
  public companyName!: string;
  public type!: Relation;
}
