import { useFetcher, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Cog8ToothIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { orderBy } from "lodash";
import { TextareaField } from "@/components/forms/textarea-field";
import SubmitButton, { SubmitButtonSavingLabel } from "@/components/submit-button";
import { useOnFetcherSuccess } from "@/hooks/fetcher-hooks";
import Date, { DateDisplayType } from "@/components/date";
import * as DropdownMenu from "@/components/dropdown-menu";
import { InvoiceNote } from "@/entities/invoice-note";
import { RootLoaderData } from "@/root";
import { Tooltip } from "@/components/tooltip";
import { InvoiceDetailsData } from "./loader";

export function InvoiceNotes() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const [notes, setNotes] = useState(invoice.notes);

  useEffect(() => {
    setNotes(invoice.notes);
  }, [invoice.notes]);

  const orderedNotes = useMemo(() => {
    return orderBy(notes, "createdAt", "desc");
  }, [notes]);

  return (
    <div id="invoice-notes" className="mt-5">
      {!notes.length ? (
        <p className="italic">No existing notes</p>
      ) : (
        <div className="flex flex-col justify-between gap-5">
          {orderedNotes.map((note) => (
            <InvoiceNoteItem key={note.id} note={note} />
          ))}
        </div>
      )}

      <div className="mt-12">
        <CreateNoteForm
          onCreate={(note) => {
            setNotes((notes) => [...notes, note]);
            setTimeout(() => {
              window.scrollTo({
                top: (document.querySelector("#invoice-notes") as HTMLDivElement).offsetTop - 100,
                behavior: "smooth",
              });
            });
          }}
        />
      </div>
    </div>
  );
}

function InvoiceNoteItem({ note }: { note: InvoiceNote }) {
  const { user } = useRouteLoaderData("root") as RootLoaderData;

  const fetcher = useFetcher();
  const isDeleting = fetcher.state !== "idle";

  const hasDeleteFailed = fetcher.state === "idle" && fetcher.data?.status >= 400;
  const isUserAllowedToEdit = note.user.id === user.id;

  const deleteNote = useCallback(() => {
    fetcher.submit(
      {},
      {
        method: "delete",
        action: `/invoice-notes/${note.id}`,
      },
    );
  }, [fetcher, note]);

  return (
    <div
      className={clsx(
        "group flex bg-white p-3 justify-between rounded shadow",
        isDeleting ? "opacity-40" : "",
      )}
      key={note.id}
    >
      <div className="flex flex-col justify-between">
        <div className="py-1 px-2 whitespace-pre-line">{note.content}</div>

        <div
          className={clsx(
            "mt-2 text-sm transition duration-100",
            isDeleting ? "text-zinc-500" : "text-zinc-300 group-hover:text-zinc-500",
          )}
        >
          — {note.user.name} on <Date date={note.createdAt} display={DateDisplayType.DATE_TIME} />
          {isDeleting ? "— Deleting..." : ""}
          {hasDeleteFailed && <p className="text-red-700 font-medium">Failed to delete note</p>}
        </div>
      </div>

      <div className="self-end text-zinc-300 group-hover:text-zinc-500">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            className="p-1 hover:text-indigo-700"
            disabled={!isUserAllowedToEdit}
          >
            {!isUserAllowedToEdit ? (
              <Tooltip content="You are not allowed to edit this note">
                <Cog8ToothIcon className="w-4 h-4 transition duration-100" />
              </Tooltip>
            ) : (
              <Cog8ToothIcon className="w-4 h-4 transition duration-100" />
            )}
          </DropdownMenu.Trigger>

          <DropdownMenu.Content>
            <DropdownMenu.ButtonItem
              type="button"
              className="text-red-600"
              label="Delete note"
              icon={<TrashIcon className="w-4 h-4" />}
              onClick={deleteNote}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}

function CreateNoteForm({ onCreate }: { onCreate?: (note: InvoiceNote) => void }) {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const fetcher = useFetcher();
  const formRef = useRef<HTMLFormElement>(null);

  useOnFetcherSuccess(
    fetcher,
    useCallback(() => {
      onCreate?.(fetcher.data?.data);
      formRef.current?.reset();
    }, [formRef.current, fetcher.data]),
  );

  const errors = fetcher.data?.errors;

  return (
    <fetcher.Form ref={formRef} method="post" action={`/invoices/${invoice.id}/create-note`}>
      <fieldset disabled={fetcher.state === "submitting"}>
        <TextareaField
          label="Add note"
          name="content"
          description="Is there something that billing should know and take into consideration?"
          errors={errors?.content}
        />

        <div className="mt-4 flex justify-end">
          <SubmitButton
            size="lg"
            isSubmitting={fetcher.state === "submitting"}
            label="Add note"
            labelWhenSubmitting={<SubmitButtonSavingLabel text="Adding..." />}
          />
        </div>
      </fieldset>
    </fetcher.Form>
  );
}
