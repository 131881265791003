import { ReactNode } from "react";
import { defer, Form, ShouldRevalidateFunction, useNavigation } from "react-router-dom";
import * as Page from "@/components/page";
import TextField from "@/components/forms/text-field";
import { CompanySelectionField } from "@/routes/orders.create/company-selection-field";
import { fetchProducts } from "@/api/product";
import {
  OrderItems,
  OrderItemsContext,
  useOrderItemsContextValues,
} from "@/routes/orders.create/order-items";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { User } from "@/entities/user";
import { Product } from "@/entities/product";
import { Invoices } from "@/routes/orders.create/invoices";
import { Payee } from "@/entities/payee";
import { fetchPayees } from "@/api/payee";
import { Payees } from "@/routes/orders.create/payee";
import { TextareaField } from "@/components/forms/textarea-field";
import SubmitButton from "@/components/submit-button";
import { preventRevalidationForFailedSubmissions } from "@/helpers/revalidation-fns";
import { useActionErrors } from "@/hooks/useActionErrors";
import { Sellers } from "@/routes/orders.create/sellers";

export { action } from "./action";

export type OrdersNewPageLoaderData = {
  users: Promise<User[]>;
  products: Product[];
  payees: Payee[];
};

export function shouldRevalidate(params: Parameters<ShouldRevalidateFunction>[0]) {
  return preventRevalidationForFailedSubmissions(params) ?? params.defaultShouldRevalidate;
}

export async function loader() {
  return defer({
    users: fetchJobillaUsers({ team: "sales" }),
    products: await fetchProducts(),
    payees: await fetchPayees(),
  });
}

export default function OrdersNewPage() {
  const transition = useNavigation();
  const errors = useActionErrors();

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Record a new order</Page.Title>
      </Page.Heading>

      <Page.Main>
        <OrderItemsContext.Provider value={useOrderItemsContextValues()}>
          <Form method="post" noValidate>
            <fieldset disabled={transition.state !== "idle"} className="flex flex-col gap-8">
              <TextField
                autoFocus
                type="text"
                autoComplete="off"
                autoCapitalize="off"
                name="hubspot_deal_id"
                label="Hubspot deal URL"
                errors={errors?.hubspot_deal_id}
                description={
                  <>
                    If you&apos;ve already logged this deal to Hubspot, enter its deal URL here - it
                    looks like this: <code>app.hubspot.com/contacts/21962769/deal/10994510712</code>
                  </>
                }
              />

              <CompanySelectionField />

              <SectionTitle>Order items</SectionTitle>
              <OrderItems />

              <div>
                <SectionTitle>Sellers</SectionTitle>
                <Sellers />
              </div>

              <div className="flex flex-col gap-6">
                <SectionTitle>Invoices</SectionTitle>

                <Invoices />
              </div>

              <div className="flex flex-col gap-6">
                <Payees />

                <TextareaField
                  name="notes"
                  label="Order notes"
                  description="Is there some additional information that other people should know about the order?"
                />
              </div>

              <div className="flex items-center gap-4">
                <SubmitButton label="Create order" labelWhenSubmitting="Creating..." size="lg" />

                {errors && (
                  <p className="text-red-600 font-medium text-sm">
                    There are some errors in the form. Please fix them before submitting again.
                  </p>
                )}
              </div>
            </fieldset>
          </Form>
        </OrderItemsContext.Provider>
      </Page.Main>
    </Page.Root>
  );
}

function SectionTitle({ children }: { children: ReactNode }) {
  return <p className="mt-8 text-2xl font-medium">{children}</p>;
}
