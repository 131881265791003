import * as React from "react";

type PrimaryAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  size?: "sm" | "md" | "lg" | "xl";
};

export default function PrimaryAnchor({ children, size = "md", ...props }: PrimaryAnchorProps) {
  const padding = {
    sm: "py-1.5 px-3",
    md: "py-2 px-4",
    lg: "py-3 px-6",
    xl: "py-4 px-8",
  };

  return (
    <a
      {...props}
      className={[
        "bg-violet-600 text-white font-medium text-sm leading-none rounded-lg transition duration-200",
        padding[size],
        "whitespace-nowrap",
        "hover:bg-gradient-to-bl hover:from-violet-900 hover:to-violet-950",
        props?.className ?? "",
      ].join(" ")}
    >
      {children}
    </a>
  );
}
