import { useRef } from "react";
import type { ReactNode } from "react";
import type { AriaOverlayProps } from "@react-aria/overlays";
import { OverlayContainer, useModal, useOverlay, usePreventScroll } from "@react-aria/overlays";
import { FocusScope } from "@react-aria/focus";
import type { AriaDialogProps } from "@react-aria/dialog";
import { useDialog } from "@react-aria/dialog";
import { AnimatePresence, motion } from "framer-motion";

export type DialogProps = {
  title: string;
  description?: ReactNode;
  children: ReactNode;
} & AriaOverlayProps &
  AriaDialogProps;

export function Dialog(props: DialogProps) {
  const data = {
    isDismissable: true,
    ...props,
  };
  const { title, description, children } = data;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(data, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll({
    isDisabled: !props.isOpen,
  });

  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(data, ref);

  return (
    <AnimatePresence>
      {data.isOpen && (
        <OverlayContainer>
          {/* @ts-expect-error react-aria does not play nice with framer-motion */}
          <motion.div
            className="fixed z-100 inset-0 bg-black overflow-x-hidden overflow-y-auto bg-opacity-30 py-16 grid place-items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, when: "beforeChildren" }}
            {...underlayProps}
          >
            <FocusScope contain restoreFocus autoFocus>
              {/* @ts-expect-error react-aria does not play nice with framer-motion */}
              <motion.div
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                ref={ref}
                className="bg-white rounded-xl shadow-xl min-w-[300px] w-full max-w-[700px]"
                initial={{ scale: 0.6 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.6 }}
                transition={{
                  duration: 0.25,
                  ease: [0.17, 0.67, 0.26, 1.64],
                }}
              >
                <p {...titleProps} className="px-12 pt-12 text-2xl font-bold text-stone-800">
                  {title}
                </p>

                {description && <p className="px-12 pb-4 text-sm text-stone-500">{description}</p>}

                <div className="px-12 pt-6 pb-8">{children}</div>
              </motion.div>
            </FocusScope>
          </motion.div>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
}
