import { MapPinIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";
import clsx from "clsx";

export function Root({ children }: { children: ReactNode }) {
  return <div>{children}</div>;
}

export enum ItemIconSize {
  Regular,
  Big,
  Huge,
}

export enum ItemIcon {
  MapPin,
  BlueDot,
  GrayDot,
}

export type ItemArgs = {
  children: ReactNode;
  icon: ItemIcon;
  size?: ItemIconSize;
};

const sizeToClassName: Record<ItemIconSize, string> = {
  [ItemIconSize.Regular]: "w-2 h-2 mx-1 mt-1.5",
  [ItemIconSize.Big]: "w-3 h-3 mx-0.5 mt-1",
  [ItemIconSize.Huge]: "w-4 h-4",
};

export function Item({ children, icon, size = ItemIconSize.Regular }: ItemArgs) {
  return (
    <div className="group flex relative space-x-2 text-sm">
      <div className="relative">
        <div className="group-last:hidden absolute inset-y-0 left-1/2 -ml-px border-l-2 border-dotted" />

        {icon === ItemIcon.MapPin ? (
          <div
            className={clsx(
              sizeToClassName[size],
              "relative bg-green-500 rounded-full ring-4 ring-white flex items-center justify-center",
            )}
          >
            <MapPinIcon className="w-2.5 h-2.5 text-white" />
          </div>
        ) : (
          <div
            className={clsx(
              sizeToClassName[size],
              "relative rounded-full ring-4 ring-white",
              icon === ItemIcon.BlueDot ? "bg-blue-500" : "bg-zinc-400",
            )}
          />
        )}
      </div>

      <div className="mb-4">{children}</div>
    </div>
  );
}

export function Label({ children }: { children: ReactNode }) {
  return <p>{children}</p>;
}

export function Subtext({ children }: { children: ReactNode }) {
  return <p className="text-xs mt-2">{children}</p>;
}
