export enum Locale {
  En_GB = "en-GB",
  Fi_FI = "fi-FI",
  De_DE = "de-DE",
  Lv_LV = "lv-LV",
  Lt_LT = "lt-LT",
}

export const localesAndLabels = [
  { code: Locale.En_GB, label: "English", nativeLabel: "English" },
  { code: Locale.Fi_FI, label: "Finnish", nativeLabel: "Suomi" },
  { code: Locale.De_DE, label: "German", nativeLabel: "Deutsch" },
  { code: Locale.Lv_LV, label: "Latvian", nativeLabel: "Latviešu" },
  { code: Locale.Lt_LT, label: "Lithuanian", nativeLabel: "Lietuvių" },
] as const;

export function isLocaleSupported(
  locale: string,
): locale is (typeof localesAndLabels)[number]["code"] {
  return locale in localesAndLabels.map(({ code }) => code);
}
