import { Entity, Type } from "@jobilla/entity";
import { JobStatus } from "@/entities/job-status";
import { PipelineStage } from "@/entities/pipeline-stage";

export class Job extends Entity {
  id!: string;
  name!: string;
  location!: string;
  version!: number;
  status!: JobStatus;
  activeFrom!: string;
  activeTo!: string | null;
  companyId!: number;
  isHidden!: boolean;
  @Type(PipelineStage)
  stages!: PipelineStage[];
  isFromCampaignRequest!: boolean;
  orderItemId!: string;
}
