import Cookies from "js-cookie";
import { fetchRefreshedAuthToken, invalidateCurrentAuthToken } from "@/api/auth";

export function getAuthToken(): string {
  const token = Cookies.get("auth.access-token");

  if (!token) {
    enforceReauthorization();
    return "";
  }

  return token;
}

export interface AuthTokenData {
  system_administrator: boolean;
  user: {
    id: number;
    avatar: string | null;
    email: string;
    lang: string;
    name: string;
    intercom_hash: string;
  };
  sub: string;
  jobilla_roles: string[];
}

export function getAuthTokenData(): AuthTokenData {
  const token = getAuthToken().split(".")[1];
  const decodedToken = atob(token);

  return JSON.parse(decodedToken);
}

/*
 * It would be lovely to make this a middleware when React Router implements it. The middlewares
 * are in discussion at the time of implementing this. Follow the progress in the link below.
 *
 * @see https://github.com/remix-run/remix/discussions/7642
 */
export function maybeHandleTokenInUrl() {
  const currentURL = new URL(window.location.href);
  const accessTokenInURL =
    window.location.hostname === "localhost" ? currentURL.searchParams.get("access-token") : null;

  if (accessTokenInURL === null) {
    return;
  }

  Cookies.set("auth.access-token", accessTokenInURL);

  const newURL = new URL(currentURL);
  newURL.searchParams.delete("access-token");

  // Specifically chose to replace instead of assigning to `window.location.href`, because this
  // way the URL with the token parameter is completely removed from the browser history.
  window.location.replace(newURL.toString());

  // Throwing an error here to prevent the rest of the app from loading. This is only to reduce
  // the amount of requests to the API, because the page will be reloaded anyway.
  throw new Error("Redirecting to remove access token from URL");
}

export async function enforceReauthorization(skipInvalidationRequest = false) {
  if (Cookies.get("auth.access-token") && !skipInvalidationRequest) {
    await invalidateCurrentAuthToken();
  }

  removeAuthTokens();

  /*
   * I'm not completely sure if we need this timeout, but in a few experiments
   * we have noticed that sometimes cookies are not removed from the browser
   * if the auth page loads quickly. This is a wishful workaround for that.
   */
  setTimeout(() => {
    redirectToLogin();
  }, 100);
}

export class NoRefreshTokenError extends Error {
  constructor() {
    super("No refresh token found.");
  }
}

export async function refreshAuthToken(): Promise<void> {
  const currentRefreshToken = Cookies.get("auth.refresh-token");

  if (!currentRefreshToken) {
    throw new NoRefreshTokenError();
  }

  const refreshResult = await fetchRefreshedAuthToken(currentRefreshToken);

  Cookies.set("auth.access-token", refreshResult.access_token);
  Cookies.set("auth.refresh-token", refreshResult.refresh_token);
}

export function removeAuthTokens(): void {
  Cookies.remove("auth.access-token");
  Cookies.remove("auth.refresh-token");
}

function redirectToLogin(): void {
  window.location.href = getLoginUrl();
}

function getLoginUrl() {
  return `${import.meta.env.VITE_AUTH_APP_URL}/login?redirect=${window.location.href}`;
}
