export const languages = [
  {
    name: "Finnish",
    key: "fi-FI",
  },
  {
    name: "English",
    key: "en-GB",
  },
  {
    name: "Latvian",
    key: "lv-LV",
  },
  {
    name: "German",
    key: "de-DE",
  },
  {
    name: "Dutch",
    key: "nl-NL",
  },
  {
    name: "Lithuanian",
    key: "lt-LT",
  },
  {
    name: "Polish",
    key: "pl-PL",
  },
  {
    name: "French",
    key: "fr-FR",
  },
  {
    name: "Spanish",
    key: "es-ES",
  },
  {
    name: "Danish",
    key: "da-DK",
  },
  {
    name: "Swedish",
    key: "sv-SE",
  },
  {
    name: "Norwegian",
    key: "nb-NO",
  },
  {
    name: "Estonian",
    key: "et-EE",
  },
] as const;
