import {
  Await,
  useAsyncValue,
  useFetcher,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import { parseISO } from "date-fns";
import { orderBy } from "lodash";
import { ChangeEvent, Suspense, useCallback, useMemo } from "react";
import { usePostHog } from "posthog-js/react";
import { EntityBuilder } from "@jobilla/entity";
import SelectBox from "@/components/forms/select-box";
import { InvoiceStatus, readonlyInvoiceStatuses, statusToName } from "@/entities/invoice-status";
import { InvoiceStatusHistory } from "@/entities/invoice-status-history";
import * as DateComponent from "@/components/date";
import * as Timeline from "@/components/timeline";
import { User } from "@/entities/user";
import { Billing } from "@/entities/billing";
import { RootLoaderData } from "@/root";
import { InvoiceDetailsData } from "./loader";

export function InvoiceStatusWithHistoryTimeline() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const { user } = useRouteLoaderData("root") as RootLoaderData;
  const posthog = usePostHog();
  const fetcher = useFetcher();
  const errors = fetcher.data?.errors;

  const historicStatusFromFetcher = useMemo(
    () =>
      fetcher.formData
        ? EntityBuilder.buildOne(InvoiceStatusHistory, {
            status: fetcher.formData.get("status")?.toString(),
            user_id: user.id,
            created_at: new Date().toISOString(),
          })
        : null,
    [fetcher.formData],
  );

  const statusesSortedByDate = orderBy(
    [
      ...invoice.historicStatuses,
      ...(historicStatusFromFetcher ? [historicStatusFromFetcher] : []),
    ],
    (status) => parseISO(status.createdAt).getTime(),
    "desc",
  );

  const onStatusSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      posthog.capture("Invoice Status Changed", {
        newInvoiceStatus: event.currentTarget.value,
        billingDetailsAvailable: invoice.customer instanceof Billing,
        invoiceStatus: invoice.status,
      });

      fetcher.submit(
        { invoiceId: invoice.id, status: event.currentTarget.value },
        { method: "post", action: `/invoices/${invoice.id}/update-status` },
      );
    },
    [fetcher],
  );

  const statusesToDisplay = useMemo(() => {
    return Object.values(InvoiceStatus).filter((status) => {
      /*
       * There are two conditions for a status to be displayed in the dropdown:
       *
       * 1. It's NOT a readonly status
       * 2. It's the current status of the invoice
       *
       * If the status is readonly but also the current status of the invoice,
       * it will be rendered as a disabled option.
       */
      return !readonlyInvoiceStatuses.includes(status) || status === invoice.status;
    });
  }, [invoice.status]);

  return (
    <div>
      <SelectBox
        name="status"
        value={invoice.status}
        label="Invoice status"
        disabled={fetcher.state === "submitting"}
        errors={errors?.status}
        onChange={onStatusSelect}
      >
        {statusesToDisplay.map((status) => (
          <option key={status} value={status} disabled={readonlyInvoiceStatuses.includes(status)}>
            {statusToName(status)}
          </option>
        ))}
      </SelectBox>

      <div className="my-4">
        <Timeline.Root>
          {statusesSortedByDate.map((status: InvoiceStatusHistory, index) => (
            <Timeline.Item
              icon={index === 0 ? Timeline.ItemIcon.MapPin : Timeline.ItemIcon.BlueDot}
              size={index === 0 ? Timeline.ItemIconSize.Huge : Timeline.ItemIconSize.Regular}
              key={status.createdAt}
            >
              <Timeline.Label>{statusToName(status.status)}</Timeline.Label>
              <Timeline.Subtext>
                <DateComponent.default
                  date={status.createdAt}
                  display={DateComponent.DateDisplayType.DATE}
                />{" "}
                — <StatusActor userId={status.userId} />
              </Timeline.Subtext>
            </Timeline.Item>
          ))}
        </Timeline.Root>
      </div>
    </div>
  );
}

function StatusActor({ userId }: { userId: number | null }) {
  const { jobillaUsers } = useLoaderData() as InvoiceDetailsData;

  if (!userId) {
    return <em>Jobilla Order Management</em>;
  }

  return (
    <Suspense fallback={<em>User #{userId}</em>}>
      <Await resolve={jobillaUsers}>
        <StatusActorName userId={userId} />
      </Await>
    </Suspense>
  );
}

function StatusActorName({ userId }: { userId: number | null }) {
  const jobillaUsers = useAsyncValue() as User[];
  const user = useMemo(
    () => jobillaUsers.find((user) => user.id === userId),
    [jobillaUsers, userId],
  );

  if (!user) {
    return <em>User #{userId} (Could not find user)</em>;
  }

  return <>{user.name}</>;
}
