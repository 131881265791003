import { useRouteLoaderData } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { UserSelectionField } from "@/routes/orders.create/user-selection-field";
import { RootLoaderData } from "@/root";
import { User } from "@/entities/user";
import { Money } from "@/entities/money";
import { MoneyDisplay } from "@/components/money-display";
import { OrderItemsContext } from "./order-items";

export function Sellers() {
  const { user } = useRouteLoaderData("root") as RootLoaderData;
  const [firstUser, setFirstUser] = useState<User>(user);
  const [secondUser, setSecondUser] = useState<User | null>(null);
  const [thirdUser, setThirdUser] = useState<User | null>(null);
  const { total } = useContext(OrderItemsContext);

  const firstSellerShare = useMemo(() => {
    let firstUserAmount = total.amount * 0.6;
    if (!secondUser) {
      firstUserAmount += total.amount * 0.25;
    }
    if (!thirdUser) {
      firstUserAmount += total.amount * 0.15;
    }
    return new Money(firstUserAmount, total.currency);
  }, [total, secondUser, thirdUser]);

  const secondSellerShare = useMemo(() => {
    return new Money(total.amount * 0.25, total.currency);
  }, [total]);

  const thirdSellerShare = useMemo(() => {
    return new Money(total.amount * 0.15, total.currency);
  }, [total]);

  return (
    <>
      <p className="mt-4">
        Orders are credited to the sellers who made the sale. If multiple people are listed as
        sellers, <b>the order value is split</b> between them.
      </p>

      <div className="mt-6 grid grid-cols-2 gap-4">
        <UserSelectionField
          name="seller_1"
          label="Deal owner (60%)"
          value={firstUser}
          onChange={setFirstUser}
          description={
            <>
              Credited with <MoneyDisplay amount={firstSellerShare} />
            </>
          }
        />

        <UserSelectionField
          name="seller_2"
          label="Executive (25%)"
          value={secondUser}
          optional
          onChange={setSecondUser}
          description={
            secondUser ? (
              <>
                Credited with <MoneyDisplay amount={secondSellerShare} />
              </>
            ) : undefined
          }
        />

        <UserSelectionField
          name="seller_3"
          label="SDR (15%)"
          value={thirdUser}
          optional
          onChange={setThirdUser}
          description={
            thirdUser ? (
              <>
                Credited with <MoneyDisplay amount={thirdSellerShare} />
              </>
            ) : undefined
          }
        />
      </div>
    </>
  );
}
