import { Entity } from "@jobilla/entity";

export class JobList extends Entity {
  id!: number;
  botId!: string;
  name!: string;
  title!: string;
  text!: string;
  subtitle!: string;
  button!: string;
  useTitle = false;
  domain!: string;
  subdomain!: string;
  stringIdentifier!: string;
  published = false;
  locale = "fi";
  activeJobCount!: number;
}
