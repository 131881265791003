import { Outlet, useLoaderData, useSearchParams } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/solid";
import SecondaryLink from "@/components/secondary-link";
import * as Page from "@/components/page";
import CompanyAccountManager from "./account-manager";
import BusinessUnit from "./business-unit";
import UnspentCampaigns from "./unspent-campaigns";
import CompanyOrders from "./orders";
import CompanyBillings from "./billings";
import CompanyHubspotLink from "./hubspot";
import CompanyNotes from "./notes";
import Booster from "./booster";
import OrganizationStructure from "./organization-structure";
import { CompanyDetailsData } from "./loader";

export { ErrorBoundary } from "./error-boundary";
export { loader, shouldRevalidate } from "./loader";
export { action } from "./action";

export default function CompaniesShow() {
  const { company } = useLoaderData() as CompanyDetailsData;
  const [searchParams] = useSearchParams();

  const backLink = new URL("/companies", window.location.origin);
  backLink.search = new URLSearchParams({
    // Non-null assertion is safe here because we check for the existence of the key
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...(searchParams.has("company_search") ? { search: searchParams.get("company_search")! } : {}),
  }).toString();

  return (
    <Page.Root>
      <Page.Heading
        backLink={
          <Page.GoBackLink to={backLink.toString()}>Back to companies list</Page.GoBackLink>
        }
      >
        <div className="flex justify-between items-center gap-4">
          <Page.Title>{company.name}</Page.Title>
          <SecondaryLink to="name" size="lg">
            <PencilIcon className="inline-block w-4 h-4 mr-1" />
            <span>Edit Company</span>
          </SecondaryLink>
        </div>
      </Page.Heading>

      <Page.Content multiColumnLayout>
        <Page.Main>
          <UnspentCampaigns />
          <Booster />
          <CompanyOrders />
          <CompanyBillings />
        </Page.Main>

        <Page.ColumnSeparator />

        <Page.Sidebar>
          <CompanyNotes />
          <CompanyHubspotLink />
          <CompanyAccountManager />
          <BusinessUnit />
          <OrganizationStructure />
        </Page.Sidebar>
      </Page.Content>

      <Outlet />
    </Page.Root>
  );
}
