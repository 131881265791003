import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { Payee } from "@/entities/payee";

export async function fetchPayee(id: string | number): Promise<Payee> {
  const response = await api.get(`v1/payees/${id}`).json<{ data: PropsJson<Payee> }>();

  return EntityBuilder.buildOne(Payee, response.data);
}

export async function fetchPayees(): Promise<Payee[]> {
  const response = await api.get(`v1/payees`).json<{ data: PropsJson<Payee>[] }>();

  return EntityBuilder.buildMany(Payee, response.data);
}
