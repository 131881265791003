import { ActionFunctionArgs, redirect } from "react-router-dom";
import {
  array,
  date,
  InferType,
  mixed,
  number,
  object,
  SchemaOf,
  string,
  ValidationError,
} from "yup";
import { HTTPError } from "ky";
import { PartialPropsJson, PropsJson } from "@jobilla/entity";
import { isFinite } from "lodash";
import { parseFormData } from "parse-nested-form-data";
import { format, startOfToday } from "date-fns";
import { Company } from "@/entities/company";
import { InvoiceCondition } from "@/entities/invoice-condition";
import { Payee } from "@/entities/payee";
import { User } from "@/entities/user";
import { Product } from "@/entities/product";
import { processYupErrors } from "@/helpers/yup-errors";
import { createOrder } from "@/api/order";
import { Order } from "@/entities/order";
import { Currency } from "@/entities/currency";
import { OrderUser } from "@/entities/order-user";

export type OrderCreateInput = {
  hubspot_deal_id?: string;
  company_id: Company["id"];
  order_items: Required<
    Array<{
      id: string;
      product_id: Product["id"];
      price: number;
    }>
  >;
  seller_1: User["id"];
  seller_2?: User["id"];
  seller_3?: User["id"];
  latest_invoicing_date: Date;
  invoices: Array<{
    condition: InvoiceCondition;
    send_at?: Date;
    custom_condition: string;
    items: Array<{
      order_item_id: string;
      price: number;
    }>;
  }>;
  payee_id: Payee["id"];
  notes: string;
};

type OrderCreationData = PartialPropsJson<Order> & {
  amount: number;
  currency: Currency;
  custom_invoices: Array<{
    amount: {
      amount: number;
      currency: Currency;
    };
    condition: InvoiceCondition;
    date?: string;
    latest_date: string;
    order_items: Array<{
      order_item_id: string;
      amount: number;
    }>;
    payment_terms: number;
  }>;
};

export type OrdersNewPageActionData =
  | {
      status: number;
    }
  | {
      status: 422;
      errors: Record<string, string[]>;
    }
  | Order;

export async function action({ request }: ActionFunctionArgs): Promise<OrdersNewPageActionData> {
  let input: InferType<ReturnType<typeof getValidationSchema>>;
  const rawInput = parseFormData(await request.formData()) as unknown as OrderCreateInput;

  try {
    input = await getValidationSchema(rawInput).validate(rawInput, { abortEarly: false });
  } catch (e) {
    if (e instanceof ValidationError) {
      return {
        errors: processYupErrors(e),
        status: 422,
      };
    }

    throw e;
  }

  const orderData = generateOrderCreationData(input);

  try {
    const order = await createOrder(orderData);
    return redirect(`/orders/${order.id}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      const errors = (await e.response.json()).errors as Record<string, string[]>;

      errors.hubspot_deal_id = errors.hubspot_deal_id?.map((error) => {
        try {
          return JSON.parse(error).message;
        } catch (e) {
          return error;
        }
      });

      return {
        status: 422,
        errors,
      };
    }

    return { status: 500 };
  }
}

function getValidationSchema(input: OrderCreateInput) {
  const hubspotDealUrlOrIdPattern =
    /(?:app\.hubspot\.com\/contacts\/\d+\/record\/0-[23]\/)?(\d+)\/?/;

  return object({
    hubspot_deal_id: string()
      .transform((value: string | undefined) => {
        return value?.match(hubspotDealUrlOrIdPattern)?.[1] ?? value;
      })
      .matches(hubspotDealUrlOrIdPattern, {
        excludeEmptyString: true,
        message: "Incorrect value. Either use a full HubSpot deal link, or just a numeric deal ID.",
      }),
    company_id: number()
      .transform((value) => (isFinite(+value) ? +value : undefined))
      .required("You must select a company."),
    order_items: array()
      .min(1, "You must add at least one product.")
      .of(
        object({
          id: string().required(),
          product_id: string().required(),
          price: number().required().min(0, "Price cannot be negative."),
        }),
      )
      .ensure(),
    seller_1: number()
      .transform((value) => (isFinite(+value) ? +value : undefined))
      .required("You must select the first seller."),
    seller_2: number().transform((value) => (isFinite(+value) ? +value : undefined))
      .notOneOf([+input.seller_1], "Second seller must be different from the first seller."),
    seller_3: number().transform((value) => (isFinite(+value) ? +value : undefined))
      .notOneOf([+input.seller_1], "Third seller must be different from the first seller."),
    latest_invoicing_date: date()
      .typeError("You must select the latest invoicing date.")
      .required("You must select the latest invoicing date.")
      .min(startOfToday(), "Earliest selectable date is tomorrow."),
    invoice_split: string().required("You must select an invoice split method."),
    invoices: array()
      .min(1)
      .of(
        object({
          condition: mixed().oneOf(Object.values(InvoiceCondition)),
          send_at: date()
            .typeError("You must select the latest invoicing date.")
            .min(startOfToday(), "Earliest selectable date is tomorrow.")
            .when("condition", {
              is: InvoiceCondition.SendingDate,
              then: (schema) => schema.required(),
            }),
          custom_condition: string()
            .ensure()
            .when("condition", {
              is: InvoiceCondition.Custom,
              then: (schema) => schema.required(),
            }),
          items: array()
            .min(1)
            .of(
              object({
                order_item_id: string().required(),
                price: number().required(),
              }),
            )
            .ensure(),
        }),
      )
      .ensure(),
    payee_id: string().required("You must select a payee."),
    notes: string().ensure(),
  }) satisfies SchemaOf<OrderCreateInput>;
}

function generateOrderCreationData(input: OrderCreateInput): OrderCreationData {
  const orderTotalAmount = input.order_items.reduce((total, item) => total + item.price * 100, 0);

  return {
    origin: "manual",
    payment_preference: "invoice",
    hubspot_deal_id: input.hubspot_deal_id,
    company_id: input.company_id,
    payee_id: input.payee_id,
    notes: input.notes,
    amount: orderTotalAmount,
    currency: Currency.EUR,
    users: [
      {
        user_id: input.seller_1,
        amount: {
          amount: checkAmountForDealOwner(input, orderTotalAmount),
          currency: Currency.EUR,
        },
      },
      input.seller_2 ? {
        user_id: input.seller_2,
        amount: {
          amount: orderTotalAmount * 0.25,
          currency: Currency.EUR,
        },
      } : null,
      input.seller_3 ? {
        user_id: input.seller_3,
        amount: {
          amount: orderTotalAmount * 0.15,
          currency: Currency.EUR,
        },
      } : null,
    ].filter((user): user is PropsJson<OrderUser> => user !== undefined),
    custom_invoices: input.invoices.map((invoice: OrderCreateInput["invoices"][number]) => ({
      amount: {
        amount: invoice.items.reduce((total, item) => total + item.price * 100, 0),
        currency: Currency.EUR,
      },
      condition: invoice.condition,
      date: invoice.send_at ? format(invoice.send_at, "yyyy-MM-dd") : undefined,
      latest_date: format(input.latest_invoicing_date, "yyyy-MM-dd"),
      order_items: invoice.items.map((item) => ({
        order_item_id: item.order_item_id,
        amount: item.price * 100,
      })),
      payment_terms: 14,
    })),
    items: input.order_items.map((item) => ({
      id: item.id,
      product_id: item.product_id,
      amount: {
        amount: item.price * 100,
        currency: Currency.EUR,
      },
    })),
  };
}

function checkAmountForDealOwner(input: OrderCreateInput, orderTotalAmount: number) {
  let amount = orderTotalAmount * 0.6;
  if (!input.seller_2) {
    amount += orderTotalAmount * 0.25;
  }
  if (!input.seller_3) {
    amount += orderTotalAmount * 0.15;
  }
  return amount;
}
