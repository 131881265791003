import { TrashIcon } from "@heroicons/react/20/solid";
import { useCallback } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import { DangerButton } from "@/components/danger-button";
import { deleteCompanyBilling } from "@/api/billing";
import { Billing } from "@/entities/billing";
import { Tooltip } from "@/components/tooltip";

type DeleteButtonProps = {
  billing: Billing;
};

export function DeleteButton({ billing }: DeleteButtonProps) {
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const deleteThis = useCallback(async () => {
    if (!billing?.id) {
      return;
    }

    if (!confirm("Are you sure you want to delete this billing?")) {
      return;
    }

    await deleteCompanyBilling(billing.id);
    navigate(`/companies/${billing.companyId}`, { preventScrollReset: true });
    revalidate();
  }, [billing?.id]);

  const button = (
    <DangerButton
      disabled={billing.isDeletable === false}
      onClick={deleteThis}
      type="button"
      size="lg"
    >
      <TrashIcon className="w-4 h-4 mr-1 -ml-1" />
      <span>Delete</span>
    </DangerButton>
  );

  if (billing.isDeletable === false) {
    return (
      <Tooltip content="This billing is attached to invoices and cannot be deleted">
        {button}
      </Tooltip>
    );
  }

  return button;
}
