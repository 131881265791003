import { Entity, Type } from "@jobilla/entity";
import { Unit } from "./unit";
import { HubspotCompany } from "./hubspot-company";

export class Company extends Entity {
  id!: number;
  name!: string;
  lang = "fi-FI";
  status!: string;
  createdAt!: string;
  taId!: number;
  updatedAt!: string;

  @Type(HubspotCompany)
  hubspot!: HubspotCompany;

  @Type(Unit)
  units: Unit[] = [];
  unitId!: string;

  get companyUnit(): Unit {
    return this.units?.[0];
  }

  set companyUnit(unit: Unit) {
    this.units[0] = unit;
  }
}
