import { LoaderFunctionArgs, useLoaderData, useLocation, useNavigation } from "react-router-dom";
import { fetchInvoices } from "@/api/invoice";
import InvoiceListTable from "@/routes/invoices/invoices-list-table";
import InvoicesIndexFilters from "@/routes/invoices/invoices-index-filters";
import { optional } from "@/helpers/loader-optional";
import { fetchAllUnits } from "@/api/unit";
import * as Page from "@/components/page";

export type InvoiceListData = Awaited<ReturnType<typeof loader>>;

export async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  const orderDirection =
    searchParams.get("order_direction") === "asc" || searchParams.get("order_direction") === "desc"
      ? searchParams.get("order_direction")
      : "desc";

  const [invoices, businessUnits] = await Promise.all([
    fetchInvoices({
      status: searchParams.get("status") ?? "ready_to_send",
      per_page: 25,
      current_page: Number(searchParams.get("page") ?? 1),
      unit: searchParams.get("unit") ?? "",
      order_direction: orderDirection,
    }),
    optional(fetchAllUnits()),
  ]);

  return {
    invoices,
    businessUnits,
  };
}

export default function InvoicesIndexPage() {
  const location = useLocation();
  const transition = useNavigation();
  const { invoices } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const isLoading =
    transition.state !== "idle" && transition.location?.pathname === location.pathname;

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Invoices</Page.Title>
      </Page.Heading>

      <Page.Content>
        <InvoicesIndexFilters />
        <InvoiceListTable isLoading={isLoading} invoices={invoices} />
      </Page.Content>
    </Page.Root>
  );
}
