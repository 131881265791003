import { Entity, Type } from "@jobilla/entity";

export class ItemWithIdAndNumericId extends Entity  {
  id!: string;
  numericId!: number;
}

export class ItemWithIdAndName extends Entity {
  id!: string;
  name!: string;
}

export class InvoiceTransferDetails extends Entity {
  @Type(ItemWithIdAndName)
  toCompany!: ItemWithIdAndName;
  @Type(ItemWithIdAndNumericId)
  toOrder!: ItemWithIdAndNumericId;
  @Type(ItemWithIdAndNumericId)
  newInvoice!: ItemWithIdAndNumericId;
  @Type(ItemWithIdAndNumericId)
  transferredOrderItems!: ItemWithIdAndNumericId[];
}
