import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import invariant from "tiny-invariant";
import * as Sentry from "@sentry/react";
import { updateOrderItem } from "@/api/order-item";
import { OrderItemStatus } from "@/entities/order-item-status";
import { processYupErrors } from "@/helpers/yup-errors";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);

  let input;

  try {
    input = await Yup.object()
      .shape({
        status: Yup.mixed<OrderItemStatus>().oneOf(Object.values(OrderItemStatus)).required(),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    Sentry.captureException(e);

    invariant(e instanceof Yup.ValidationError);

    return {
      status: 422,
      message: e.message,
      errors: processYupErrors(e),
    };
  }

  try {
    await updateOrderItem(params.orderItem, { status: input.status });
    return { status: 201 };
  } catch (e) {
    Sentry.captureException(e);
    return { status: 500 };
  }
}
