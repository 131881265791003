import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import ErrorGeneric from "@/components/error-pages/generic";

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 422) {
    return <ErrorGeneric title={error.data.message} content={error.data.description} />;
  }

  throw error;
}
