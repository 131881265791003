import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { HTTPError } from "ky";
import { useEffect } from "react";
import Error404 from "@/components/error-pages/404";
import ErrorGeneric from "@/components/error-pages/generic";

export function ErrorBoundary() {
  const error = useRouteError();

  if (import.meta.env.DEV) {
    useEffect(() => {
      console.error(error);
    }, [error]);
  }

  if (error instanceof HTTPError && error.response.status === 404) {
    return <Error404 title="No such company" />;
  }

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <Error404 title="No such company" />;
  }

  return <ErrorGeneric error={error} />;
}
