import { Link, useLoaderData } from "react-router-dom";
import SidebarBox from "@/routes/companies.$company/sidebar-box";
import SidebarBoxAction from "@/routes/companies.$company/sidebar-box-action";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { StyledHtmlContent } from "@/components/styled-html-content";
import type { CompanyDetailsData } from "./loader";

export default function CompanyNotes() {
  return (
    <SidebarBox
      title="Notes"
      action={
        <Link to="notes">
          <SidebarBoxAction>Change</SidebarBoxAction>
        </Link>
      }
    >
      <CompanyNotesContents />
    </SidebarBox>
  );
}

function CompanyNotesContents() {
  const { notes } = useLoaderData() as CompanyDetailsData;

  if (notes instanceof FailedOptionalResult) {
    return <ErrorState />;
  }

  if (!notes?.content) {
    return <p className="text-sm text-slate-500">No notes here yet. Want to add a new one?</p>;
  }

  return <StyledHtmlContent content={notes.htmlContent} />;
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load notes.</p>
    </div>
  );
}
