import { Children, InputHTMLAttributes, MutableRefObject, ReactNode, useId } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { Label } from "@/components/forms/label";
import { useActionErrors } from "@/hooks/useActionErrors";
import { mergeRefs } from "@/components/forms/merge-refs";
import RequiredAsterisk from "./required-asterisk";

export type SelectBoxProps = {
  name: string;
  errors?: string[] | null;
  label?: ReactNode;
  description?: ReactNode;
  inputRef?: MutableRefObject<HTMLSelectElement | null>;
} & InputHTMLAttributes<HTMLSelectElement>;

export default function SelectBox({
  name,
  errors,
  label,
  children,
  description,
  inputRef,
  className,
  ...props
}: SelectBoxProps) {
  const inputId = props.id ?? useId();
  const actionErrors = useActionErrors();

  const { register, formState, control } = useFormContext() || { register: () => {} };

  const validationError = formState?.errors?.[name ?? ""]
    ? [formState?.errors?.[name ?? ""]?.message]
    : null;

  errors = errors ?? validationError ?? actionErrors?.[name ?? ""];

  return (
    <div className="flex flex-col justify-stretch">
      {label && (
        <Label htmlFor={inputId}>
          {label} {props.required && <RequiredAsterisk />}
        </Label>
      )}

      {control && register ? (
        <Controller
          defaultValue={props.defaultValue}
          name={name}
          control={control}
          render={({ field: { ref, ...controllerRest } }) => (
            <select
              {...register(name)}
              ref={inputRef ? mergeRefs(inputRef, ref) : ref}
              className={clsx(
                "rounded-md border-0 ring-1 focus:outline-0 focus:ring-1 focus:ring-purple-500 focus:bg-purple-50 transition duration-200",
                className,
                clsx(className).search(/\bw-/) !== -1 ? "" : "w-full",
                errors?.length ? "ring-red-600 bg-red-50" : "ring-stone-300",
              )}
              id={inputId}
              {...props}
              {...controllerRest}
            >
              {Children.toArray(children)}
            </select>
          )}
        />
      ) : (
        <select
          name={name}
          ref={inputRef}
          className={clsx(
            "rounded-md border-0 ring-1 focus:outline-0 focus:ring-1 focus:ring-purple-500 focus:bg-purple-50 transition duration-200",
            className,
            clsx(className).search(/\bw-/) !== -1 ? "" : "w-full",
            errors?.length ? "ring-red-600 bg-red-50" : "ring-stone-300",
          )}
          id={inputId}
          {...props}
        >
          {Children.toArray(children)}
        </select>
      )}

      {errors?.map((errorText) => (
        <p className="text-sm mt-1 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      ))}

      {description && <p className="text-sm mt-2 text-stone-500">{description}</p>}
    </div>
  );
}
