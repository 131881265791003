import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { LandingPage } from "@/entities/landing-page";

export async function createLandingPage(data: PartialPropsJson<LandingPage>) {
  const response = await api
    .post(`api/v2/landing-pages`, {
      json: data,
    })
    .json<{ data: PartialPropsJson<LandingPage> }>();

  return EntityBuilder.buildOne(LandingPage, response.data);
}

export async function linkJobListsToLandingPage(landingPageId: number, jobListIds: number[]) {
  return api.put(`api/v2/landing-pages/${landingPageId}/job-lists`, {
    json: {
      job_lists: jobListIds,
    },
  });
}
