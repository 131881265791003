import { Entity, Type } from "@jobilla/entity";
import { finvoiceOperators } from "@/helpers/finvoice-operators";
import { BillingAddress } from "./billing-address";
import { Company } from "./company";
import { Currency } from "./currency";
import { PaymentTerms } from "./payment-terms";

export class Billing extends Entity {
  id!: string;
  name!: string;
  email!: string;
  currency: Currency = Currency.EUR;
  paymentTerms = PaymentTerms.Days14;
  vat!: string;
  reference!: string;
  note!: string;
  isDefault = false;
  isDeletable: boolean | null = null;
  customerCode!: string;

  @Type(BillingAddress)
  address = new BillingAddress();

  @Type(Company)
  company = new Company();
  companyId!: number;

  finvoiceAddress!: string;
  finvoiceOperator!: string;

  get hasFinvoiceAddress() {
    return this.finvoiceAddress !== null;
  }

  get finvoiceOperatorObject() {
    if (!this.finvoiceOperator) {
      return null;
    }

    return finvoiceOperators.find((operator) => operator.id === this.finvoiceOperator);
  }
}
