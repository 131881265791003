import { ActionFunctionArgs } from "react-router-dom";
import { cancelUserInvite } from "@/api/jobilla-users";

export async function action({ params }: ActionFunctionArgs) {
  if (!params.user) {
    return {
      status: 404,
    };
  }

  try {
    await cancelUserInvite(+params.user);
    return { status: 201 };
  } catch (error) {
    return { status: 500 };
  }
}
