import { CheckCircleIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import * as DropdownMenu from "@/components/dropdown-menu";
import { Confirmation } from "@/components/confirmation";
import { UserDropdownMenuContext } from "@/routes/units.$unit/user-dropdown";

export function ResendInvite() {
  const { user } = useContext(UserDropdownMenuContext);
  const [hasResentInvitation, setHasResentInvitation] = useState(false);

  return (
    <Confirmation
      formAction={`/api/invites/${user.id}/resend`}
      title="Resend invitation"
      description={
        <>
          Resending an invitation will revoke all previous invitation links. <b>Are you sure?</b>
        </>
      }
      submitLabel="Resend invitation"
      onSuccess={() => {
        setHasResentInvitation(true);
      }}
    >
      <DropdownMenu.ButtonItem
        autoClose={false}
        icon={
          hasResentInvitation ? (
            <CheckCircleIcon className="w-5 h-5 animate-bounce" />
          ) : (
            <ChevronDoubleRightIcon className="w-4 h-4" />
          )
        }
        label={hasResentInvitation ? "Resent invitation!" : "Resend invitation..."}
        subtext="Re-invite this user. Previous invite link will be revoked."
        onClick={() => {
          setHasResentInvitation(false);
        }}
      />
    </Confirmation>
  );
}
