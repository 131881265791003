import { Entity, Type } from "@jobilla/entity";
import { User } from "./user";

export class InvoiceNote extends Entity {
  id!: string;
  invoiceId!: string;
  userId!: string;
  content!: string;
  createdAt!: string;

  @Type(User)
  user!: User;
}
