import type { ReactNode } from "react";
import { Link, NavLink, To } from "react-router-dom";
import Arrow from "@/components/icons/arrow";

type MenuSectionProps = {
  children: ReactNode;
  title: string;
  createLink?: To;
};

export default function MenuSection({ children, title, createLink }: MenuSectionProps) {
  return (
    <div className="mt-6 pr-2 ml-2">
      <div className="flex justify-between items-center pl-8 pr-2">
        <p className="text-gray-500 font-bold uppercase text-xs">{title}</p>

        {createLink && (
          <Link to={createLink} className="text-violet-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="presentation"
              className="w-3.5 h-3.5 fill-current"
            >
              <path d="M.5 12A11.5 11.5 0 1012 .5 11.513 11.513 0 00.5 12zm5-.5a1 1 0 011-1h3.75a.25.25 0 00.25-.25V6.5a1 1 0 011-1h1a1 1 0 011 1v3.75a.25.25 0 00.25.25h3.75a1 1 0 011 1v1a1 1 0 01-1 1h-3.75a.25.25 0 00-.25.25v3.75a1 1 0 01-1 1h-1a1 1 0 01-1-1v-3.75a.25.25 0 00-.25-.25H6.5a1 1 0 01-1-1z" />
            </svg>
          </Link>
        )}
      </div>

      <div className="pt-3">{children}</div>
    </div>
  );
}

type MenuSectionItemProps = {
  children: ReactNode;
  href: string;
};

MenuSection.Item = function Item({ children, href }: MenuSectionItemProps) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        [
          "flex transition duration-150 p-2 pl-3 rounded",
          isActive
            ? "bg-purple-100 bg-opacity-70 text-violet-700 font-black"
            : "hover:bg-gray-400/20",
        ]
          .filter(Boolean)
          .join(" ")
      }
    >
      {children}
    </NavLink>
  );
};

type MenuSectionSubItemProps = {
  children: ReactNode;
  href: string;
};

MenuSection.SubItem = function SubItem({ children, href }: MenuSectionSubItemProps) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        [
          "flex transition duration-150 p-2 pl-8 rounded text-sm",
          isActive
            ? "bg-purple-100 bg-opacity-70 text-violet-700 font-black"
            : "hover:bg-gray-400/20",
        ]
          .filter(Boolean)
          .join(" ")
      }
    >
      <Arrow />
      {children}
    </NavLink>
  );
};

MenuSection.Divider = function Divider() {
  return <hr className="mt-4 border-zinc-300 mx-4" />;
};
