import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { CompanyRelation } from "@/entities/CompanyRelation";

export async function fetchOrganizationStructure(companyId: number): Promise<CompanyRelation[]> {
  const responseJson = await api
    .get(`organisation-structure/v1/${companyId}`)
    .json<{ relation: PropsJson<CompanyRelation>[] }>();

  return EntityBuilder.buildMany(CompanyRelation, responseJson.relation ?? []);
}
