import { Link, LinkProps } from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";
import type { OrderItem } from "@/entities/order-item";

type OrderItemSetupStatusPillProps = {
  item: OrderItem;
  linkProps?: Omit<LinkProps, "to">;
};

export function OrderItemSetupStatusPill({ item, linkProps }: OrderItemSetupStatusPillProps) {
  const colors = item.hasContext
    ? "bg-green-100 text-green-600 hover:bg-green-300 hover:text-green-700"
    : "bg-yellow-100 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800";

  return (
    <Tooltip.Root delayDuration={0} disableHoverableContent>
      <Tooltip.Trigger asChild>
        <Link
          to={
            item.hasContext ? `/order-items/${item.id}` : `/order-items/${item.id}/campaign-request`
          }
          {...linkProps}
          className={[
            "inline-block rounded-full text-xs py-0.5 px-2 whitespace-nowrap font-bold",
            colors,
          ].join(" ")}
        >
          {item.hasContext ? "Campaign setup complete" : "Campaign needs setup"}
        </Link>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content side="right" sideOffset={4}>
          <div className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2">
            <span>
              {item.campaignContext ? (
                `Job name: ${item.campaignContext.jobName}`
              ) : (
                <em>Campaign details are not yet provided</em>
              )}
            </span>
          </div>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}
