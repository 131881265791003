import { LoaderFunctionArgs } from "react-router-dom";
import { fetchCompanyOrders } from "@/api/company-order";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";

export async function loader({ request, params }: LoaderFunctionArgs) {
  assertParameterIsNumeric(params.company);

  const search = new URL(request.url).searchParams;

  const orders = await fetchCompanyOrders(params.company, {
    page: Math.max(0, Number(search.get("page") ?? 1)),
    perPage: 10,
  });

  return {
    orders,
  };
}
