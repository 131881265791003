export interface FinvoiceOperator {
  id: string;
  name: string;
}
// source: https://support.netvisor.fi/en/support/solutions/articles/77000466865-resources-customer-register
export const finvoiceOperators: FinvoiceOperator[] = [
  {
    name: "Aktia / Paikallisosuuspankki",
    id: "HELSFIHH",
  },
  {
    name: "Ålandsbanken",
    id: "AABAFI22",
  },
  {
    name: "Apix Messaging Oy",
    id: "003723327487",
  },
  {
    name: "Apix Messaging Oy (old code)",
    id: "00372332748700001",
  },
  {
    name: "BasWare Einvoices Oy",
    id: "BAWCFI22",
  },
  {
    name: "CGI Suomi (ent. Logica Suomi Oy)",
    id: "003703575029",
  },
  {
    name: "Danske Bank",
    id: "DABAFIHH",
  },
  {
    name: "DNB Bank ASA, Finland Branch",
    id: "DNBAFIHX",
  },
  {
    name: "Enfo",
    id: "003714377140",
  },
  {
    name: "Handelsbanken",
    id: "HANDFIHH",
  },
  {
    name: "InExchange Factorum AB",
    id: "INEXCHANGE",
  },
  {
    name: "InExchange Factorum AB (old code)",
    id: "SE556700997101",
  },
  {
    name: "Maventa",
    id: "003721291126",
  },
  {
    name: "Netbox Finland Oy",
    id: "003726044706",
  },
  {
    name: "Nordea",
    id: "NDEAFIHH",
  },
  {
    name: "Notebeat Oy",
    id: "003717203971",
  },
  {
    name: "OpusCapita Solutions Oy",
    id: "E204503",
  },
  {
    name: "Osuuspankki",
    id: "OKOYFIHH",
  },
  {
    name: "Pagero",
    id: "003723609900",
  },
  {
    name: "POP Pankki",
    id: "POPFFI22",
  },
  {
    name: "PostNord Strålfors Oy",
    id: "003701150617",
  },
  {
    name: "Ropo Capital",
    id: "003714377140",
  },
  {
    name: "Säästöpankki",
    id: "ITELFIHH",
  },
  {
    name: "S-Pankki/LähiTapiola",
    id: "SBANFIHH",
  },
  {
    name: "Sproom ApS",
    id: "SPROOM",
  },
  {
    name: "Telia Finland Oyj",
    id: "003703575029",
  },
  {
    name: "Tieto Oyj",
    id: "003701011385",
  },
  {
    name: "Peppol",
    id: "PEPPOL",
  },
  {
    name: "Opentext Oy (ent Liaison Technologies Oy)",
    id: "003708599126",
  },
];
