import { Link } from "react-router-dom";
import clsx from "clsx";

type PaginatorArgs = { currentPage: number; lastPage: number; baseUrl: string };

export function Paginator({ currentPage, lastPage, baseUrl }: PaginatorArgs) {
  const LINKS_AROUND_CURRENT_PAGE = 2;

  const linksFrom = Math.max(1, currentPage - LINKS_AROUND_CURRENT_PAGE);
  const linksUntil = Math.min(lastPage, currentPage + LINKS_AROUND_CURRENT_PAGE);
  const linksToRender: number[] = Array(linksUntil - linksFrom + 1)
    .fill(null)
    .map((_, index) => linksFrom + index);

  if (linksToRender[0] === LINKS_AROUND_CURRENT_PAGE + 1) {
    linksToRender.unshift(2);
  }

  if (linksToRender[0] === LINKS_AROUND_CURRENT_PAGE) {
    linksToRender.unshift(1);
  }

  if (linksToRender[linksToRender.length - 1] === lastPage - 2) {
    linksToRender.push(lastPage - 1);
  }

  if (linksToRender[linksToRender.length - 1] === lastPage - 1) {
    linksToRender.push(lastPage);
  }

  const PageLink = ({
    page,
    label,
    highlight = false,
    disabled = false,
  }: {
    page: number;
    label?: string;
    highlight?: boolean;
    disabled?: boolean;
  }) => {
    const [basePath, searchParamsOfBasePath] = baseUrl.split("?");
    const searchParams = new URLSearchParams(searchParamsOfBasePath);
    searchParams.set("page", page.toString());

    const LinkElement = disabled ? "span" : Link;

    return (
      <LinkElement
        to={`${basePath}?${searchParams.toString()}`}
        className={clsx(
          "inline-block px-4 py-1.5 font-normal",
          disabled ? "text-gray-400 cursor-default" : "",
          highlight && !disabled ? "text-purple-700 bg-violet-200 hover:scale-110" : "",
          !highlight && !disabled
            ? "hover:bg-violet-50 hover:underline decoration-2 decoration-purple-400"
            : "",
          page === currentPage && !disabled ? "text-gray-600 bg-purple-100" : "",
          "rounded-md transition-all duration-50 transform-gpu",
        )}
      >
        {label ?? page}
      </LinkElement>
    );
  };

  return (
    <nav aria-label="Pagination" className="flex items-center justify-center gap-1">
      <PageLink page={currentPage - 1} label="←" highlight disabled={currentPage === 1} />

      {linksToRender[0] !== 1 && (
        <>
          <PageLink page={1} />
          <div>...</div>
        </>
      )}

      {linksToRender.map((page) => (
        <PageLink key={page} page={page} />
      ))}

      {linksToRender[linksToRender.length - 1] !== lastPage && (
        <>
          <div>...</div>
          <PageLink page={lastPage} />
        </>
      )}

      <PageLink page={currentPage + 1} label="→" highlight disabled={currentPage === lastPage} />
    </nav>
  );
}
