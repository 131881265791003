import {
  defer,
  isRouteErrorResponse,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
  useRouteError,
  useSearchParams,
} from "react-router-dom";
import { useCallback } from "react";
import Section from "@/components/section";
import { OrderItem } from "@/entities/order-item";
import { fetchOrderItem } from "@/api/order-item";
import { Dialog } from "@/components/dialog";
import { CompanyNote } from "@/entities/company-note";
import { fetchCompanyNotes } from "@/api/company-notes";
import { assertParameterExists } from "@/helpers/loader-guards";
import { CompanyNotes } from "./company-notes";
import OrderItemDetails from "./details";
import { StatusSwitcher } from "./status-switcher";

export type OrderItemDialogData = {
  orderItem: OrderItem;
  companyNote: CompanyNote;
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);
  const companyNote = orderItem.order
    ? // At least one of those properties will be present. Terrible interface
      // design, I know... We will update these API result types some time in the
      // future to use individual return types for each of these endpoints.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fetchCompanyNotes((orderItem.order?.company?.id ?? orderItem.order?.companyId)!)
    : null;

  return defer({
    orderItem: orderItem,
    companyNote: companyNote,
  });
}

export default function OrderItemDialog() {
  const { product } = useParams();
  const { orderItem } = useLoaderData() as OrderItemDialogData;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goBackToOrderPipeline = useCallback(
    () => navigate(`/order-pipeline/${product}?${searchParams}`, { preventScrollReset: true }),
    [product],
  );

  return (
    <Dialog
      title={orderItem.product?.title ?? "Order Item"}
      isOpen={true}
      onClose={goBackToOrderPipeline}
    >
      <div className="space-y-4">
        <StatusSwitcher />

        <OrderItemDetails />

        <Section title="Company notes">
          <CompanyNotes />
        </Section>
      </div>
    </Dialog>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <Dialog isOpen title="Order Item">
      <div className="text-stone-800 space-y-8">
        <p>{isRouteErrorResponse(error) ? error.data.error : "Something went wrong"}</p>
      </div>
    </Dialog>
  );
}
