import { ResponsePromise } from "ky";
import { api } from "@/api/base";

export async function updateCompanyBusinessUnit(
  companyId: string,
  unitId: string,
): Promise<ResponsePromise> {
  return api.put(`v1/companies/${companyId}/unit`, {
    json: {
      unit_id: unitId,
    },
  });
}
