import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { HTTPError } from "ky";
import ReactQuill, { Value } from "react-quill";
import invariant from "tiny-invariant";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import SecondaryButton from "@/components/secondary-button";
import type { CompanyDetailsData } from "@/routes/companies.$company/loader";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import "react-quill/dist/quill.snow.css";
import { updateCompanyNotes } from "@/api/company-notes";
import { DeltaData } from "@/entities/company-note";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";

type ActionResult = {
  errors?: { [key: string]: string[] } | undefined;
  status?: number;
};

export async function action({ request, params }: ActionFunctionArgs): Promise<ActionResult> {
  assertParameterIsNumeric(params.company);

  const input = Object.fromEntries((await request.formData()).entries());

  try {
    await updateCompanyNotes(Number(params.company), JSON.parse(input.contents as string));
    return redirect(`/companies/${params.company}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: e.response.status,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
      errors: { name: ["Unexpected error occurred while saving the notes."] },
    };
  }
}

export default function CompanyNotesDialog() {
  const { notes } = useRouteLoaderData("company-details") as CompanyDetailsData;

  const navigate = useNavigate();
  const params = useParams();

  const close = useCallback(() => {
    navigate(`/companies/${params.company}`);
  }, []);

  if (notes instanceof FailedOptionalResult) {
    return (
      <Dialog isOpen onClose={close} title="Edit Company Note">
        <p className="text-red-600">Could not load notes.</p>
      </Dialog>
    );
  }

  return <CompanyNotesForm />;
}

function CompanyNotesForm() {
  const { notes } = useRouteLoaderData("company-details") as CompanyDetailsData;

  invariant(!(notes instanceof FailedOptionalResult));

  const navigate = useNavigate();
  const params = useParams();

  const close = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/companies/${params.company}`);
    }
  }, []);

  const [delta, setDelta] = useState<DeltaData>(notes.content.ops as unknown as DeltaData);
  const editorRef = useRef<ReactQuill>();

  useEffect(() => {
    if (editorRef.current) {
      setDelta(editorRef.current.getEditor().getContents().ops as unknown as DeltaData);
    }
  }, [editorRef]);

  const handleContentChange = useCallback(() => {
    if (editorRef.current) {
      setDelta(editorRef.current.getEditor().getContents().ops as unknown as DeltaData);
    }
  }, [editorRef]);

  return (
    <Dialog isOpen onClose={close} title="Edit Company Note">
      <Form method="post">
        <input type="hidden" name="contents" value={JSON.stringify(delta)} />

        <ReactQuill
          defaultValue={notes.content as unknown as Value}
          onChange={handleContentChange}
          ref={(el) => {
            if (el) {
              editorRef.current = el;
            }
          }}
        />
        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton size="lg" />

          <SecondaryButton type="button" size="lg" onClick={close}>
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
