import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Payee } from "@/entities/payee";
import SecondaryLink from "@/components/secondary-link";
import Date, { DateDisplayType } from "@/components/date";
import { InvoiceStatus } from "@/entities/invoice-status";
import { InvoiceDetailsData } from "./loader";

export function InvoiceSummary() {
  const { invoice, payees } = useLoaderData() as InvoiceDetailsData;

  return (
    <section className="bg-white rounded p-6 shadow-sm text-sm">
      <div className="grid grid-cols-3 gap-4">
        <div className="space-y-1">
          <p className="font-bold">Paid to</p>
          <Suspense
            fallback={
              <p>
                <em>Loading payee data...</em>
              </p>
            }
          >
            <Await resolve={payees}>
              <PayeeInformation />
            </Await>
          </Suspense>
          {invoice.status !== InvoiceStatus.Void && (
            <SecondaryLink to="payee" size="xs">
              <PencilSquareIcon className="inline-block w-3 h-3 mr-1.5" />
              <span>Change</span>
            </SecondaryLink>
          )}
        </div>

        <div className="space-y-1">
          <p className="font-bold">Condition</p>
          <InvoiceCondition />
        </div>

        <div className="space-y-1">
          <p className="font-bold">Payment term</p>
          <div className="flex flex-col space-y-2 items-start">
            <SummaryPill>{invoice.paymentTerm} days</SummaryPill>
          </div>
          {invoice.status !== InvoiceStatus.Void && (
            <SecondaryLink to="payment-term" size="xs">
              <PencilSquareIcon className="inline-block w-3 h-3 mr-1.5" />
              <span>Change</span>
            </SecondaryLink>
          )}
        </div>
      </div>
    </section>
  );
}

function SummaryPill({ children }: { children: React.ReactNode }) {
  return (
    <span className="inline-block bg-blue-50 text-blue-700 py-1 px-2 rounded font-bold text-xs uppercase">
      {children}
    </span>
  );
}

function InvoiceCondition() {
  const { invoice, orderItems } = useLoaderData() as InvoiceDetailsData;

  if (invoice.condition === "sending_date") {
    return (
      <>
        <p>Sending date</p>
        <p>
          {invoice.date ? (
            <Date date={invoice.date} display={DateDisplayType.DATE} />
          ) : (
            <em>Not set</em>
          )}
        </p>
      </>
    );
  } else if (invoice.condition === "order_item") {
    const producedOrderItems = orderItems.filter(
      (item) => item.status === "active" || item.status === "completed",
    );
    return (
      <>
        <p>Order production</p>
        <p className="text-xs">
          The invoice will be ready to be sent once all invoiced order items have been produced.
        </p>
        <p>
          {producedOrderItems.length} / {invoice.items.length} items produced
        </p>
        <InvoiceConditionFooter />
      </>
    );
  } else {
    return (
      <>
        <p>Custom</p>
        <p>{invoice.description}</p>
        <InvoiceConditionFooter />
      </>
    );
  }
}

function InvoiceConditionFooter() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;

  return (
    <>
      <p className="font-bold">Latest sending date</p>
      <p>
        {invoice.latestDate !== undefined ? (
          <Date date={invoice.latestDate} display={DateDisplayType.DATE} />
        ) : (
          "No latest sending date set"
        )}
      </p>
      {invoice.status !== InvoiceStatus.Void && (
        <SecondaryLink to="latest-date" size="xs">
          <PencilSquareIcon className="inline-block w-3 h-3 mr-1.5" />
          <span>Change</span>
        </SecondaryLink>
      )}
    </>
  );
}

function PayeeInformation() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const payees = useAsyncValue() as Payee[];
  const payee = payees.find((payee) => payee.id === invoice.payeeId);

  return (
    <>
      <p>{payee?.name}</p>
      <p>{payee?.address.country}</p>
    </>
  );
}
