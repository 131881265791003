import { EntityBuilder, PartialPropsJson, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { Job } from "@/entities/job";
import { Form } from "@/entities/form";

export async function fetchJob(id: number): Promise<Job> {
  const response = await api.get(`v5/jobs/${id}`).json<{ data: PropsJson<Job> }>();

  return EntityBuilder.buildOne(Job, response.data);
}

export async function createJob(data: PartialPropsJson<Job>): Promise<Job> {
  const response = await api.post(`v5/jobs`, { json: data }).json<{ data: PropsJson<Job> }>();

  return EntityBuilder.buildOne(Job, response.data);
}

export async function createForm(data: PartialPropsJson<Form>) {
  const response = await api.post(`v2/forms`, { json: data }).json<{ data: PropsJson<Form> }>();

  return EntityBuilder.buildOne(Form, response.data);
}
