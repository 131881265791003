import { PropsWithChildren, TdHTMLAttributes } from "react";
import clsx from "clsx";

export type TableColumnArgs = PropsWithChildren<
  Omit<TdHTMLAttributes<HTMLTableCellElement>, "className"> & {
    colSpan?: number;
    minimal?: boolean;
    numeric?: boolean;
    rightAligned?: boolean;
  }
>;

export function TableColumn({
  children,
  minimal = false,
  numeric = false,
  rightAligned = false,
  ...props
}: TableColumnArgs) {
  const classes = clsx(
    "px-4 py-3",
    minimal ? "w-1" : "",
    rightAligned ? "text-right" : "",
    numeric ? "tabular-nums slashed-zero whitespace-nowrap" : "",
  );

  return (
    <td className={classes} {...props}>
      {children}
    </td>
  );
}
