import {
  Await,
  defer,
  LoaderFunctionArgs,
  Outlet,
  ShouldRevalidateFunction,
  useLoaderData,
} from "react-router-dom";
import { Suspense } from "react";
import { fetchOrder } from "@/api/order";
import { OrderHubspotLink } from "@/routes/orders.$order/hubspot";
import { OrderSummary } from "@/routes/orders.$order/summary";
import { OrderItems } from "@/routes/orders.$order/items";
import { OrderInvoices } from "@/routes/orders.$order/invoices";
import SecondaryLink from "@/components/secondary-link";
import { OrderSellers } from "@/routes/orders.$order/sellers";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { OrderCancellation } from "@/routes/orders.$order/order-cancellation";
import { Order } from "@/entities/order";
import { User } from "@/entities/user";
import { fetchPayee } from "@/api/payee";
import { Payee } from "@/entities/payee";
import { fetchUserPermissionFor } from "@/api/permissions";
import {
  preventRevalidationForFailedSubmissions,
  preventRevalidationForModalNavigation,
} from "@/helpers/revalidation-fns";
import * as Page from "@/components/page";
import { assertParameterExists } from "@/helpers/loader-guards";

// Exporting loader's types so that it can be referenced in subcomponents.
export type OrderDetailsData = {
  order: Order;
  jobillaUsers?: Promise<User[]>;
  payee?: Promise<Payee>;
  canUserModify: Promise<boolean>;
  canUserDelete: Promise<boolean>;
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.order);

  const order = await fetchOrder(params.order);

  return defer({
    order,
    payee: fetchPayee(order.payeeId),
    jobillaUsers: fetchJobillaUsers(),
    canUserModify: fetchUserPermissionFor("update", "order", params.order),
    canUserDelete: fetchUserPermissionFor("delete", "order", params.order),
  });
}

export const shouldRevalidate: ShouldRevalidateFunction = (params) => {
  // Don't reload data if the user has just canceled an order. Because otherwise the page will
  // error out with 404, but we want to keep showing the page as is until user takes action.
  if (params.formAction?.search(/orders\/[a-f0-9-]+\/cancel/) !== -1) {
    return false;
  }

  return (
    preventRevalidationForFailedSubmissions(params) ??
    preventRevalidationForModalNavigation(params) ??
    params.defaultShouldRevalidate
  );
};

export default function OrderDetailsPage() {
  const { order, canUserDelete, canUserModify } = useLoaderData() as OrderDetailsData;

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Order #{order.numericId}</Page.Title>
      </Page.Heading>

      <Page.Main>
        <div>
          <p className="text-2xl font-medium">Summary</p>

          <div className="mt-10 flex flex-col gap-4">
            <OrderHubspotLink />
            <OrderSummary />
          </div>
        </div>

        <section>
          <p className="text-2xl font-medium">Order notes</p>
          <p className="mt-6 whitespace-pre-line bg-yellow-100 p-6 shadow-sm rounded-md">
            {order.notes ?? <em>The order does not have any notes.</em>}
          </p>
        </section>

        <section>
          <p className="text-2xl font-medium">Items</p>

          <div className="mt-10">
            <OrderItems />
          </div>
        </section>

        <section>
          <p className="text-2xl font-medium">Invoices</p>

          <div className="mt-10">
            <OrderInvoices />
          </div>
        </section>

        {!order.isTransferOrder && (
          <>
            <section>
              <div className="flex justify-between items-center">
                <p className="text-2xl font-medium">Sales split</p>
                <div>
                  <Suspense fallback={null}>
                    <Await resolve={canUserModify}>
                      {(hasPermission) =>
                        hasPermission && (
                          <SecondaryLink to={`/orders/${order.id}/sales-split`} preventScrollReset>
                            Change sellers
                          </SecondaryLink>
                        )
                      }
                    </Await>
                  </Suspense>
                </div>
              </div>

              <div className="mt-10">
                <OrderSellers />
              </div>
            </section>
            <Suspense fallback={null}>
              <Await resolve={canUserDelete}>
                {(hasPermission) => hasPermission && <OrderCancellation />}
              </Await>
            </Suspense>
          </>
        )}
      </Page.Main>

      <Outlet />
    </Page.Root>
  );
}
