export const teamsAndRoles = [
  {
    id: "sales",
    name: "Sales",
    roles: [
      {
        id: "sales.director",
        name: "Sales directors",
        userTitle: "Sales director",
      },
      {
        id: "sales.account_manager",
        name: "Account managers",
        userTitle: "Account manager",
      },
      {
        id: "sales.specialist",
        name: "Inside sales specialists",
        userTitle: "Inside sales specialist",
      },
    ],
  },
  {
    id: "customer_success",
    name: "Customer success",
    roles: [
      {
        id: "customer_success.manager",
        name: "Customer success managers",
        userTitle: "Customer success manager",
      },
      {
        id: "customer_success.specialist",
        name: "Customer success specialists",
        userTitle: "Customer success specialist",
      },
    ],
  },
  {
    id: "service_production",
    name: "Service production",
    roles: [
      {
        id: "service_production.manager",
        name: "Production managers",
        userTitle: "Production manager",
      },
      {
        id: "service_production.senior_specialist",
        name: "Senior production specialists",
        userTitle: "Senior production specialist",
      },
      {
        id: "service_production.specialist",
        name: "Production specialists",
        userTitle: "Production specialist",
      },
    ],
  },
  {
    id: "finance",
    name: "Finance",
    roles: [
      {
        id: "finance.controller",
        name: "Controllers",
        userTitle: "Controller",
      },
      {
        id: "finance.specialist",
        name: "Finance specialists",
        userTitle: "Finance specialist",
      },
    ],
  },
  {
    id: "product",
    name: "Product",
    roles: [
      {
        id: "product.technical_support_specialist",
        name: "Technical support specialists",
        userTitle: "Technical support specialist",
      },
      {
        id: "product.software_engineer",
        name: "Software developers",
        userTitle: "Software developer",
      },
      {
        id: "product.developer",
        name: "Developers",
        userTitle: "Developer",
      },
    ],
  },
  {
    id: "administration",
    name: "Administration",
    roles: [
      {
        id: "administration.director",
        name: "Directors",
        userTitle: "Director",
      },
      {
        id: "administration.partner_manager",
        name: "Partner managers",
        userTitle: "Partner manager",
      },
    ],
  },
  {
    id: "content_production",
    name: "Content production",
    roles: [
      {
        id: "content_production.web_designer",
        name: "Web designers",
        userTitle: "Web designer",
      },
      {
        id: "content_production.specialist",
        name: "Content production specialists",
        userTitle: "Content production specialist",
      },
    ],
  },
  {
    id: null,
    name: "Not defined",
    roles: [
      {
        id: null,
        name: "Not defined",
        userTitle: null,
      },
    ],
  },
] as const satisfies TeamsAndRoles;

export type TeamsAndRoles = ReadonlyArray<Team>;

export type Team = NullTeam | NonNullTeam;

export type NullTeam = {
  id: null;
  name: string;
  roles: ReadonlyArray<NullRole>;
};

export type NonNullTeam = {
  id: string;
  name: string;
  roles: ReadonlyArray<NonNullRole>;
};

export type Role = NullRole | NonNullRole;

export type NullRole = {
  id: null;
  name: string;
  userTitle: null;
};

export type NonNullRole = {
  id: string;
  name: string;
  userTitle: string;
};
