import { ReactNode } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

export type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
  tooltipProps?: RadixTooltip.TooltipProps;
  tooltipContentProps?: RadixTooltip.TooltipContentProps;
};

export function Tooltip({ content, children, tooltipProps, tooltipContentProps }: TooltipProps) {
  return (
    <RadixTooltip.Root {...tooltipProps}>
      <RadixTooltip.Trigger asChild>
        <span>{children}</span>
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          {...tooltipContentProps}
          className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
        >
          {content}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}
