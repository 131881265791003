import {
  isRouteErrorResponse,
  LoaderFunctionArgs,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
} from "react-router-dom";
import { fetchCompanies } from "@/api/company";
import CompanyListTable from "@/routes/companies/company-list-table";
import CompaniesIndexFilters from "@/routes/companies/companies-index-filters";
import * as Page from "@/components/page";

export async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  const companies = await fetchCompanies({
    page: Number(searchParams.get("page") ?? 1),
    name: searchParams.get("search") ?? "",
  });

  return {
    companies,
  };
}

export default function CompanyIndexPage() {
  const location = useLocation();
  const transition = useNavigation();
  const { companies } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const isLoading =
    transition.state !== "idle" && transition.location?.pathname === location.pathname;

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Companies</Page.Title>
      </Page.Heading>

      <Page.Content>
        <CompaniesIndexFilters />
        <CompanyListTable isLoading={isLoading} companies={companies} />
      </Page.Content>
    </Page.Root>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <div>You have found something that does not exist</div>;
  }

  return <div>WHOOPS!</div>;
}
