import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { useCallback } from "react";
import { HTTPError } from "ky";
import invariant from "tiny-invariant";
import { Dialog } from "@/components/dialog";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";
import SecondaryButton from "@/components/secondary-button";
import { DangerButton } from "@/components/danger-button";
import { revokeCampaignRequest } from "@/api/campaign";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);

  const input = Object.fromEntries((await request.formData()).entries()) as {
    campaign_context_id?: string;
  };

  invariant(input.campaign_context_id);

  try {
    await revokeCampaignRequest(input.campaign_context_id);
    return redirect(`/order-items/${params.orderItem}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: e.response.status,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
      errors: { name: ["Unexpected error occurred while saving the notes."] },
    };
  }
}

export default function OrderItemCampaignRevokeDialog() {
  const { orderItem } = useRouteLoaderData("order-item-details") as OrderItemDetailsData;

  const navigate = useNavigate();

  const close = useCallback(() => {
    navigate(`/order-items/${orderItem.id}`, {
      preventScrollReset: true,
    });
  }, []);

  return (
    <Dialog isOpen onClose={close} title="Revoke campaign request?">
      <Form method="post">
        {orderItem.campaignContext ? (
          <>
            <input type="hidden" name="campaign_context_id" value={orderItem.campaignContext.id} />

            <p className="text-sm text-slate-700">
              Are you sure you want to revoke campaign request and remove it from the order
              pipeline? Company will get the campaign back to their inventory, but no information
              about the campaign request will be saved.
            </p>
          </>
        ) : (
          <p className="text-sm text-slate-700">There is no campaign request to revoke.</p>
        )}

        <div className="mt-8 flex items-center justify-center space-x-4">
          {orderItem.campaignContext && (
            <DangerButton type="submit" size="lg">
              Revoke
            </DangerButton>
          )}

          <SecondaryButton type="button" size="lg" onClick={close}>
            Close
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
