import type { ReactNode } from "react";

export type SidebarBoxArgs = {
  title: string;
  children: ReactNode;
  action?: ReactNode;
};

export default function SidebarBox({ title, children, action }: SidebarBoxArgs) {
  return (
    <div className="bg-white rounded p-6 shadow-sm">
      <div className="flex items-center justify-between space-x-2">
        <p className="font-medium text-lg">{title}</p>
        {action}
      </div>

      <div className="mt-4 text-sm font-medium">{children}</div>
    </div>
  );
}
