import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

type SecondaryLinkProps = LinkProps & {
  size?: "xs" | "sm" | "sm-square" | "md" | "lg" | "xl";
  disabled?: boolean;
};

export default function SecondaryLink({
  children,
  disabled,
  size = "md",
  ...props
}: SecondaryLinkProps) {
  const sizeBasedStyles: Record<Exclude<SecondaryLinkProps["size"], undefined>, string> = {
    xs: "py-1 px-2 text-xs",
    sm: "py-1.5 px-3 text-sm",
    "sm-square": "py-1.5 px-1.5 text-sm",
    md: "py-2 px-4 text-sm",
    lg: "py-3 px-6 text-sm",
    xl: "py-4 px-8",
  };

  const baseClasses = [
    "inline-flex items-center justify-center",
    "bg-purple-200 text-violet-900 font-medium rounded-lg leading-none tracking-wide",
    sizeBasedStyles[size],
    "transition duration-200",
  ];

  if (disabled) {
    return <span className={clsx(baseClasses, "opacity-40 cursor-not-allowed")}>{children}</span>;
  }

  return (
    <Link
      {...props}
      className={clsx(
        baseClasses,
        "hover:bg-gradient-to-t hover:from-purple-200 hover:to-blue-100",
      )}
    >
      {children}
    </Link>
  );
}
