import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { Billing } from "@/entities/billing";
import { api } from "@/api/base";

export async function fetchCompanyBillings(companyId: number): Promise<Billing[]> {
  const response = await api.get(`v1/companies/${companyId}/billing`);

  // TODO: should make this more explanatory for user
  if (response.status >= 300) {
    console.error("failed request", response.status, await response.text());

    throw new Response("Something went wrong.", {
      status: response.status,
    });
  }

  const json = await response.json<{ data: PartialPropsJson<Billing>[] }>();

  return EntityBuilder.buildMany(Billing, json.data);
}

export async function addCompanyBilling(billing: Billing): Promise<Billing> {
  const response = await api.post("v1/companies/billing", {
    json: billing.toJson(),
  });

  if (response.status >= 300) {
    throw response;
  }

  const json = await response.json<{ data: PartialPropsJson<Billing> }>();
  return EntityBuilder.buildOne(Billing, json.data);
}

export async function updateCompanyBilling(billing: Billing): Promise<Billing> {
  const response = await api.put(`v1/companies/billing/${billing.id}`, {
    json: billing.toJson(),
  });

  if (response.status >= 300) {
    throw response;
  }

  const json = await response.json<{ data: PartialPropsJson<Billing> }>();
  return EntityBuilder.buildOne(Billing, json.data);
}

export async function deleteCompanyBilling(id: string): Promise<void> {
  const response = await api.delete(`v1/companies/billing/${id}`);

  if (response.status >= 300) {
    throw response;
  }
}
