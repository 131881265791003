import { Suspense } from "react";
import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { Billing } from "@/entities/billing";
import { BillingDetailsRequest } from "@/entities/billing-details-request";
import { InvoiceStatus } from "@/entities/invoice-status";
import Date, { DateDisplayType } from "@/components/date";
import { PrimaryLink } from "@/components/primary-link";
import SecondaryLink from "@/components/secondary-link";
import { InvoiceDetailsData } from "./loader";

export function InvoiceBillingDetails() {
  const { invoice, billingDetails, billingDetailsRequest } = useLoaderData() as InvoiceDetailsData;

  return (
    <>
      <p className="font-bold">Billing details</p>
      {invoice.customer ? (
        <>
          <div className="text-xs">
            <strong>{invoice.customer.name}</strong>
            <p>{invoice.customer.email}</p>
            <p>{invoice.customer.vat}</p>
            {invoice.customer.reference ? (
              <p>{invoice.customer.reference}</p>
            ) : (
              <p className="italic">No reference provided</p>
            )}
          </div>
          <div className="text-xs">
            <p>{invoice.customer.address.line1}</p>
            {invoice.customer.address.line2 ? <p>{invoice.customer.address.line2}</p> : null}
            <p>
              {invoice.customer.address.postalCode} {invoice.customer.address.city}
            </p>
            <p className="capitalize">{invoice.customer.address.country}</p>
            {invoice.customer.finvoiceOperator ? <p>{invoice.customer.finvoiceOperator}</p> : null}
            {invoice.customer.finvoiceAddress ? <p>{invoice.customer.finvoiceAddress}</p> : null}
          </div>
        </>
      ) : (
        <>
          <Suspense fallback={null}>
            <Await resolve={billingDetails}>
              <NoBillingDetails />
            </Await>
          </Suspense>
        </>
      )}
      <>
        {invoice.status !== InvoiceStatus.Void && (
          <Suspense fallback={null}>
            <Await resolve={billingDetails}>
              <FooterWithButtons />
            </Await>
          </Suspense>
        )}
        <Suspense fallback={null}>
          <Await
            resolve={billingDetailsRequest}
            errorElement={
              <div className="text-xs">
                <p>No request sent</p>
              </div>
            }
          >
            <ActiveBDR />
          </Await>
        </Suspense>
      </>
    </>
  );
}

function NoBillingDetails() {
  const billingDetails = useAsyncValue() as Billing[];

  return (
    <>
      {billingDetails.length > 0 ? (
        <div className="text-xs">
          <p>No billing details are assigned, but previously used billing details are available.</p>
        </div>
      ) : (
        <div className="text-xs">
          <p>No billing details available right now.</p>
        </div>
      )}
    </>
  );
}

function FooterWithButtons() {
  const billing = useAsyncValue() as Billing[];

  return (
    <div className="flex flex-col gap-3">
      <SecondaryLink to="select-billing" size="lg">
        Select billing details
      </SecondaryLink>

      <PrimaryLink to="request-billing" size="lg">
        {billing.length > 0 ? "Request new details" : "Request details"}
      </PrimaryLink>
    </div>
  );
}

function ActiveBDR() {
  const activeBDR = useAsyncValue() as BillingDetailsRequest;

  return (
    <div className="text-xs">
      <p>
        Billing details requested from {activeBDR.sourceId} on{" "}
        <Date date={activeBDR.createdAt} display={DateDisplayType.DATE} />
      </p>
    </div>
  );
}
