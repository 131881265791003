import {
  ActionFunctionArgs,
  Form,
  redirect,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { useCallback } from "react";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import SecondaryButton from "@/components/secondary-button";
import TextField from "@/components/forms/text-field";
import { fetchHubspotCompany } from "@/api/company-hubspot";
import { processYupErrors } from "@/helpers/yup-errors";
import { useActiveActionData } from "@/hooks/useActiveActionData";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterIsNumeric(params.company);

  let input;

  try {
    input = await Yup.object()
      .shape({
        hubspot_company_id: Yup.string()
          .required()
          .matches(/^\d+$|^https:\/\/app-eu1\.hubspot\.com\/contacts\/\d+\/record\/\d-\d\/\d+$/, {
            message: "Invalid HubSpot company ID.",
          })
          .transform((value) => value.replace(/.*\/record\/\d-\d\/(\d+)$/, "$1")),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    const hubspotCompany = await fetchHubspotCompany(input.hubspot_company_id);

    if (hubspotCompany.isAssociated) {
      return {
        status: 422,
        errors: {
          hubspot_company_id: ["This company is already associated with another company."],
        },
      };
    }

    return redirect(`/companies/${params.company}/hubspot-link/${hubspotCompany.id}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 404) {
      return {
        status: 404,
      };
    }

    return { status: 500 };
  }
}

export default function HubspotDialog() {
  const params = useParams();
  const location = useLocation();
  const transition = useNavigation();
  const navigate = useNavigate();
  const actionResult = useActiveActionData() as Exclude<
    Awaited<ReturnType<typeof action>>,
    Response
  >;
  const errors = (actionResult?.errors as Record<string, string[]>) ?? {};
  const isNotFoundError = actionResult?.status === 404;

  if (isNotFoundError) {
    errors.hubspot_company_id = ["This does not match any company in HubSpot."];
  }

  const hasFoundHubspotCompany =
    transition.location?.pathname && transition.location.pathname !== location.pathname;
  const close = useCallback(() => navigate(`/companies/${params.company}`), []);

  return (
    <Dialog isOpen onClose={close} title="Link to Hubspot">
      <Form method="post" noValidate>
        <TextField
          required
          name="hubspot_company_id"
          label="Hubspot Company ID/URL"
          errors={errors?.hubspot_company_id}
          description={
            <>
              You can find a company&apos;s ID by going to the company page in Hubspot and finding
              the number in the URL right after{" "}
              <code className="bg-stone-100 rounded py-0.5 px-1">/company/</code> or use the company
              page&apos;s URL directly.
            </>
          }
        />

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton
            size="lg"
            label="Check ID"
            labelWhenSubmitting="Checking..."
            isSubmitting={hasFoundHubspotCompany || transition.state === "submitting"}
          />

          <SecondaryButton type="button" size="lg" onClick={close}>
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
