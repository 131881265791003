import {
  Form,
  json,
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import * as Page from "@/components/page";
import { fetchOrderItem } from "@/api/order-item";
import { OrderItem } from "@/entities/order-item";
import { useActionErrors } from "@/hooks/useActionErrors";
import TextField from "@/components/forms/text-field";
import SelectBox from "@/components/forms/select-box";
import { languages } from "@/helpers/public-languages";
import { fetchOrder } from "@/api/order";
import { Order } from "@/entities/order";
import { User } from "@/entities/user";
import { fetchCompanyUsers } from "@/api/company";
import SubmitButton from "@/components/submit-button";
import { Company } from "@/entities/company";
import { assertParameterExists } from "@/helpers/loader-guards";
import { useUnitDefaults } from "@/hooks/useUnitDefaults";
import { AssigneeField } from "./assignee-field";

export { action } from "./action";
export { ErrorBoundary } from "./error-boundary";

export type OrderItemRecruitmentWizardLoaderData = {
  company: Company;
  companyUsers: User[];
  orderItem: OrderItem;
  order: Order;
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);

  if (!orderItem.product?.isDigitalHeadhunting) {
    throw json(
      {
        message: "This is not a digital headhunting order item.",
        description: "You can create a recruitment only for a digital headhunting order item.",
      },
      { status: 422 },
    );
  }

  const order = orderItem.order ?? (await fetchOrder(orderItem.orderId));

  // We know that `company` will be present here in this specific API response.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const company = order.company!;

  return {
    company,
    companyUsers: await fetchCompanyUsers(company.id),
    orderItem,
    order,
  } satisfies OrderItemRecruitmentWizardLoaderData;
}

export default function OrderItemRecruitmentWizard() {
  const { orderItem, company } = useLoaderData() as OrderItemRecruitmentWizardLoaderData;
  const unitDefaults = useUnitDefaults(company.units?.[0]);
  const transition = useNavigation();
  const errors = useActionErrors();

  return (
    <Page.Root>
      <Page.Heading
        backLink={
          <Page.GoBackLink to={`/order-items/${orderItem.id}`}>
            Go back to order item
          </Page.GoBackLink>
        }
      >
        <Page.Title>Draft a recruitment</Page.Title>
        <Page.Description>
          Setting up <b>{orderItem.campaignContext?.jobName}</b> for{" "}
          <Link to={`/companies/${company.id}`}>
            <b>{company.name}</b>
          </Link>
        </Page.Description>
      </Page.Heading>

      <Page.Main>
        <Form method="post" noValidate className="max-w-4xl">
          <input type="hidden" name="company_id" value={company.id} />

          <fieldset disabled={transition.state !== "idle"} className="flex flex-col gap-8">
            <SelectBox
              name="language"
              label="Recruitment language"
              errors={errors?.hubspot_deal_id}
              description="This will determine the language of the whole form page."
              defaultValue={unitDefaults.locale}
            >
              <option value="" disabled>
                Select a language
              </option>

              {languages.map((language) => (
                <option key={language.key} value={language.key}>
                  {language.name}
                </option>
              ))}
            </SelectBox>

            <TextField
              type="text"
              name="title"
              label="Job name"
              required
              defaultValue={orderItem.campaignContext?.jobName}
            />

            <div className="flex items-center gap-8">
              <div className="w-1/2">
                <TextField
                  type="text"
                  name="location"
                  label="Location"
                  defaultValue={orderItem.campaignContext?.jobLocation ?? ""}
                />
              </div>
            </div>

            <AssigneeField />

            <div>
              <SubmitButton
                label="Create recruitment"
                labelWhenSubmitting="Creating..."
                size="lg"
              />
            </div>

            {errors && (
              <p className="text-red-600 font-medium text-sm">
                There are some errors in the form. Please fix them before submitting again.
              </p>
            )}
          </fieldset>
        </Form>
      </Page.Main>
    </Page.Root>
  );
}
