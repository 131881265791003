import * as React from "react";

type TertiaryButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: "sm" | "md" | "lg" | "xl";
};

export default function TertiaryButton({ children, size = "md", ...props }: TertiaryButtonProps) {
  const padding = {
    sm: "p-1.5",
    md: "p-2",
    lg: "p-3",
    xl: "p-4",
  };

  return (
    <button
      {...props}
      className={[
        "inline-block",
        padding[size],
        "bg-zinc-200 text-zinc-700 text-sm font-medium rounded-lg leading-none",
        "transition duration-200",
        "disabled:opacity-40 disabled:cursor-not-allowed",
        "enabled:hover:bg-zinc-300",
        props.className ?? "",
      ].join(" ")}
    >
      {children}
    </button>
  );
}
