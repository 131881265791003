import { useLoaderData } from "react-router-dom";
import { useContext, useMemo } from "react";
import { OrdersNewPageLoaderData } from "@/routes/orders.create/route";
import SelectBox from "@/components/forms/select-box";
import { LocaleContext } from "@/helpers/locale-context";
import { Locale } from "@/helpers/language-and-locale";

export function Payees() {
  const { payees } = useLoaderData() as OrdersNewPageLoaderData;
  const { locale } = useContext(LocaleContext);

  const defaultPayee = useMemo(() => {
    return (
      payees.find(
        (payee) =>
          payee.name.toLowerCase().trim() ===
          (locale === Locale.De_DE ? "jobilla dach gmbh" : "jobilla oy"),
      )?.id ?? payees[0].id
    );
  }, []);

  return (
    <SelectBox label="Payee" name="payee_id" defaultValue={defaultPayee}>
      {payees.map((payee) => (
        <option key={payee.id} value={payee.id}>
          {payee.name}
        </option>
      ))}
    </SelectBox>
  );
}
