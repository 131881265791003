import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { capitalize, isNil, uniqBy } from "lodash";
import { differenceInHours } from "date-fns";
import clsx from "clsx";
import * as Sentry from "@sentry/react";
import { Paginated } from "@/api/base";
import { OrderItem } from "@/entities/order-item";
import { OrderPipelineData } from "@/routes/order-pipeline.$product/route";
import { listOrderItems } from "@/api/order-item";
import * as DateComponent from "@/components/date";
import usePageBottom from "@/hooks/usePageBottom";
import { MoneyDisplay } from "@/components/money-display";
import { useRootLoaderData } from "@/root";

type OrderPipelinesSwimlanesProps = {
  isLoading: boolean;
  orderItems: OrderPipelineData;
};

export default function OrderPipelineSwimlanes({
  isLoading,
  orderItems,
}: OrderPipelinesSwimlanesProps) {
  const [searchParams] = useSearchParams();
  const [isLoadingOrderItems, setIsLoadingOrderItems] = useState(false);
  const handleLoadingOrderItems = (state: boolean) => {
    setIsLoadingOrderItems(state);
  };

  const isLoadCompletedOrderItems =
    searchParams.get("hide_completed") === "false" || isNil(searchParams.get("hide_completed"));

  return (
    <>
      <div className="flex flex-row space-x-6">
        <Swimlane
          orderItems={orderItems.newOrderItems}
          status="New"
          handleLoadingOrderItems={handleLoadingOrderItems}
        />
        <Swimlane
          orderItems={orderItems.setupOrderItems}
          status="Setup"
          handleLoadingOrderItems={handleLoadingOrderItems}
        />
        <Swimlane
          orderItems={orderItems.activeOrderItems}
          status="Active"
          handleLoadingOrderItems={handleLoadingOrderItems}
        />
        {isLoadCompletedOrderItems && orderItems.completedOrderItems && (
          <Swimlane
            orderItems={orderItems.completedOrderItems}
            status="Completed"
            handleLoadingOrderItems={handleLoadingOrderItems}
          />
        )}
      </div>
      {(isLoading || isLoadingOrderItems) && (
        <div className="inset-0 bg-white bg-opacity-40 text-center py-12">
          <p className="font-bold text-2xl">Loading...</p>
        </div>
      )}
    </>
  );
}

function Swimlane({
  orderItems,
  status,
  handleLoadingOrderItems,
}: {
  orderItems: Paginated<OrderItem[]>;
  status: string;
  handleLoadingOrderItems: (state: boolean) => void;
}) {
  const { products } = useRootLoaderData();

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasScrollLoadError, setHasScrollLoadError] = useState(false);

  const { product: productParameter } = useParams();
  const product = products.find((product) => product.id === productParameter);

  const [orderItemList, setOrderItemList] = useState<Paginated<OrderItem[]>>(orderItems);

  const loadNextOrderItems = async () => {
    handleLoadingOrderItems(true);
    setIsLoading(true);

    const nextPage = orderItemList.meta?.current_page + 1;
    try {
      const orderItems = await listOrderItems({
        per_page: 25,
        current_page: nextPage,
        product: productParameter,
        unit_id: searchParams.get("unit_id") ?? undefined,
        company_id: searchParams.get("company_id") ?? undefined,
        statuses: [status.toLowerCase()],
        order_by: product?.isDigitalHeadhunting ? "campaign_created_at" : "created_at",
        order: "asc",
      });
      orderItems.data = uniqBy([...orderItemList.data, ...orderItems.data], "id");
      setOrderItemList(orderItems);
      setHasScrollLoadError(false);
    } catch (e) {
      Sentry.captureException(e);
      setHasScrollLoadError(true);
    }
    setIsLoading(false);
    handleLoadingOrderItems(false);
  };

  const hasNextPage = orderItemList.meta?.current_page < orderItemList.meta?.last_page;

  usePageBottom(() => {
    if (hasNextPage && !isLoading) {
      loadNextOrderItems();
    }
  }, 4000);

  useEffect(() => {
    setOrderItemList(orderItems);
  }, [orderItems]);

  return (
    <div className="flex flex-col mb-4 pt-4 bg-gray-100 w-1/3">
      <div className="flex justify-between">
        <span className="px-8 font-bold text-gray-500">{status}</span>
        <span className="px-8 font-bold text-gray-500">{orderItems.meta.total}</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        {orderItemList.data.map((orderItem) => (
          <OrderItemTile orderItem={orderItem} key={orderItem.id} />
        ))}
      </div>
      {hasScrollLoadError && (
        <div className="flex rounded bg-red-300 justify-center items-center">
          <span className="px-8 font-bold">Error loading more order items</span>
        </div>
      )}
    </div>
  );
}

function OrderItemTile({ orderItem }: { orderItem: OrderItem }) {
  const [searchParams] = useSearchParams();

  const goToOrder = orderItem.product?.isDigitalHeadhunting
      ? `/order-items/${orderItem.id}`
      : `details/${orderItem.id}?${searchParams}`;

  const isOld =
    Math.abs(differenceInHours(new Date(), new Date(orderItem.campaignContext?.createdAt ?? ""))) >=
    48;

  return (
    <Link
      key={orderItem.id}
      className="flex flex-col pl-6 rounded-md bg-white p-4 my-4 w-4/5 shadow-md"
      to={goToOrder}
    >
      <span className="font-bold">{orderItem.order?.company?.name}</span>
      <span>{orderItem.campaignContext?.jobName}</span>
      <span className="text-gray-500">{capitalize(orderItem.product?.title)}</span>
      <span className="text-gray-500">
        <MoneyDisplay amount={orderItem.amount} />
      </span>
      <span className="text-gray-500">
        Requested{" "}
        <span
          className={clsx(
            isOld && orderItem.status === "new" && "text-red-500",
            !isOld && orderItem.status === "new" && "text-green-500",
          )}
        >
          {orderItem.campaignContext?.createdAt && (
            <DateComponent.default
              date={orderItem.campaignContext.createdAt}
              display={DateComponent.DateDisplayType.RELATIVE}
            />
          )}
        </span>
      </span>
    </Link>
  );
}
