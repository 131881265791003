import { PartialPropsJson, PropsJson } from "@jobilla/entity";
import { CampaignContext } from "@/entities/campaign-context";
import { api } from "@/api/base";

export async function createCampaignRequest(data: PartialPropsJson<CampaignContext>) {
  return await api.post("v1/orders/items/campaign-context", { json: data }).json<CampaignContext>();
}

export async function updateCampaignRequest(
  id: string,
  data: Omit<PropsJson<CampaignContext>, "id" | "created_at">,
) {
  return await api
    .put(`v1/orders/items/campaign-context/${id}`, { json: data })
    .json<CampaignContext>();
}

export async function revokeCampaignRequest(id: string) {
  return api.delete(`v1/orders/items/campaign-context/${id}`);
}
