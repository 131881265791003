import { useLoaderData } from "react-router-dom";
import { useMemo } from "react";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { CompanyRelation, Relation } from "@/entities/CompanyRelation";
import SidebarBox from "@/routes/companies.$company/sidebar-box";
import StyledLink from "@/components/styled-link";
import type { CompanyDetailsData } from "./loader";

export default function OrganizationStructure() {
  const { organizationStructure } = useLoaderData() as CompanyDetailsData;

  return (
    <SidebarBox title={"Corporate Structure"}>
      {organizationStructure instanceof FailedOptionalResult ? (
        <ErrorState />
      ) : (
        <OrganizationStructureContents companyRelations={organizationStructure} />
      )}
    </SidebarBox>
  );
}

function OrganizationStructureContents({
  companyRelations,
}: {
  companyRelations: CompanyRelation[];
}) {
  const parentCompanies = useMemo(() => {
    return companyRelations.filter((relation) => relation.type === Relation.Parent);
  }, [companyRelations]);

  const childCompanies = useMemo(() => {
    return companyRelations.filter((relation) => relation.type === Relation.Child);
  }, [companyRelations]);

  return (
    <div className="flex flex-col gap-4">
      {parentCompanies.length === 0 && childCompanies.length === 0 && (
        <span className="text-gray-500 font-normal">No related companies right now.</span>
      )}

      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {parentCompanies.map((relation) => (
            <div className="flex flex-row gap-2" key={relation.companyId}>
              <StyledLink target="_blank" to={`/companies/${relation.companyId}`}>
                {relation.companyName}
              </StyledLink>
              <div>(Parent)</div>
            </div>
          ))}
        </div>
      </div>

      {parentCompanies.length > 0 && childCompanies.length > 0 && (
        <hr className="h-xxxxs my-xs bg-gray border-1" />
      )}

      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {childCompanies.map((relation) => (
            <div className="flex flex-row gap-2" key={relation.companyId}>
              <StyledLink target="_blank" to={`/companies/${relation.companyId}`}>
                {relation.companyName}
              </StyledLink>
              <div>(Child)</div>
            </div>
          ))}
        </div>
      </div>

      <span className="text-gray-500 font-normal">Related companies are managed via Hubspot. </span>
    </div>
  );
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="mt-2 opacity-75">Could not load corporate structure.</p>
    </div>
  );
}
