import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { useCallback } from "react";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import TextField from "@/components/forms/text-field";
import { processYupErrors } from "@/helpers/yup-errors";
import { useActiveActionData } from "@/hooks/useActiveActionData";
import { linkOrder } from "@/api/order-hubspot";
import { SpinnerIcon } from "@/components/icons/spinner";
import SecondaryButton from "@/components/secondary-button";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.order);

  let input;

  try {
    input = await Yup.object()
      .shape({
        hubspot_deal_id: Yup.string()
          .required()
          .matches(/^\d+$|(?:app\.hubspot\.com\/contacts\/\d+\/record\/0-3\/)?(\d+)\/?$/, {
            message: "Invalid HubSpot deal ID.",
          })
          .transform((value) => value.replace(/.*\/record\/0-3\/(\d+)\/?$/, "$1")),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await linkOrder(params.order, input.jom_company_id, input.hubspot_deal_id);
    return redirect(`/orders/${params.order}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors as Record<string, string[]>,
      };
    }

    return { status: 500 };
  }
}

export default function OrderHubspotLinkDialog() {
  const { order } = useRouteLoaderData("order-details") as OrderDetailsData;
  const params = useParams();
  const navigate = useNavigate();
  const actionResult = useActiveActionData() as Exclude<
    Awaited<ReturnType<typeof action>>,
    Response
  >;

  const close = useCallback(() => navigate(`/orders/${params.order}`), []);

  return (
    <Dialog isOpen onClose={close} title="Link to Hubspot">
      <Form method="post" noValidate>
        <input type="hidden" name="jom_company_id" value={order.companyId} />

        <TextField
          required
          name="hubspot_deal_id"
          label="Hubspot deal ID/URL"
          errors={
            /* The prop name is not hubspot_deal_id because that's how the backend API sends it */
            actionResult?.errors?.hubspot_id?.map((error) => error.split(":")[1])
          }
          description={
            <>
              Link this order to Hubspot so we can keep track of your sales and deals.
              <br />A Hubspot deal URL looks like this:
              <br />
              <code>app.hubspot.com/contacts/123/deal/456</code>
            </>
          }
        />

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton
            label="Link"
            size="lg"
            labelWhenSubmitting={<SpinnerIcon className="animate-spin h-4 w-4 text-white" />}
          />

          <SecondaryButton type="button" onClick={close} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
