import * as React from "react";

export function TrashCan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M21.5 2.5h-19a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h21a1 1 0 0 0 1-1v-3a2 2 0 0 0-2-2ZM21.5 10h-19a1 1 0 0 0-1 1v8.5a2 2 0 0 0 2 2h17a2 2 0 0 0 2-2V11a1 1 0 0 0-1-1Zm-6.25 3.5A1.252 1.252 0 0 1 14 14.75h-4a1.25 1.25 0 0 1 0-2.5h4a1.252 1.252 0 0 1 1.25 1.25Z"
      />
    </svg>
  );
}
