import { ActionFunctionArgs } from "react-router-dom";
import { action_accountManager } from "@/routes/companies.$company/account-manager";
import { action_businessUnit } from "@/routes/companies.$company/business-unit";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterIsNumeric(params.company);

  const inputData = Object.fromEntries(await request.formData()) as Record<string, string>;

  if (inputData._form === "account-manager-change") {
    return await action_accountManager({ request, inputData, params });
  } else if (inputData._form === "company-unit-change") {
    return await action_businessUnit({ request, inputData, params });
  }

  if (import.meta.env.DEV) {
    console.warn("Unknown form data", inputData);
  }

  return null;
}
