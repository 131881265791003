import { ActionFunctionArgs, redirect } from "react-router-dom";
import * as Yup from "yup";
import { PropsJson } from "@jobilla/entity";
import { HTTPError } from "ky";
import { CampaignContext } from "@/entities/campaign-context";
import { createCampaignRequest, updateCampaignRequest } from "@/api/campaign";
import { assertParameterExists } from "@/helpers/loader-guards";

export type CampaignRequestPageActionData =
  | undefined
  | { status: number; errors: Record<string, string[]> };

export const formSchema = Yup.object()
  .shape({
    campaign_context_id: Yup.string().nullable(),
    campaign_context_job_id: Yup.number().nullable(),
    job_name: Yup.string().required("Enter a campaign name."),
    duration: Yup.string().required().oneOf(["14", "21", "custom"]),
    custom_duration: Yup.mixed().when("duration", {
      is: "custom",
      then: Yup.number()
        .typeError("Please enter a number value.")
        .required("Enter a number for custom duration.")
        .min(1),
    }),
    booster: Yup.mixed<CampaignContext["boosterType"] | "">()
      .nullable()
      .oneOf(["", "range_booster", "duration_booster", "contact_booster"]),
    job_location: Yup.string().nullable(),
    unit_id: Yup.string().required("Select a unit."),
    notes: Yup.string().nullable(),
    contact_name: Yup.string().nullable(),
    contact_email: Yup.string().email().nullable(),
    contact_phone: Yup.string().nullable(),
    media_source: Yup.mixed<CampaignContext["mediaSource"] | null>()
      .oneOf([null, "customer_website", "photo_bank", "direct"])
      .transform((value) => (value === "" ? null : value)),
  })
  .defined();

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);

  const data = Object.fromEntries(await request.formData()) as Yup.InferType<typeof formSchema>;

  const requestData: Omit<PropsJson<CampaignContext>, "id" | "created_at"> = {
    job_id: data.campaign_context_job_id ?? null,
    job_name: data.job_name,
    // We should ideally do this conversion right before `.validate()` call
    // of Yup in a `.transform()` call, but the type definitions are not
    // updated correctly with the transform. TS still thinks the type
    // is `string` instead of `number`.
    duration: Number(data.duration === "custom" ? data.custom_duration : data.duration),
    booster_type: data.booster || null,
    job_location: data.job_location ?? null,
    unit_id: data.unit_id,
    notes: data.notes ?? null,
    media_source: data.media_source ?? null,
    order_item_id: params.orderItem,
    contact_person:
      data.contact_name && data.contact_email && data.contact_phone
        ? {
            name: data.contact_name,
            email: data.contact_email,
            phone: data.contact_phone,
          }
        : undefined,
  };

  try {
    if (data.campaign_context_id) {
      await updateCampaignRequest(data.campaign_context_id, requestData);
    } else {
      await createCampaignRequest(requestData);
    }

    return redirect(`/order-items/${params.orderItem}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors as Record<string, string[]>,
      };
    }

    return { status: 500 };
  }
}
