import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { getAuthTokenData } from "@/services/auth";
import { User } from "@/entities/user";
import { UserPermissionInfo } from "@/entities/user-permission-info";
import { Unit } from "@/entities/unit";

export async function fetchCurrentUser() {
  const tokenData = getAuthTokenData();

  const response = await api
    .get(`api/v2/users/${tokenData.user.id}`)
    .json<{ data: PropsJson<User> }>();

  return EntityBuilder.buildOne(User, response.data);
}

export async function fetchCurrentUserPermissions() {
  const response = await api.get(`api/v2/user/permissions`).json<PropsJson<UserPermissionInfo>>();

  return EntityBuilder.buildOne(UserPermissionInfo, response);
}

export async function updateUserUnit(id: User["id"], unitId: Unit["id"]) {
  return api.put(`v1/users/${id}/unit`, {
    json: {
      unit_id: unitId,
    },
  });
}

export async function updateUserRole(id: User["id"], role: User["role"]) {
  return api.put(`v1/users/${id}/role`, {
    json: {
      role,
    },
  });
}
