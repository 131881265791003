import { Entity, Type } from "@jobilla/entity";
import { OrderItem } from "@/entities/order-item";

export class BoosterToken extends Entity {
  public id!: string;
  public expiresAt!: string;
  public type!: "range_booster" | "duration_booster" | "contact_booster" | null;
  public orderItemId!: string;

  @Type(() => OrderItem)
  public orderItem!: OrderItem;
}
