import { useLoaderData } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";
import BillingsList from "@/routes/companies.$company/billing-list";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import SecondaryLink from "@/components/secondary-link";
import type { CompanyDetailsData } from "./loader";

export default function CompanyBillings() {
  const { billings } = useLoaderData() as CompanyDetailsData;

  return (
    <section>
      <div className="flex justify-between items-center">
        <p className="font-medium text-2xl">Billing Addresses</p>
        <SecondaryLink preventScrollReset to="billings/new" size="lg">
          <PlusIcon className="inline-block w-4 h-4 -mt-0.5 mr-1" />
          <span>Add</span>
        </SecondaryLink>
      </div>
      <div className="mt-4">
        {billings instanceof FailedOptionalResult ? (
          <ErrorState />
        ) : (
          <BillingsList billings={billings} />
        )}
      </div>
    </section>
  );
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load billings.</p>
    </div>
  );
}
