import {
  ActionFunctionArgs,
  Form,
  redirect,
  useActionData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { HTTPError } from "ky";
import * as Yup from "yup";
import { useCallback } from "react";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import { processYupErrors } from "@/helpers/yup-errors";
import SelectBox from "@/components/forms/select-box";
import SecondaryButton from "@/components/secondary-button";
import { updateInvoicePaymentTerm } from "@/api/invoice";
import type { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import { PaymentTerms } from "@/entities/payment-terms";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  let input;

  try {
    const requestData = Object.fromEntries((await request.formData()).entries());

    input = await Yup.object()
      .shape({
        payment_term: Yup.mixed<PaymentTerms>()
          .required()
          .transform((value) => Number(value)),
      })
      .validate(requestData, { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateInvoicePaymentTerm(params.invoice, input.payment_term);

    return redirect(`/invoices/${params.invoice}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }
}

export default function PaymentTermDialog() {
  const { invoice } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const navigate = useNavigate();
  const actionResult = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;

  const goBackToInvoiceDetails = useCallback(() => navigate(`/invoices/${invoice.id}`), []);
  const hasUnexpectedErrors = actionResult?.status === 500;

  const terms = Object.keys(PaymentTerms).filter((key) => !isNaN(Number(key)));

  return (
    <Dialog isOpen onClose={goBackToInvoiceDetails} title="Change invoice payment terms">
      <Form method="post">
        <fieldset disabled={false} className="flex flex-col space-y-1">
          <SelectBox
            name="payment_term"
            label="Invoice payment term"
            errors={actionResult?.errors?.payment_term}
            defaultValue={invoice.paymentTerm}
          >
            {Object.values(terms).map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </SelectBox>
        </fieldset>

        {hasUnexpectedErrors && (
          <ErrorBlock className="mt-6">
            Could not change the payment term of the invoice. Please try again. If the problem
            persists, contact support.
          </ErrorBlock>
        )}

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton size="lg" />

          <SecondaryButton type="button" onClick={goBackToInvoiceDetails} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
