import { ButtonHTMLAttributes, forwardRef, useState } from "react";
import clsx from "clsx";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";

export type PrimaryButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: "sm" | "md" | "lg" | "xl";
  disabledTooltipText?: string;
};

export default forwardRef<HTMLButtonElement, PrimaryButtonProps>(function PrimaryButton(
  { children, disabledTooltipText, size = "md", ...props }: PrimaryButtonProps,
  ref,
) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const padding = {
    sm: "py-1.5 px-3",
    md: "py-2 px-4",
    lg: "py-3 px-6",
    xl: "py-4 px-8",
  };

  return (
    <Tooltip.Root
      delayDuration={500}
      open={isTooltipShown && props.disabled}
      onOpenChange={setIsTooltipShown}
    >
      <Tooltip.Trigger asChild>
        <button
          ref={ref}
          {...props}
          className={clsx(
            "inline-flex items-center justify-center gap-1",
            "bg-violet-600 text-white font-medium text-sm shadow leading-none rounded-lg transition duration-200",
            padding[size],
            "whitespace-nowrap",
            "enabled:hover:bg-gradient-to-bl enabled:hover:from-violet-900 enabled:hover:to-violet-950",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            props?.className ?? "",
          )}
        >
          {children}
        </button>
      </Tooltip.Trigger>

      <AnimatePresence>
        {isTooltipShown && props.disabled && (
          <Tooltip.Portal forceMount>
            <Tooltip.Content side="bottom" asChild forceMount>
              <motion.div
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -5 }}
                className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
              >
                {disabledTooltipText}
              </motion.div>
            </Tooltip.Content>
          </Tooltip.Portal>
        )}
      </AnimatePresence>
    </Tooltip.Root>
  );
});
