import { useLocation, useParams } from "react-router-dom";
import * as Page from "@/components/page";

export function GoBackLink() {
  const location = useLocation();

  const isPreviousPageCompany = location.state?.back_to?.company;
  const isPreviousPageOrder = location.state?.back_to?.order;

  if (isPreviousPageCompany) {
    return <GoBackToCompanyLink company={isPreviousPageCompany} />;
  }

  if (isPreviousPageOrder) {
    return <GoBackToOrderLink order={isPreviousPageOrder} />;
  }

  return <GoBackToOrderItemLink />;
}

function GoBackToCompanyLink({ company }: { company: { id: string; name: string } }) {
  return (
    <Page.GoBackLink to={`/companies/${company.id}`}>
      Back to company <em>{company.name}</em>
    </Page.GoBackLink>
  );
}

function GoBackToOrderLink({ order }: { order: { id: string; numericId: string } }) {
  return (
    <Page.GoBackLink to={`/orders/${order.id}`}>
      <span>Back to order #{order.numericId}</span>
    </Page.GoBackLink>
  );
}

function GoBackToOrderItemLink() {
  const { orderItem } = useParams();

  return (
    <Page.GoBackLink to={`/order-items/${orderItem}`}>
      <span>Back to order item</span>
    </Page.GoBackLink>
  );
}
