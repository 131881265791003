import { HTTPError } from "ky";
import type { ErrorOptions } from "@/helpers/error";

export class FailedOptionalResult extends Error {
  constructor(message: string, public response: Response | null = null, options?: ErrorOptions) {
    super(message, options);
  }
}

export async function optional<T>(callback: Promise<T>): Promise<T | FailedOptionalResult> {
  try {
    return await callback;
  } catch (e) {
    if (e instanceof HTTPError) {
      return new FailedOptionalResult("", e.response, { cause: e });
    }

    if (e instanceof Response) {
      return new FailedOptionalResult("", e);
    }

    if (e instanceof Error) {
      return new FailedOptionalResult(e.message, null, { cause: e });
    }

    return new FailedOptionalResult(typeof e === "string" ? e : "");
  }
}
