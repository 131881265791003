import { api } from "@/api/base";

export async function linkOrder(jomOrderId: string, jomCompanyId: string, hubspotDealId: string) {
  return api.patch(`hubspot/v1/orders/associate`, {
    json: {
      company_id: jomCompanyId,
      order_id: jomOrderId,
      hubspot_id: hubspotDealId,
    },
  });
}
