import { capitalize } from "lodash";
import { Link, LinkProps } from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import type { InvoiceStatus } from "@/entities/invoice-status";
import type { Invoice } from "@/entities/invoice";
import { MoneyDisplay } from "@/components/money-display";

export function InvoicePill({ invoice, ...props }: { invoice: Invoice } & Omit<LinkProps, "to">) {
  const colors: Record<InvoiceStatus, string> = {
    new: "bg-orange-100 text-yellow-700 hover:bg-orange-200 hover:text-yellow-800",
    confirm_billing_details: "bg-cyan-100 text-cyan-700 hover:bg-cyan-200 hover:text-cyan-700",
    ready_to_send: "bg-cyan-100 text-cyan-700 hover:bg-cyan-200 hover:text-cyan-700",
    void: "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600 line-through",
    sent: "bg-green-100 text-green-600 hover:bg-green-200 hover:text-green-700",
    pending_information: "bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-700",
    reminder_sent: "bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-700",
    pending_deletion: clsx([
      "bg-[repeating-linear-gradient(-45deg,#fee2e2,#fee2e2_6px,#fecaca_6px,#fecaca_12px)]",
      "text-red-900 [text-shadow:1px_0_0_white,-1px_0_0_white,0_1px_0_white,0_-1px_0_white]",
    ]),
    paid: "bg-green-100 text-green-600 hover:bg-green-200 hover:text-green-700",
  };

  return (
    <div>
      <Tooltip.Root delayDuration={0} disableHoverableContent>
        <Tooltip.Trigger asChild>
          <Link
            className={`inline-block rounded-full text-xs py-0.5 px-2 font-bold
          tabular-nums slashed-zero ${colors[invoice.status]}`}
            to={`/invoices/${invoice.id}`}
            {...props}
          >
            {invoice.numericId}
          </Link>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content side="right" sideOffset={4}>
            <div className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2">
              <span>
                <MoneyDisplay amount={invoice.amount} numbersOnly /> -{" "}
                {capitalize(invoice.status).replace(/_/g, " ")}
              </span>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </div>
  );
}
