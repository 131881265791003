import { Await, useLoaderData, useSearchParams } from "react-router-dom";
import { Suspense } from "react";
import { EyeIcon } from "@heroicons/react/24/solid";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Paginated } from "@/api/base";
import { Paginator } from "@/components/paginator";
import { Order } from "@/entities/order";
import StyledLink from "@/components/styled-link";
import { User } from "@/entities/user";
import { OrderItemPill } from "@/components/order-item-pill";
import { InvoicePill } from "@/components/invoice-pill";
import { OrderIndexData } from "@/routes/orders/route";
import TertiaryLink from "@/components/tertiary-link";
import { Table, TableColumn, TableRow } from "@/components/table";
import Date, { DateDisplayType } from "@/components/date";
import { MoneyDisplay } from "@/components/money-display";

type OrdersListTableProps = {
  isLoading: boolean;
  orders: Paginated<Order[]>;
};

export default function OrderListTable({ isLoading, orders }: OrdersListTableProps) {
  const [searchParams] = useSearchParams();
  const { jobillaUsers } = useLoaderData() as OrderIndexData;

  return (
    <div className="relative">
      <Table
        columns={[
          { label: "ID" },
          { label: "Customer" },
          { label: "Sale date" },
          { label: "Sellers" },
          { label: "Items" },
          { label: "Invoices" },
          { label: "Amount" },
          { label: "" },
        ]}
      >
        <tbody>
          {orders.data.map((order) => (
            <TableRow key={order.id}>
              <TableColumn minimal>
                <StyledLink to={`/orders/${order.id}`}>#{order.numericId}</StyledLink>
              </TableColumn>

              <TableColumn>
                <span className="font-bold">{order.company?.name}</span>
              </TableColumn>

              <TableColumn>
                <span className="whitespace-nowrap">
                  {order.isTransferOrder ? (
                    <Tooltip.Root delayDuration={300}>
                      <Tooltip.Trigger asChild>
                        <span>Synthetic</span>
                      </Tooltip.Trigger>

                      <Tooltip.Portal>
                        <Tooltip.Content sideOffset={4}>
                          <div className="w-60 inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2">
                            <span>{order.notes}</span>
                          </div>
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  ) : (
                    <Date date={order.createdAt} display={DateDisplayType.DATE} />
                  )}
                </span>
              </TableColumn>

              <TableColumn>
                {order.isTransferOrder ? (
                  <span>-</span>
                ) : (
                  order.users
                    .filter((user) => user.userId !== null)
                    .map((orderUser, index) => (
                      <Suspense
                        key={`${orderUser.userId}${index}`}
                        fallback={
                          <p>
                            #{orderUser.userId} <em>(Loading user data)</em>
                          </p>
                        }
                      >
                        <Await
                          resolve={jobillaUsers}
                          errorElement={
                            <p>
                              #{orderUser.userId} <em>(Failed to load user data)</em>
                            </p>
                          }
                        >
                          {(resolvedUsers: User[]) => {
                            const user = resolvedUsers.find((u) => u.id === orderUser.userId);

                            if (!user) {
                              return (
                                <p>
                                  #{orderUser.userId} <em>(User does not exist)</em>
                                </p>
                              );
                            }
                            return <p className="whitespace-nowrap">{user.name}</p>;
                          }}
                        </Await>
                      </Suspense>
                    ))
                )}
              </TableColumn>

              <TableColumn>
                <div className="flex flex-wrap gap-1">
                  {order.items.map((item) => (
                    <OrderItemPill key={item.id} item={item} />
                  ))}
                </div>
              </TableColumn>

              <TableColumn>
                <div className="flex flex-wrap gap-1">
                  {order.invoices.map((invoice) => (
                    <InvoicePill key={invoice.id} invoice={invoice} />
                  ))}
                </div>
              </TableColumn>

              <TableColumn minimal>
                <p className="whitespace-nowrap">
                  {order.isTransferOrder ? (
                    <span>-</span>
                  ) : (
                    <MoneyDisplay amount={order.itemsTotal} />
                  )}
                </p>
              </TableColumn>

              <TableColumn minimal>
                <TertiaryLink to={`/orders/${order.id}`} size="md">
                  <span className="sr-only">Go to order details</span>
                  <EyeIcon className="w-4 h-4 text-zinc-600" />
                </TertiaryLink>
              </TableColumn>
            </TableRow>
          ))}
        </tbody>

        {orders.data.length === 0 && (
          <tfoot>
            <tr className="border-t">
              <TableColumn colSpan={8}>
                <p className="italic text-sm">There are no orders to show.</p>
              </TableColumn>
            </tr>
          </tfoot>
        )}
      </Table>

      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-40 text-center py-12">
          <p className="font-bold text-2xl">Loading...</p>
        </div>
      )}

      {!!orders.data.length && (
        <div className="mt-8">
          <Paginator
            currentPage={Number(searchParams.get("page") ?? 1)}
            lastPage={orders.meta.last_page}
            baseUrl={`/orders?${searchParams}`}
          />
        </div>
      )}
    </div>
  );
}
