import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { processYupErrors } from "@/helpers/yup-errors";
import { updateOrderItem } from "@/api/order-item";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.order);

  let input;

  try {
    input = await Yup.object()
      .shape({
        order_item_id: Yup.string().required(),
        amount: Yup.number().required().positive(),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateOrderItem(input.order_item_id, {
      amount: input.amount * 100,
    });

    return { status: 201 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return { status: 500 };
  }
}
