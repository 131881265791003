import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { HubspotCompany } from "@/entities/hubspot-company";
import { api } from "@/api/base";

export async function fetchHubspotCompany(id: string): Promise<HubspotCompany> {
  const data = await api
    .get(`hubspot/v1/companies/${id}`)
    .json<{ data: PartialPropsJson<HubspotCompany> }>();

  return EntityBuilder.buildOne(HubspotCompany, data.data);
}

export async function linkCompany(jomCompanyId: string, hubspotCompanyId: string) {
  return api.patch(`hubspot/v1/companies/associate`, {
    json: {
      company_id: jomCompanyId,
      hubspot_id: hubspotCompanyId,
    },
  });
}
