import { useMemo } from "react";
import { parseFormData } from "parse-nested-form-data";
import { isObject } from "lodash";
import { useActiveActionData } from "@/hooks/useActiveActionData";

type ValidationErrorResult = {
  status: 422;
  errors: Record<string, string[]>;
};

type NonValidationErrorResult = {
  status: number;
};

export function useActionErrors() {
  const actionResult = useActiveActionData() as ValidationErrorResult | NonValidationErrorResult;

  return useMemo(() => {
    if (!isObject(actionResult)) {
      return null;
    }

    if (!("errors" in actionResult)) {
      return null;
    }

    const formData = new FormData();

    for (const key in actionResult.errors) {
      const errors = actionResult.errors[key];
      errors?.forEach((error, index) => {
        formData.append(`${key}[${index}]`, error);
      });
    }

    // We explicitly want to use `any` here, because we don't know the type of
    // the data, and `unknown` is difficult to work with in templates.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return parseFormData(formData) as Record<string, any[]>;
  }, [actionResult]);
}
