import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { Unit } from "@/entities/unit";
import { User } from "@/entities/user";

export async function fetchAllUnits() {
  const response = await api.get(`v1/units`).json<{ data: PropsJson<Unit>[] }>();

  return EntityBuilder.buildMany(Unit, response.data);
}

export async function fetchLocationUnits() {
  const response = await api.get(`v1/units?type=location`).json<{ data: PropsJson<Unit>[] }>();

  return EntityBuilder.buildMany(Unit, response.data);
}

export async function fetchBusinessUnitUsers(id: string) {
  const response = await api.get(`v1/units/${id}/users`).json<{ data: PropsJson<User>[] }>();

  return EntityBuilder.buildMany(User, response.data);
}
