import {
  isRouteErrorResponse,
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteError,
  useRouteLoaderData,
} from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { HTTPError } from "ky";
import clsx from "clsx";
import * as Sentry from "@sentry/react";
import Error404 from "@/components/error-pages/404";
import ErrorGeneric from "@/components/error-pages/generic";
import Toolbar from "@/components/sidebar/toolbar";
import Menu from "@/components/sidebar/menu";
import { fetchCurrentUser, fetchCurrentUserPermissions } from "@/api/user";
import { LocaleContext } from "@/helpers/locale-context";
import { fetchAllUnits } from "@/api/unit";
import { fetchPipelineProducts } from "@/api/product";
import { Posthog } from "@/components/posthog";
import { User } from "@/entities/user";
import { Unit } from "@/entities/unit";
import { Product } from "@/entities/product";
import { UserPermissionInfo } from "@/entities/user-permission-info";
import { maybeHandleTokenInUrl } from "@/services/auth";

export type RootLoaderData = {
  permissions: UserPermissionInfo;
  products: Product[];
  units: Unit[];
  user: User;
  userUnit?: Unit;
};

export function useRootLoaderData() {
  return useRouteLoaderData("root") as RootLoaderData;
}

export async function loader(): Promise<RootLoaderData | undefined> {
  maybeHandleTokenInUrl();

  const [permissions, products, units, user] = await Promise.all([
    fetchCurrentUserPermissions(),
    fetchPipelineProducts(),
    fetchAllUnits(),
    fetchCurrentUser(),
  ]);

  return {
    permissions,
    products,
    units,
    user,
    userUnit: units.find((unit) => unit.id === Object.keys(permissions.units)[0]),
  };
}

export function shouldRevalidate() {
  // Probably don't need to revalidate this data very often.
  return false;
}

export default function Root() {
  const { user } = useLoaderData() as RootLoaderData;

  useEffect(() => {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  }, [user.id]);

  return (
    <LocaleContext.Provider value={{ locale: user.language }}>
      <Layout>
        <Outlet />
      </Layout>
    </LocaleContext.Provider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <Layout>
      {isRouteErrorResponse(error) && error.status === 404 && <Error404 title="No such thing" />}
      {error instanceof HTTPError && error.response.status === 404 && (
        <Error404 title="No such thing" />
      )}
      {!(isRouteErrorResponse(error) && error.status === 404) && <ErrorGeneric error={error} />}
    </Layout>
  );
}

function Layout({ children }: { children: ReactNode }) {
  const transition = useNavigation();

  return (
    <div
      className={clsx(
        "w-full flex min-h-screen bg-gray-50 text-stone-700",
        // Explicitly set the cursor to `wait` for all links when loading.
        // Because links do not inherit the cursor from parent elements.
        transition.state === "loading" && "cursor-wait [&_a]:cursor-wait",
      )}
    >
      <div className="sticky top-0 flex h-screen min-w-[280px] max-w-[360px] w-1/4">
        <Toolbar />
        <div className="flex-1">
          <Menu />
        </div>
      </div>

      <div
        className={clsx(
          "flex-1 overflow-hidden",
          transition.state === "loading" && "opacity-60 pointer-events-none",
        )}
      >
        {children}
      </div>
      <ScrollRestoration />
      <Posthog />
    </div>
  );
}
