import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { useCallback } from "react";
import invariant from "tiny-invariant";
import { Dialog } from "@/components/dialog";
import { CompanyDetailsData } from "@/routes/companies.$company/loader";
import { BillingForm } from "@/components/billing-form";
import { Billing } from "@/entities/billing";

export default function EditBillingDetailsDialog() {
  const { company, billings } = useRouteLoaderData("company-details") as CompanyDetailsData;
  const params = useParams();
  const navigate = useNavigate();

  invariant(Array.isArray(billings));

  const billing = billings.find((billing) => billing.id.toString() === params.billing);

  if (!billing) {
    throw new Error(`Billing with ID ${params.billing} not found`);
  }

  const onBillingUpdate = useCallback(
    (billing: Billing) => {
      navigate(`/companies/${company.id}`, {
        preventScrollReset: true,
        state: { higlightBilling: billing.id },
      });
    },
    [company, navigate],
  );

  const goBackToCompanyDetails = useCallback(
    () => navigate(`/companies/${company.id}`, { preventScrollReset: true }),
    [],
  );

  return (
    <Dialog
      isOpen
      onClose={goBackToCompanyDetails}
      title="Edit billing details"
      description="Provide information about the company's billing details, so that the invoice can be created correctly."
    >
      <BillingForm
        billing={billing}
        companyId={company.id}
        onCancel={goBackToCompanyDetails}
        onSuccess={onBillingUpdate}
      />
    </Dialog>
  );
}
