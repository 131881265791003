import { Entity } from "@jobilla/entity";
import { Currency } from "./currency";

export class Money extends Entity {
  amount = 0;
  currency!: Currency;

  constructor(amount?: number, currency: Currency = Currency.EUR) {
    super();

    this.amount = amount ?? 0;
    this.currency = currency;
  }

  add(money?: Money) {
    if (!money) {
      return this;
    }

    if (this.currency !== money.currency) {
      throw new Error("Currencies do not match");
    }

    return new Money(this.amount + money.amount, this.currency);
  }
}
