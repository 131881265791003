import { PartialPropsJson } from "@jobilla/entity";
import { FormTypes, Option, QuestionType } from "@/entities/form";

type Question = {
  uuid?: string;
  label: string;
  type: QuestionType;
  attribute?: string;
  subtype?: string | null;
  position?: number;
  validation_rule: string[];
  options?: Array<PartialPropsJson<Option>>;
};

type FormTemplate = {
  version: 2;
  job_id?: number;
  company_id?: number;
  lang?: string;
  type: FormTypes;
  name?: string;
  welcome: {
    title: string;
    subtitle: string;
    description?: string;
    subtext?: string;
  };
  questions: Question[];
  results: Array<{
    title: string;
    description: string;
    category: number;
    buttonLabel?: string;
  }>;
};

// This is a IIFE (Immediately Invoked Function Expression) because this is
// the only way I could make TS actually evaluate the types and values of
// the returned object.
export default ((): FormTemplate => ({
  version: 2,
  type: FormTypes.Application,
  welcome: {
    title: "recruitment_presets.forms.welcome.title",
    subtitle: "recruitment_presets.forms.welcome.subtitle",
  },
  questions: [],
  results: [],
}))();

export const withResultScreens = (formTemplate: FormTemplate) => {
  formTemplate.results.push(
    {
      title: "recruitment_presets.forms.results.?.title",
      description: "recruitment_presets.forms.results.?.description",
      category: 0,
      buttonLabel: "recruitment_presets.forms.results.?.buttonLabel",
    },
    {
      title: "recruitment_presets.forms.results.A+.title",
      description: "recruitment_presets.forms.results.A+.description",
      category: 1,
      buttonLabel: "recruitment_presets.forms.results.A+.buttonLabel",
    },
    {
      title: "recruitment_presets.forms.results.A.title",
      description: "recruitment_presets.forms.results.A.description",
      category: 2,
      buttonLabel: "recruitment_presets.forms.results.A.buttonLabel",
    },
    {
      title: "recruitment_presets.forms.results.B.title",
      description: "recruitment_presets.forms.results.B.description",
      category: 3,
      buttonLabel: "recruitment_presets.forms.results.B.buttonLabel",
    },
    {
      title: "recruitment_presets.forms.results.C.title",
      description: "recruitment_presets.forms.results.C.description",
      category: 4,
      buttonLabel: "recruitment_presets.forms.results.C.buttonLabel",
    },
  );
};

export const withIntroductoryQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.introductory.title",
    type: "select",
    subtype: null,
    position: 0,
    attribute: "",
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.introductory.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.introductory.options.option2",
        position: 1,
      },
    ],
  });
  return formTemplate;
};

export const withCurrentSituationQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.current_situation.title",
    type: "select",
    subtype: "multiple",
    position: 2,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.current_situation.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.current_situation.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.current_situation.options.option3",
        position: 2,
      },
    ],
  });
  return formTemplate;
};

export const withSpecificExperienceQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.specific_experience.title",
    type: "select",
    subtype: "multiple",
    position: 2,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.specific_experience.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.specific_experience.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.specific_experience.options.option3",
        position: 2,
      },
    ],
  });
  return formTemplate;
};

export const withExperienceQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.experience.title",
    type: "select",
    subtype: null,
    position: 2,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.experience.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.experience.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.experience.options.option3",
        position: 2,
      },
      {
        label: "recruitment_presets.forms.questions.experience.options.option4",
        position: 3,
      },
    ],
  });
  return formTemplate;
};

export const withPersonalityQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.personality.title",
    type: "select",
    subtype: "multiple",
    position: 3,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.personality.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.personality.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.personality.options.option3",
        position: 2,
      },
      {
        label: "recruitment_presets.forms.questions.personality.options.option4",
        position: 3,
      },
    ],
  });
  return formTemplate;
};

export const withEducationQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.education.title",
    type: "select",
    subtype: null,
    position: 1,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.education.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.education.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.education.options.option3",
        position: 2,
      },
      {
        label: "recruitment_presets.forms.questions.education.options.option4",
        position: 3,
      },
    ],
  });
  return formTemplate;
};

export const withCommuteQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.commute.title",
    type: "select",
    subtype: null,
    position: 3,
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.commute.options.option1",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.commute.options.option2",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.commute.options.option3",
        position: 2,
      },
    ],
  });
  return formTemplate;
};

export const withContactConsentQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push({
    label: "recruitment_presets.forms.questions.contact_consent.title",
    type: "select",
    subtype: null,
    position: 4,
    attribute: "jobilla::contact_consent",
    validation_rule: ["required"],
    options: [
      {
        label: "recruitment_presets.forms.questions.contact_consent.options.option1",
        uuid: "yes",
        position: 0,
      },
      {
        label: "recruitment_presets.forms.questions.contact_consent.options.option2",
        uuid: "maybe",
        position: 1,
      },
      {
        label: "recruitment_presets.forms.questions.contact_consent.options.option3",
        uuid: "no",
        position: 2,
      },
    ],
  });
};

export const withContactQuestions = (formTemplate: FormTemplate) => {
  formTemplate.questions.push(
    {
      label: "recruitment_presets.forms.questions.contact.name",
      type: "text",
      attribute: "jobilla::name",
      subtype: "name",
      position: 5,
      validation_rule: ["required"],
      options: undefined,
    },
    {
      label: "recruitment_presets.forms.questions.contact.email",
      type: "text",
      attribute: "jobilla::email",
      subtype: "email",
      position: 6,
      validation_rule: ["required", "email"],
      options: undefined,
    },
    {
      label: "recruitment_presets.forms.questions.contact.phone",
      type: "text",
      attribute: "jobilla::phone",
      subtype: "phone",
      position: 7,
      validation_rule: ["required"],
      options: undefined,
    },
  );
  return formTemplate;
};
