import { CheckCircleIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import * as DropdownMenu from "@/components/dropdown-menu";
import { Confirmation } from "@/components/confirmation";
import { UserDropdownMenuContext } from "@/routes/units.$unit/user-dropdown";

export function CancelInvite() {
  const { user } = useContext(UserDropdownMenuContext);
  const [hasCancelledInvitation, setHasCancelledInvitation] = useState(false);

  return (
    <Confirmation
      formAction={`/api/invites/${user.id}/cancel`}
      title="Cancel invitation"
      description={
        <>
          Canceling an invitation will revoke all of this user&apos;s previous invitation links, and
          delete the user from list. <b>Are you sure?</b>
        </>
      }
      submitLabel="Cancel invitation"
      onSuccess={() => {
        setHasCancelledInvitation(true);
      }}
    >
      <DropdownMenu.ButtonItem
        autoClose={false}
        icon={
          hasCancelledInvitation ? (
            <CheckCircleIcon className="w-5 h-5 animate-bounce" />
          ) : (
            <ChevronDoubleRightIcon className="w-4 h-4" />
          )
        }
        label={hasCancelledInvitation ? "Cancelled invitation!" : "Cancel invitation..."}
        subtext="Cancel the invite of this user. Previous invite link will be revoked and this user will be removed from the list."
        onClick={() => {
          setHasCancelledInvitation(false);
        }}
      />
    </Confirmation>
  );
}
