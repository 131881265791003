import { forwardRef, useCallback, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowRightOnRectangleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";
import { RootLoaderData } from "@/root";
import { enforceReauthorization } from "@/services/auth";
import { isPosthogEnabled } from "@/components/posthog";

export function UserPopover() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <UserButton />
      </Popover.Trigger>

      <AnimatePresence>
        {isOpen && (
          <Popover.Portal forceMount>
            <Popover.Content side="right" asChild>
              <UserPopoverContents />
            </Popover.Content>
          </Popover.Portal>
        )}
      </AnimatePresence>
    </Popover.Root>
  );
}

const UserButton = forwardRef<HTMLButtonElement>(function UserButton(props, ref) {
  const { user } = useRouteLoaderData("root") as RootLoaderData;

  return (
    <button {...props} ref={ref} className="p-3 hover:bg-black/30 data-[state=open]:bg-white">
      <div className="flex items-center justify-center w-10 h-10 bg-zinc-400/80 rounded-full text-white font-bold">
        {user.initials}
      </div>
    </button>
  );
});

const UserPopoverContents = forwardRef<HTMLDivElement>(function UserPopoverContents(props, ref) {
  const { user } = useRouteLoaderData("root") as RootLoaderData;
  const posthog = usePostHog();

  const onLogoutPress = useCallback(() => {
    isPosthogEnabled() && posthog.reset();
    enforceReauthorization();
  }, []);

  return (
    <motion.div
      className="bg-white w-56 rounded shadow-xl divide-y border border-l-0 origin-bottom-left"
      animate={{ opacity: 1, scale: 1, transition: { duration: 0 } }}
      exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.1 } }}
      transition={{ ease: "easeInOut" }}
      ref={ref}
      {...props}
    >
      <div className="bg-gray-100 p-6 pb-12 text-center text-sm">
        <p className="font-medium">{user.name}</p>
        <p className="text-xs text-gray-500">{user.email}</p>
      </div>

      <div className="grid grid-cols-2 divide-x">
        <a
          href={`${import.meta.env.VITE_TAS_APP_URL}/profile/overview`}
          className="flex flex-col items-center justify-center text-center space-y-2 p-4 hover:bg-sky-700 hover:text-white transition duration-150"
        >
          <Cog6ToothIcon className="w-5 h-5" />
          <span>Account Settings</span>
        </a>

        <a
          href="https://help.jobilla.com/"
          target="_blank"
          className="flex flex-col items-center justify-center text-center space-y-2 p-4 hover:bg-sky-700 hover:text-white transition duration-150"
          rel="noopener noreferrer"
        >
          <QuestionMarkCircleIcon className="w-5 h-5" />
          <span>Help</span>
        </a>
      </div>

      <button
        type="button"
        className="bg-gray-100 w-full flex items-center justify-center p-4 space-x-2 hover:bg-sky-700 hover:text-white transition duration-150"
        onClick={onLogoutPress}
      >
        <ArrowRightOnRectangleIcon className="w-5 h-5" />
        <span>Logout</span>
      </button>
    </motion.div>
  );
});
