import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api, Paginated } from "@/api/base";
import { Order } from "@/entities/order";

export type FetchCompanyOrdersOptions = {
  page?: number;
  perPage?: number;
};

export async function fetchCompanyOrders(
  companyId: string,
  options: FetchCompanyOrdersOptions = {},
): Promise<Paginated<Order[]>> {
  const response = await api.get("v1/orders", {
    searchParams: {
      company: companyId,
      page: String(options?.page || 1),
      per_page: String(options?.perPage),
    },
  });

  const json = await response.json<Paginated<PropsJson<Order>[]>>();

  return {
    ...json,
    data: EntityBuilder.buildMany(Order, json.data),
  };
}
