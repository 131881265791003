import {
  ActionFunctionArgs,
  Form,
  redirect,
  useActionData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { HTTPError } from "ky";
import * as Yup from "yup";
import { useCallback } from "react";
import { format } from "date-fns";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import { processYupErrors } from "@/helpers/yup-errors";
import SecondaryButton from "@/components/secondary-button";
import { updateInvoice, updateInvoiceStatus } from "@/api/invoice";
import type { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import TextField from "@/components/forms/text-field";
import { Checkbox } from "@/components/forms/checkbox";
import { InvoiceStatus } from "@/entities/invoice-status";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  let input;

  try {
    const requestData = Object.fromEntries((await request.formData()).entries());

    input = await Yup.object()
      .shape({
        latest_date: Yup.date().required(),
        reset_status: Yup.boolean()
          .required()
          .transform((value) => value === "on"),
      })
      .validate(requestData, { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateInvoice(params.invoice, {
      latest_date: format(input.latest_date, "yyyy-MM-dd"),
    });

    if (input.reset_status) {
      await updateInvoiceStatus(params.invoice, InvoiceStatus.New);
    }

    return redirect(`/invoices/${params.invoice}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }
}

export default function PayeeDialog() {
  const { invoice } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const navigate = useNavigate();
  const actionResult = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;

  const goBackToInvoiceDetails = useCallback(() => navigate(`/invoices/${invoice.id}`), []);
  const hasUnexpectedErrors = actionResult?.status === 500;

  return (
    <Dialog isOpen onClose={goBackToInvoiceDetails} title="Change invoice latest sending date">
      <Form method="post">
        <fieldset disabled={false} className="flex flex-col space-y-6">
          <TextField
            type="date"
            name="latest_date"
            label="Latest sending date"
            defaultValue={invoice.latestDate}
            errors={actionResult?.errors?.latest_date}
          />

          <div className="space-y-1">
            <p className="font-bold">Should the invoice status reset?</p>
            <Checkbox
              name="reset_status"
              label="Yes, change invoice status to NEW"
              errors={actionResult?.errors?.reset_status}
            />
          </div>
        </fieldset>

        {hasUnexpectedErrors && (
          <ErrorBlock className="mt-6">
            The submitted data could not be saved because of an unexpected error.
          </ErrorBlock>
        )}

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton size="lg" />

          <SecondaryButton type="button" onClick={goBackToInvoiceDetails} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
