import { Entity, Type } from "@jobilla/entity";
import { OrderItemStatus } from "@/entities/order-item-status";
import { Currency } from "@/entities/currency";
import { InvoiceStatus } from "@/entities/invoice-status";
import { Money } from "./money";
import { Invoice } from "./invoice";
import type { ProductStatus } from "./product-status";
import { OrderItem } from "./order-item";
import { Company } from "./company";
import { OrderUser } from "./order-user";
import { HubspotDeal } from "./hubspot-deal";

export class Order extends Entity {
  id!: string;
  numericId!: number;
  payeeId!: string;
  companyId?: number;
  customerId!: string;
  type!: string;
  origin: "manual" | "hubspot" = "manual";
  createdAt!: string;
  paymentPreference = "invoice";

  @Type(Company)
  company?: Company;

  @Type(() => OrderItem)
  items: OrderItem[] = [];

  @Type(OrderUser)
  users: OrderUser[] = [];

  @Type(() => Invoice)
  invoices: Invoice[] = [];

  @Type(HubspotDeal)
  hubspotDeal?: HubspotDeal;
  hubspotDealId?: string;

  statuses: ProductStatus[] = [];
  notes!: string;
  isTransferOrder?: boolean;

  get itemsTotal(): Money {
    if (!this.items.length) {
      return new Money(0, Currency.EUR);
    }

    return new Money(
      this.items.reduce((total, item) => total + item.amount.amount, 0),
      this.items[0].amount?.currency,
    );
  }

  get isCancellable(): boolean {
    const orderItemsAreNew = this.items.every(
      (item) => item.status === OrderItemStatus.New || item.status === OrderItemStatus.Cancelled,
    );
    const invoicesAreNotBlocking = this.invoices.every(
      (item) => item.canBeEdited || item.status === InvoiceStatus.PendingDeletion,
    );
    const someItemsAreDistributed = this.items.some((item) => item.isDistributed);
    return orderItemsAreNew && invoicesAreNotBlocking && !someItemsAreDistributed;
  }
}
