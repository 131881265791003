import { Await, useLoaderData } from "react-router-dom";
import { ReactNode, Suspense, useMemo } from "react";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";
import { OrderItemStatus } from "@/entities/order-item-status";
import { DangerButton, DangerButtonProps } from "@/components/danger-button";
import { DangerLink } from "@/components/danger-link";

export function Revoke() {
  const { orderItem, permissionToRevokeCampaign } = useLoaderData() as OrderItemDetailsData;

  const isCampaignRevokable = useMemo(() => {
    return orderItem.campaignContext !== null && orderItem.status === OrderItemStatus.New;
  }, [orderItem]);

  return (
    <div className="flex flex-col gap-6 bg-white rounded shadow-sm p-6">
      <div className="flex items-center justify-between gap-4">
        <p className="text-xl font-bold text-gray-600">Revoke this campaign</p>

        <Suspense fallback={<RevokeButton disabled={true}>Checking permissions...</RevokeButton>}>
          <Await resolve={permissionToRevokeCampaign}>
            {(canUserRevokeCampaign: boolean) => (
              <RevokeButton disabled={!isCampaignRevokable || !canUserRevokeCampaign}>
                Revoke campaign
              </RevokeButton>
            )}
          </Await>
        </Suspense>
      </div>

      <Suspense>
        <Await resolve={permissionToRevokeCampaign}>
          {(canUserRevokeCampaign: boolean) =>
            !canUserRevokeCampaign && (
              <p className="text-slate-500 italic text-sm">
                You cannot revoke campaigns because your account does not have the permission to do
                so.
              </p>
            )
          }
        </Await>
      </Suspense>

      {orderItem.status !== OrderItemStatus.New && (
        <p className="text-slate-500 italic text-sm">
          You cannot revoke campaigns that are already being produced. To revoke the campaign,
          return it to &quot;New&quot; first.
        </p>
      )}

      {orderItem.campaignContext === null && (
        <p className="text-slate-500 italic text-sm">There is no campaign to revoke.</p>
      )}

      <p className="text-sm text-zinc-700">
        Revoke this campaign request and restore it back to customer&apos;s inventory.
      </p>
    </div>
  );
}

type RevokeButtonArgs = { children: ReactNode; disabled?: boolean } & DangerButtonProps;

function RevokeButton({ disabled = false, children }: RevokeButtonArgs) {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  if (disabled) {
    return (
      <DangerButton disabled size="lg">
        {children}
      </DangerButton>
    );
  }

  return (
    <DangerLink preventScrollReset to={`/order-items/${orderItem.id}/revoke`} size="lg">
      {children}
    </DangerLink>
  );
}
