import { Entity, Type } from "@jobilla/entity";

export enum FormTypes {
  Application = "application",
}

export type QuestionType =
  | "text"
  | "opentext"
  | "select"
  | "datetime"
  | "file"
  | "welcome"
  | "final-screen";

export class Option extends Entity {
  uuid!: string;
  label!: string;
  score?: number;
  position!: number;
  created_at!: string;
}

export class Question extends Entity {
  type: QuestionType = "text";
  subtype!: string | null;
  label!: string;
  companyId!: number;
  slug!: string | null;
  publishedAt!: string;
  attribute?: string;
  position = 0;
  score!: number;
  created_at!: string;
  uuid!: string;
  validation_rule: string[] = [];
  @Type(Option)
  options!: Option[];
}

export class Welcome extends Entity {
  uuid!: string;
  title!: string;
  subtitle!: string;
  description!: string;
}

export class Form extends Entity {
  uuid!: string;
  version = 2;
  companyId!: number;
  jobId!: number;
  name!: string;
  lang!: string;
  createdAt!: string;
  type!: FormTypes;
  @Type(Welcome)
  welcome!: Welcome;
  @Type(Question)
  questions!: Question[];
  sendButton!: {
    button_label: string | null;
  };
}
