import * as React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

export function init() {
  if (import.meta.env.MODE === "development" || !import.meta.env.VITE_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb) {
      if (isPostHogBreadcrumb(breadcrumb)) {
        return null;
      }

      return breadcrumb;
    },
  });
}

function isPostHogBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  try {
    return (
      breadcrumb.category === "xhr" && new URL(breadcrumb.data?.url).host.includes("posthog.com")
    );
  } catch (error) {
    if (error instanceof TypeError && !error.message.includes("Invalid URL")) {
      Sentry.captureException(error);
    }
  }

  return false;
}
