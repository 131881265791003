import { createContext, useState } from "react";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import { Unit } from "@/entities/unit";
import { User } from "@/entities/user";
import * as DropdownMenu from "@/components/dropdown-menu";
import SecondaryButton from "@/components/secondary-button";
import { ResendInvite } from "@/routes/units.$unit/resend-invite";
import { CancelInvite } from "@/routes/units.$unit/cancel-invite";
import { ChangeUnit } from "@/routes/units.$unit/change-unit";
import { ChangeRole } from "@/routes/units.$unit/change-role";

export type UserDropdownMenuContextData = {
  unit: Unit;
  user: User;
};

export const UserDropdownMenuContext = createContext<UserDropdownMenuContextData>({
  unit: new Unit(),
  user: new User(),
});

export function UserDropdownMenu({ unit, user }: { unit: Unit; user: User }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UserDropdownMenuContext.Provider value={{ unit, user }}>
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenu.Trigger asChild>
          <SecondaryButton size="md-square">
            <Cog8ToothIcon className="w-4 h-4" />
          </SecondaryButton>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content>
          {user.setupCompletedAt ? <DropdownForActiveUser /> : <DropdownForInvitedUser />}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </UserDropdownMenuContext.Provider>
  );
}

function DropdownForActiveUser() {
  return (
    <>
      <ChangeUnit />
      <ChangeRole />
    </>
  );
}

function DropdownForInvitedUser() {
  return (
    <>
      <ResendInvite />
      <CancelInvite />
    </>
  );
}
