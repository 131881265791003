import { useRouteLoaderData } from "react-router-dom";
import MenuSection from "@/components/sidebar/menu/section";
import { RootLoaderData } from "@/root";
import { OrderPipelines } from "@/components/sidebar/menu/order-pipelines";

export default function Menu() {
  return (
    <div className="ml-1">
      <div className="h-screen overflow-y-auto overscroll-contain bg-white shadow-xl rounded-xl py-8">
        <p className="text-2xl font-bold tracking-tight ml-4">Jobilla Admin</p>

        <div className="pt-6 block clear-both" />

        <MenuSection.Divider />

        <MenuSection title="Customers" createLink="/companies/create">
          <MenuSection.Item href="/companies">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                role="presentation"
                className="inline-block mr-2 text-blue-700 w-4 h-4 fill-current"
              >
                <path
                  fillRule="evenodd"
                  d="M21 11h-2v10c0 .353-.072.686-.185 1H21a1 1 0 001-1v-9a1 1 0 00-1-1M5 11H3a1 1 0 00-1 1v9a1 1 0 001 1h2.184A2.962 2.962 0 015 21V11zM14 7h-4a1 1 0 110-2h4a1 1 0 010 2m0 4h-4a1 1 0 110-2h4a1 1 0 010 2m0 4h-4a1 1 0 110-2h4a1 1 0 010 2m2-13H8a1 1 0 00-1 1v18a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1"
                />
              </svg>
              Companies
            </div>
          </MenuSection.Item>
        </MenuSection>

        <MenuSection.Divider />

        <MenuSection title="Orders" createLink="/orders/create">
          <MenuSection.Item href="/orders">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                role="presentation"
                className="inline-block mr-2 text-blue-700 w-4 h-4 fill-current"
              >
                <path
                  d="M9.5 17a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM4.16 2a3 3 0 012.97 2.573l.02.178.02.249h13.504a1 1 0 01.962 1.275l-1.46 5.109a1 1 0 01-.81.713L8.254 13.81a.996.996 0 00.469.184L8.84 14H20a1 1 0 01.117 1.993L20 16H8.84a3 3 0 01-2.97-2.573l-.02-.178-.694-8.332a1 1 0 00-.878-.91L4.16 4H3a1 1 0 01-.117-1.993L3 2h1.16z"
                  fillRule="evenodd"
                />
              </svg>
              Orders
            </div>
          </MenuSection.Item>

          <OrderPipelines />

          <MenuSection.Item href="/invoices">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                role="presentation"
                className="inline-block mr-2 text-blue-700 w-4 h-4 fill-current"
              >
                <path
                  d="M18 2a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2h12zm-6 3a1 1 0 00-1 1v1.279l-1.273.424-.168.063A2.526 2.526 0 008 10.1c0 1.102.74 2.066 1.805 2.35l1.195.318v1.844l-1.684-.56-.113-.031a1 1 0 00-.52 1.928L11 16.72V18l.007.117A1 1 0 0013 18v-1.28l1.273-.423.168-.063A2.526 2.526 0 0016 13.9c0-1.102-.74-2.066-1.805-2.35L13 11.231V9.387l1.684.562.113.03a1 1 0 00.52-1.928L13 7.28V6l-.007-.117A1 1 0 0012 5zm1 8.301l.68.182.077.029c.146.07.243.22.243.388a.526.526 0 01-.36.5l-.64.212v-1.311zm-2-3.915v1.312l-.68-.18-.077-.03A.432.432 0 0110 10.1l.007-.084a.526.526 0 01.353-.415l.64-.215z"
                  fillRule="evenodd"
                />
              </svg>
              Invoices
            </div>
          </MenuSection.Item>
        </MenuSection>

        <MenuSection.Divider />

        <Units />
      </div>
    </div>
  );
}

function Units() {
  const { units } = useRouteLoaderData("root") as RootLoaderData;

  return (
    <MenuSection title="Jobilla Units">
      {units.map((unit) => (
        <MenuSection.Item key={unit.id} href={`/units/${unit.id}`}>
          <div className="pl-6">{unit.name}</div>
        </MenuSection.Item>
      ))}
    </MenuSection>
  );
}
