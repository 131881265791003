import { useContext, useMemo } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { UserDropdownMenuContext } from "@/routes/units.$unit/user-dropdown";
import { Confirmation } from "@/components/confirmation";
import * as DropdownMenu from "@/components/dropdown-menu";
import SelectBox from "@/components/forms/select-box";
import { NonNullRole, TeamsAndRoles, teamsAndRoles } from "@/helpers/teams-and-roles";

export function ChangeRole() {
  const { user, unit } = useContext(UserDropdownMenuContext);

  return (
    <Confirmation
      formAction={`/api/users/${user.id}/role`}
      title="Change role"
      content={<DialogContents />}
      submitLabel={`Change ${user.name} to selected role`}
    >
      <DropdownMenu.ButtonItem
        autoClose={false}
        icon={<ChevronDoubleRightIcon className="w-4 h-4" />}
        label="Change role or team..."
        subtext={`Assign this user another role, still within the ${unit.name}.`}
      />
    </Confirmation>
  );
}

function DialogContents() {
  const { user } = useContext(UserDropdownMenuContext);

  const roles = useMemo(() => {
    return (teamsAndRoles as TeamsAndRoles)
      .map((team) => team.roles)
      .flat()
      .filter((role): role is NonNullRole => role !== null && role.id !== null);
  }, [teamsAndRoles]);

  return (
    <SelectBox label="New role" name="role" defaultValue={user.role}>
      {roles.map((role) => (
        <option key={role.id} value={role.id}>
          {role.userTitle}
        </option>
      ))}
    </SelectBox>
  );
}
