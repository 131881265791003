import {
  ActionFunctionArgs,
  redirect,
  ShouldRevalidateFunction,
  useActionData,
  useLoaderData,
} from "react-router-dom";
import * as Yup from "yup";
import { HTTPError } from "ky";
import TextField from "@/components/forms/text-field";
import SelectBox from "@/components/forms/select-box";
import { fetchLocationUnits } from "@/api/unit";
import SubmitButton from "@/components/submit-button";
import { createCompany, fetchUsersByTeam } from "@/api/company";
import { updateCompanyBusinessUnit } from "@/api/company-business-unit";
import { ErrorBlock } from "@/components/error-block";
import { ValidatedForm } from "@/components/forms/validated-form";
import { updateAccountManager } from "@/api/company-account-manager";

const formSchema = Yup.object()
  .shape({
    name: Yup.string().required("You have to provide a company name"),
    lang: Yup.string().required("You have to choose a language"),
    unit_id: Yup.string().required("You have to choose a business unit"),
    account_manager_id: Yup.number()
      .required("You have to choose an account manager")
      .typeError("You have to choose an account manager"),
  })
  .defined();

export async function loader() {
  const jobillans = await fetchUsersByTeam("sales");

  jobillans.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));

  return {
    units: await fetchLocationUnits(),
    jobillans,
  };
}

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  // Don't ever need to revalidate this page, because it's only fetching the unit list,
  // and that's a fairly static data that doesn't require refreshing between submissions.
  return false;
};

export async function action({ request }: ActionFunctionArgs) {
  const data = Object.fromEntries(await request.formData()) as Yup.InferType<typeof formSchema>;

  try {
    const company = await createCompany({
      name: data.name,
      lang: data.lang,
    });

    await updateCompanyBusinessUnit(company.id.toString(), data.unit_id);
    await updateAccountManager(company.id, data.account_manager_id);

    return redirect(`/companies/${company.id}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: ((await e.response.json()) as { errors: Record<string, string[]>; message: string })
          .errors,
      };
    }

    return {
      status: 500,
    };
  }
}

export default function CompanyCreatePage() {
  const { jobillans, units } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const actionResult = useActionData() as Exclude<Awaited<ReturnType<typeof action>>, Response>;

  const hasUnexpectedError = actionResult?.status === 500;

  return (
    <div className="px-8 py-8 lg:pb-16">
      <h1 className="mt-8 text-3xl font-bold text-gray-800">New Company</h1>

      <ValidatedForm className="mt-8 max-w-lg" formSchema={formSchema}>
        <fieldset className="space-y-8">
          <TextField autoFocus name="name" label="Company name" />
          <SelectBox
            name="lang"
            label="Default company language"
            description="Your default form templates will be in this language."
            defaultValue="en-GB"
          >
            <option value="fi-FI">FI - Finnish</option>
            <option value="en-GB">EN - English</option>
            <option value="lv-LV">LV - Latvian</option>
            <option value="de-DE">DE - German</option>
          </SelectBox>

          <SelectBox
            name="unit_id"
            label="Company business unit"
            description="Assigns the created company to the selected business unit."
            defaultValue=""
          >
            <option disabled value="">
              Choose a unit
            </option>

            {units.map((unit) => (
              <option key={unit.id} value={unit.id}>
                {unit.name}
              </option>
            ))}
          </SelectBox>

          <SelectBox
            name="account_manager_id"
            label="Account manager"
            description="Assigns the selected person to the company. You can change it later."
            defaultValue=""
          >
            <option disabled value="">
              Choose a Jobillan
            </option>

            {jobillans.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </SelectBox>
        </fieldset>

        {hasUnexpectedError && (
          <ErrorBlock className="mt-8">
            Unexpected error occurred while creating the company.
          </ErrorBlock>
        )}

        <div className="mt-8">
          <SubmitButton size="lg" label="Create" labelWhenSubmitting="Creating..." />
        </div>
      </ValidatedForm>
    </div>
  );
}
