import { useCallback } from "react";
import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from "react-router-dom";
import { object, string, ValidationError } from "yup";
import { HTTPError } from "ky";
import { Dialog } from "@/components/dialog";
import { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import TextField from "@/components/forms/text-field";
import SelectBox from "@/components/forms/select-box";
import SubmitButton from "@/components/submit-button";
import SecondaryButton from "@/components/secondary-button";
import { processYupErrors } from "@/helpers/yup-errors";
import { createBillingDetailsRequest } from "@/api/billing-request";
import { SpinnerIcon } from "@/components/icons/spinner";
import { localesAndLabels } from "@/helpers/language-and-locale";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  const rawInput = Object.fromEntries((await request.formData()).entries());
  let input;

  try {
    input = await object({
      email: string()
        .required("You must provide the customer's email address.")
        .email("This email address is invalid."),
      language: string().required(
        "You must select the language of the email that will be sent to the customer.",
      ),
    }).validate(rawInput, { abortEarly: false });
  } catch (e) {
    if (e instanceof ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await createBillingDetailsRequest({
      source_id: input.email,
      lang: input.language,
      invoice_id: params.invoice,
      type: "creation",
      source_type: "external",
      confirmed: false,
    });

    return { status: 200 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: e.response.json,
      };
    }

    return { status: 500 };
  }
}

export default function RequestBilling() {
  const { invoice } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const transition = useNavigation();
  const actionResult = useActionData() as Awaited<ReturnType<typeof action>>;
  const navigate = useNavigate();

  const isSubmitting = transition.state === "submitting";
  const isSuccessful = !isSubmitting && actionResult?.status === 200;

  const goBackToInvoiceDetails = useCallback(
    () => navigate(`/invoices/${invoice.id}`, { preventScrollReset: true }),
    [invoice],
  );

  return (
    <Dialog isOpen onClose={goBackToInvoiceDetails} title="Request billing details">
      <Form method="post" noValidate>
        <fieldset disabled={isSubmitting || isSuccessful} className="flex flex-col gap-6">
          <TextField
            required
            type="email"
            label="Customer email"
            name="email"
            description="We will send the customer an email where they can provide and confirm their billing details."
            placeholder="jones@example.com"
          />

          <SelectBox
            name="language"
            label="Language"
            description="The language of the email that will be sent to the customer."
          >
            {localesAndLabels.map((locale) => (
              <option key={locale.code} value={locale.code}>
                {locale.label}
              </option>
            ))}
          </SelectBox>
        </fieldset>

        <div className="mt-6 flex items-center justify-center space-x-4">
          <SubmitButton
            size="lg"
            disabled={isSubmitting || isSuccessful}
            label={isSuccessful ? "✅ Sent" : "Send request"}
            labelWhenSubmitting={
              <>
                <SpinnerIcon className="w-4 h-4 animate-spin" />
                Submitting
              </>
            }
          />

          <SecondaryButton type="button" size="lg" onClick={goBackToInvoiceDetails}>
            {isSuccessful ? "Close" : "Cancel"}
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
