import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { useCallback } from "react";
import { cancelOrder } from "@/api/order";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import SecondaryLink from "@/components/secondary-link";
import SecondaryButton from "@/components/secondary-button";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterExists } from "@/helpers/loader-guards";
import { MoneyDisplay } from "@/components/money-display";

export async function action({ params }: ActionFunctionArgs) {
  assertParameterExists(params.order);

  try {
    await cancelOrder(params.order);

    // Ideal scenario is to directly redirect to the company details page here, but if we do it with
    // a response from the action, we will not be able to show the result in the redirected page. So
    // we return a result here, and redirect in the component to attach state to the redirect.
    return { status: 201 };
  } catch (e) {
    return { status: 500 };
  }
}

export default function CancelOrderDialog() {
  const { order } = useRouteLoaderData("order-details") as OrderDetailsData;
  const actionResult = useActionData() as Awaited<ReturnType<typeof action>>;

  const navigate = useNavigate();
  const params = useParams();

  const isActionSuccessful = actionResult?.status === 201;

  const navigateToOrder = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/orders/${params.order}`);
    }
  }, []);

  return (
    <Dialog
      isOpen
      title={`Cancel order #${order.numericId}`}
      onClose={navigateToOrder}
      // Only allow closing the dialog if the form hasn't been submitted yet.
      shouldCloseOnBlur={false}
      isDismissable={actionResult === undefined}
      isKeyboardDismissDisabled={actionResult !== undefined}
      shouldCloseOnInteractOutside={() => actionResult === undefined}
    >
      {isActionSuccessful ? <CancelSuccess /> : <CancelForm />}
    </Dialog>
  );
}

function CancelForm() {
  const { order } = useRouteLoaderData("order-details") as OrderDetailsData;
  const actionResult = useActionData() as Awaited<ReturnType<typeof action>>;

  const navigate = useNavigate();
  const params = useParams();

  const hasErrors = typeof actionResult?.status === "number" && actionResult.status !== 201;

  const navigateToOrder = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/orders/${params.order}`);
    }
  }, []);

  return (
    <Form method="post">
      <div className="space-y-2">
        <p>
          Are you sure you want to cancel order #{order.numericId} with {order.items.length} order
          items and sum of <MoneyDisplay amount={order.itemsTotal} />?
        </p>
        <p>
          This action is irreversible, but it&apos;s possible to identify canceled orders
          afterwards.
        </p>
      </div>

      {hasErrors && (
        <ErrorBlock>
          Could not cancel the order. Please try again. If the problem persists, contact support.
        </ErrorBlock>
      )}

      <div className="mt-8 flex items-center justify-center space-x-4">
        <SubmitButton label="Cancel order" labelWhenSubmitting="Cancelling..." />

        <SecondaryButton type="button" onClick={navigateToOrder} size="lg">
          Back
        </SecondaryButton>
      </div>
    </Form>
  );
}

function CancelSuccess() {
  const { order } = useRouteLoaderData("order-details") as OrderDetailsData;

  return (
    <div>
      <p className="leading-relaxed">
        Okay, the order is now canceled. It will no longer be visible in any orders list and will
        not be accessible via direct link.
      </p>

      <div className="mt-8 flex items-center justify-center space-x-4">
        <SecondaryLink to={`/companies/${order.companyId}`}>Go to related company</SecondaryLink>
      </div>
    </div>
  );
}
