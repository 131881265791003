import { ShouldRevalidateFunction } from "react-router-dom";

export function preventRevalidationForFailedSubmissions(
  params: Parameters<ShouldRevalidateFunction>[0],
) {
  // We don't need to reload data if there was a form submission and it failed.
  if (
    params.actionResult &&
    (!("status" in params.actionResult) || params.actionResult.status > 300)
  ) {
    return false;
  }
}

/**
 * Prevents revalidation when modals within the page are opened.
 */
export function preventRevalidationForModalNavigation(
  params: Parameters<ShouldRevalidateFunction>[0],
  paramName = "id",
) {
  const currentPath = params.currentUrl.pathname.replace(/\/$/, "");
  const nextPath = params.nextUrl.pathname.replace(/\/$/, "");

  if (
    nextPath.includes(currentPath) &&
    nextPath !== currentPath &&
    params.currentParams[paramName] === params.nextParams[paramName]
  ) {
    return false;
  }
}

/**
 * Prevents revalidation when modals within the page are closed.
 */
export function preventRevalidationForModalClose(
  params: Parameters<ShouldRevalidateFunction>[0],
  paramName = "id",
) {
  const currentPath = params.currentUrl.pathname.replace(/\/$/, "");
  const nextPath = params.nextUrl.pathname.replace(/\/$/, "");

  if (
    currentPath.includes(nextPath) &&
    currentPath !== nextPath &&
    params.currentParams[paramName] === params.nextParams[paramName] &&
    // If the navigation was a result of a form submission, it's not considered _just_ a modal
    // close, and the data should be revalidated.
    params.formAction === undefined
  ) {
    return false;
  }
}
