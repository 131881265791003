import { EntityBuilder } from "@jobilla/entity";
import invariant from "tiny-invariant";
import Delta from "quill-delta";
import { CompanyNote } from "@/entities/company-note";
import { api } from "@/api/base";

export async function fetchCompanyNotes(companyId: number): Promise<CompanyNote> {
  const response = await api.get(`v1/companies/${companyId}/notes`);

  const json = await response.json<{ data?: { content: Delta } }>();

  if (!json?.data?.content) {
    return EntityBuilder.buildOne(CompanyNote, {
      company_id: companyId,
      content: { ops: [] } as unknown as Delta,
    });
  }

  invariant(json.data.content.ops?.length, "Unexpected content.");

  return EntityBuilder.buildOne(CompanyNote, {
    company_id: companyId,
    content: json.data.content,
  });
}

export async function updateCompanyNotes(companyId: number, content: object): Promise<void> {
  await api.put(`v1/companies/${companyId}/notes`, {
    body: JSON.stringify({
      content: {
        ops: content,
      },
    }),
  });
}
