import { PropsWithChildren, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

export type HeadingArgs = PropsWithChildren<{
  backLink?: ReactNode;
}>;

export function Heading({ backLink, children }: HeadingArgs) {
  return (
    <header className="flex flex-col gap-4">
      {backLink}
      <div className="flex flex-col gap-2">{children}</div>
    </header>
  );
}

export type GoBackLinkArgs = PropsWithChildren<{
  to: LinkProps["to"];
}>;

export function GoBackLink({ children, to }: GoBackLinkArgs) {
  return (
    <p>
      <Link
        to={to}
        className="
          inline-flex items-center gap-2
          hover:text-indigo-500 hover:translate-x-2
          transition ease-out duration-500
        "
      >
        <ArrowLeftIcon className="block w-4 h-4 -mt-0.5" />
        <span>{children}</span>
      </Link>
    </p>
  );
}

export function Title({ children }: PropsWithChildren<NonNullable<unknown>>) {
  return <h1 className="text-3xl font-bold tracking-tight">{children}</h1>;
}

export function Description({ children }: PropsWithChildren<NonNullable<unknown>>) {
  return <h2 className="font-light text-slate-500 tracking-tight">{children}</h2>;
}
