import { useFetcher, useLoaderData } from "react-router-dom";
import { capitalize } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import PaperPlane from "@/components/icons/paper-plane";
import { NetsuiteAttachment } from "@/entities/netsuite-attachment";
import Date, { DateDisplayType } from "@/components/date";
import SubmitButton from "@/components/submit-button";
import { SpinnerIcon } from "@/components/icons/spinner";
import { Checkbox } from "@/components/forms/checkbox";

export function NetsuiteField() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;
  const [isSentToNetsuite, setIsSentToNetsuite] = useState(false);
  const [netsuiteStatus, setNetsuiteStatus] = useState<string>("send"); // ["success", "error"]
  const fetcher = useFetcher();
  const errors = fetcher.data?.error;

  const successText = () => {
    if (netsuiteStatus === "send") {
      return "Successfully sent to Netsuite";
    } else {
      return "Successfully updated in Netsuite";
    }
  };

  useEffect(() => {
    if (
      invoice.latestOrderedAttachments?.length <= 0 ||
      invoice.latestOrderedAttachments[0].type === "credit note"
    ) {
      setNetsuiteStatus("send");
    } else if (invoice.latestOrderedAttachments[0].type === "invoice") {
      setNetsuiteStatus("update");
    }
  }, [invoice]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    invoice.overrideEinvoice = checked;
  };

  const onSendNetsuite = useCallback(() => {
    fetcher.submit(
      { invoiceId: invoice.id, overrideEinvoice: invoice.overrideEinvoice },
      { method: "put", action: `/invoices/${invoice.id}/send-netsuite` },
    );
    setIsSentToNetsuite(true);
  }, [fetcher]);

  return (
    <>
      <SubmitButton
        type="button"
        size="lg"
        isSubmitting={fetcher.state === "submitting"}
        disabled={invoice.status != "ready_to_send"}
        disabledTooltipText="Invoice must be ready to send before sending it to Netsuite"
        label={
          <>
            <PaperPlane className="inline-block w-4 h-4 -mt-0.5 mr-1" />
            <span>{netsuiteStatus === "send" ? "Send to Netsuite" : "Update in Netsuite"}</span>
          </>
        }
        labelWhenSubmitting={
          <>
            <SpinnerIcon className="w-4 h-4 animate-spin" />
            <span>
              {netsuiteStatus === "send" ? "Sending to Netsuite" : "Updating in Netsuite"}
            </span>
          </>
        }
        onClick={onSendNetsuite}
      >
        <PaperPlane className="inline-block w-4 h-4 -mt-0.5 mr-1" />
        <span>{netsuiteStatus === "send" ? "Send to Netsuite" : "Update in Netsuite"}</span>
      </SubmitButton>

      <Checkbox
        name="override_einvoice"
        label="Override eInvoice operator with default"
        onChange={handleCheckboxChange}
      />

      {isSentToNetsuite && fetcher.state != "submitting" && !errors && (
        <p className="rounded-full bg-green-200 py-2 text-center">{successText()}</p>
      )}

      {isSentToNetsuite && fetcher.state != "submitting" && errors?.status === 500 && (
        <p className="rounded-full bg-red-400 py-2 text-center">
          Cannot sent to Netsuite. Please try again
        </p>
      )}

      {invoice.attachments?.length > 0 && (
        <div className="space-y-2">
          <p className="font-bold">Attachments sent to Netsuite</p>
          {invoice.latestOrderedAttachments.map((attachment: NetsuiteAttachment) => (
            <NetsuiteAttachmentField attachment={attachment} key={attachment.id} />
          ))}
        </div>
      )}
    </>
  );
}

function NetsuiteAttachmentField({ attachment }: { attachment: NetsuiteAttachment }) {
  const goToNetsuite = () => {
    window.open(attachment.url, "_blank", "toolbar=0,location=0,menubar=0");
  };

  return (
    <button
      className="flex flex-col w-full items-start justify-between bg-white rounded pl-4 py-3 space-y-1"
      onClick={goToNetsuite}
    >
      <span className="font-bold">
        {capitalize(attachment.type)} #{attachment.reference}
      </span>
      <div
        className="inline-block rounded-full text-xs py-0.5 px-2 font-bold
          bg-green-100 text-green-600 hover:bg-green-200 hover:text-green-700"
      >
        <span>
          Sent <Date date={attachment.createdAt} display={DateDisplayType.DATE} />
        </span>
      </div>
    </button>
  );
}
