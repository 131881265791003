import { InputHTMLAttributes, RefObject } from "react";
import { useFormContext } from "react-hook-form";
import { mergeRefs } from "@/components/forms/merge-refs";

type RichRadioArgs = {
  name: string;
  float?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
} & InputHTMLAttributes<HTMLInputElement>;

export function RichRadio({ name, children, inputRef, float, className, ...props }: RichRadioArgs) {
  const baseClasses = [
    "relative flex items-start bg-white py-4 px-5",
    "border-2 border-zinc-200 rounded",
    "[&:has(:checked)]:border-cyan-600 [&:has(:checked)]:bg-cyan-50",
    "[&:has(:disabled)]:opacity-40",
    "transition duration-100 cursor-pointer",
  ];

  const { register } = useFormContext() || { register: () => {} };

  const { ref, ...rest } = register(name, { value: props.defaultValue }) || {};

  return (
    <label className={[...baseClasses, className ?? ""].join(" ")}>
      {/* input[type=radio] does not support readOnly attribute, so we will simulate it. */}
      {props.readOnly && props.defaultChecked && (
        <input ref={ref} {...rest} type="hidden" name={name} value={props.value} />
      )}

      <input
        {...rest}
        name={name}
        ref={inputRef ? mergeRefs(inputRef, ref) : ref}
        type="radio"
        disabled={props.readOnly}
        className={[
          "text-green-600 focus:ring-green-600",
          float ? "absolute left-4 top-4" : "mt-1 mr-3",
        ].join(" ")}
        {...props}
      />
      <div className="flex-1 h-full">{children}</div>
    </label>
  );
}
