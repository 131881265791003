import { Entity, Type } from "@jobilla/entity";

export class AvailabilitySummary extends Entity {
  total!: number;
  available!: number;
  setup!: number;
  active!: number;
  consumed!: number;
}

export class CampaignSummary extends Entity {
  @Type(AvailabilitySummary)
  lite!: AvailabilitySummary;

  @Type(AvailabilitySummary)
  standard!: AvailabilitySummary;

  @Type(AvailabilitySummary)
  pro!: AvailabilitySummary;

  get totalCampaigns(): number {
    return (this.lite?.total ?? 0) + (this.standard?.total ?? 0) + (this.pro?.total ?? 0);
  }

  get availableCampaigns(): number {
    // eslint-disable-next-line max-len
    return (this.lite?.available ?? 0) + (this.standard?.available ?? 0) + (this.pro?.available ?? 0);
  }

  get setupCampaigns(): number {
    return (this.lite?.setup ?? 0) + (this.standard?.setup ?? 0) + (this.pro?.setup ?? 0);
  }

  get activeCampaigns(): number {
    return (this.lite?.active ?? 0) + (this.standard?.active ?? 0) + (this.pro?.active ?? 0);
  }

  get consumedCampaigns(): number {
    return (this.lite?.consumed ?? 0) + (this.standard?.consumed ?? 0) + (this.pro?.consumed ?? 0);
  }
}

export class InventorySummary extends Entity {
  @Type(CampaignSummary)
  campaign!: CampaignSummary;

  @Type(AvailabilitySummary)
  durationBooster!: AvailabilitySummary;

  @Type(AvailabilitySummary)
  rangeBooster!: AvailabilitySummary;

  @Type(AvailabilitySummary)
  contactBooster!: AvailabilitySummary;
}
