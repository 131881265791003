import { EntityBuilder } from "@jobilla/entity";
import { api, Paginated } from "@/api/base";
import { JobList } from "@/entities/job-list";

export async function fetchCompanyJobLists(companyId: number) {
  const response = await api
    .get("api/v2/job-lists", {
      searchParams: {
        company_id: companyId,
      },
    })
    .json<Paginated<JobList[]>>();

  return EntityBuilder.buildMany(JobList, response.data);
}
