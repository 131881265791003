import { useLoaderData } from "react-router-dom";
import * as DL from "@/components/definition-list";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";

export function Boosters() {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  return (
    <DL.Root>
      <DL.Item label="Additional booster">
        <p>
          {orderItem.campaignContext?.boosterType === null ? (
            <span className="text-zinc-400">None</span>
          ) : orderItem.campaignContext?.boosterType === "duration_booster" ? (
            <b>Duration</b>
          ) : orderItem.campaignContext?.boosterType === "contact_booster" ? (
            <b>Candidate contacting service</b>
          ) : (
            <b>Range</b>
          )}
        </p>

        {orderItem.campaignContext?.boosterType !== null && (
          <p className="text-violet-600">
            A {orderItem.campaignContext?.boosterType.replaceAll("_", " ")} is applied to this
            recruitment.
          </p>
        )}
      </DL.Item>
    </DL.Root>
  );
}
