import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { Product } from "@/entities/product";

export async function fetchProducts(): Promise<Product[]> {
  const response = await api.get(`v1/products`).json<{ data: PropsJson<Product>[] }>();

  return EntityBuilder.buildMany(Product, response.data);
}

export async function fetchPipelineProducts(): Promise<Product[]> {
  const response = await api
    .get(`v1/products?has_pipeline=1`)
    .json<{ data: PropsJson<Product>[] }>();

  return EntityBuilder.buildMany(Product, response.data);
}
