import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import Select from "react-select";
import { Label } from "@/components/forms/label";
import { OrderItemRecruitmentWizardLoaderData } from "@/routes/order-items.$orderItem.recruitment/route";
import { User } from "@/entities/user";
import { useActionErrors } from "@/hooks/useActionErrors";

export function AssigneeField() {
  const { companyUsers } = useLoaderData() as OrderItemRecruitmentWizardLoaderData;
  const [assignees, setAssignees] = useState<User[]>([]);
  const errors = useActionErrors()?.["assignees"] ?? [];

  return (
    <div>
      <Label>Assignees</Label>

      {assignees.map((assignee) => (
        <input
          key={assignee.id.toString()}
          type="hidden"
          name="assignees"
          value={assignee.id.toString()}
        />
      ))}

      <Select
        unstyled
        isMulti
        menuPlacement="auto"
        minMenuHeight={220}
        isSearchable={false}
        closeMenuOnSelect={false}
        options={companyUsers}
        onChange={(selection) => setAssignees(selection.slice(0))}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id.toString()}
        classNames={{
          control: () =>
            "w-full form-input flex items-center justify-between rounded-md border-0 ring-1 ring-stone-300 focus:bg-purple-50 focus:ring-1 focus:ring-purple-500 focus:outline-0 transition duration-200 disabled:bg-stone-50 disabled:cursor-not-allowed disabled:text-stone-500",
          valueContainer: () => "flex flex-wrap items-center cursor-default select-none gap-2",
          multiValue: () =>
            "flex items-center gap-1 overflow-hidden bg-emerald-500 text-white rounded pl-1",
          multiValueLabel: () => "text-sm py-0.5 pl-1",
          multiValueRemove: () => "self-stretch hover:bg-emerald-700/40 px-1.5 rounded",
          menu: () => "mt-1 bg-white border shadow-lg rounded-md py-2",
          menuList: () => "overflow-y-auto",
          option: () =>
            "flex items-center justify-start py-2 px-4 gap-2 cursor-pointer select-none hover:bg-emerald-200/50",
        }}
      />

      {errors?.map((errorText) => (
        <p className="text-sm mt-1 text-red-700" key={errorText}>
          {errorText}
        </p>
      ))}
    </div>
  );
}
