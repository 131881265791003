import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Billing } from "@/entities/billing";
import PencilIcon from "@/components/icons/pencil";
import { Table, TableColumn, TableRow } from "@/components/table";

export type BillingsListProps = {
  billings: Billing[];
};

export default function BillingsList({ billings }: BillingsListProps) {
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.highlightBilling) {
      return;
    }

    const element = document.getElementById(`billing-${location.state?.highlightBilling}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [location.state?.highlightBilling]);

  return (
    <Table
      columns={[
        { label: "Name" },
        { label: "Country/E-Invoice operator" },
        { label: "City/E-Invoice address" },
        { label: "VAT" },
        { label: "Reference" },
        { label: "", minimal: true },
      ]}
    >
      <tbody>
        {billings.map((billing) => (
          <TableRow
            key={billing.id}
            id={`billing-${billing.id}`}
            className={`text-sm ${
              location.state?.highlightBilling === billing.id ? "bg-amber-50" : ""
            }`}
          >
            <TableColumn>{billing.name}</TableColumn>
            <TableColumn>{billing.address.country}</TableColumn>
            <TableColumn>{billing.address.city}</TableColumn>
            <TableColumn>{billing.vat}</TableColumn>
            <TableColumn>{billing.reference}</TableColumn>
            <TableColumn minimal>
              <Link
                preventScrollReset
                to={`billings/${billing.id}`}
                className="bg-slate-300 hover:bg-slate-400 text-slate-700 text-xs font-medium p-2 rounded shadow-md"
              >
                <PencilIcon className="inline-block w-4 h-4" />
              </Link>
            </TableColumn>
          </TableRow>
        ))}

        {billings.length === 0 && (
          <tr className="bg-white">
            <td colSpan={5} className="px-4 py-3 italic rounded-bl-md text-sm">
              This company does not have any billing details.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
