import { api } from "@/api/base";

export async function invalidateCurrentAuthToken() {
  return api.post(`api/v2/auth/logout`);
}

export interface RefreshedAuthTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export async function fetchRefreshedAuthToken(refreshToken: string) {
  return api
    .post(`oauth/token`, {
      json: {
        refresh_token: refreshToken,
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
        grant_type: "refresh_token",
      },
    })
    .json<RefreshedAuthTokenResponse>();
}
