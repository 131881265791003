import { Entity } from "@jobilla/entity";

export enum PipelineStageType {
  Entry = "entry",
  Hire = "hire",
  Rejection = "rejection",
  Call = "call",
  Review = "review",
  Interview = "interview",
}

export class PipelineStage extends Entity {
  id!: string;
  name!: string;
  type!: PipelineStageType | null;
  position!: number;
}
