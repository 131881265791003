import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

export type DangerLinkProps = LinkProps & {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export function DangerLink({ children, size = "md", ...props }: DangerLinkProps) {
  const sizeBasedStyles = {
    xs: "py-1 px-2 text-xs",
    sm: "py-1.5 px-3 text-sm",
    "sm-square": "py-1.5 px-1.5 text-sm",
    md: "py-2 px-4 text-sm",
    lg: "py-3 px-6 text-sm",
    xl: "py-4 px-8",
  };

  const baseClasses = [
    "inline-flex items-center justify-center",
    "bg-rose-600 text-white font-medium rounded-lg leading-none tracking-wide",
    "whitespace-nowrap",
    sizeBasedStyles[size],
    "transition duration-200",
  ];

  return (
    <Link
      {...props}
      className={clsx(baseClasses, "hover:bg-gradient-to-bl hover:from-rose-600 hover:to-rose-700")}
    >
      {children}
    </Link>
  );
}
