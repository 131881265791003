import { useCallback } from "react";
import {
  Form,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Dialog } from "@/components/dialog";
import TextField from "@/components/forms/text-field";
import SelectBox from "@/components/forms/select-box";
import SubmitButton from "@/components/submit-button";
import SecondaryButton from "@/components/secondary-button";
import { teamsAndRoles } from "@/helpers/teams-and-roles";
import { localesAndLabels } from "@/helpers/language-and-locale";

export { action } from "./action";

export function loader({ request }: LoaderFunctionArgs) {
  const selectedTeam = new URL(request.url).searchParams.get("team") ?? undefined;

  return {
    roles:
      // Either the roles of the selected team, ...
      teamsAndRoles.find((team) => team.id === selectedTeam)?.roles ??
      // Otherwise all roles, with the team name attached for clarity.
      teamsAndRoles
        .filter((team) => team.id !== null)
        .map((team) =>
          team.roles.map((role) => ({
            ...role,
            userTitle: `${team.name} — ${role.userTitle}`,
          })),
        )
        .flat(),
  };
}

export default function UnitPageInviteUserDialog() {
  const { roles } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const transition = useNavigation();

  const isFormDisabled = transition.state !== "idle";

  const goBackToUnitPage = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <Dialog title="Invite a new user" isOpen onClose={goBackToUnitPage}>
      <Form method="post" noValidate>
        <fieldset disabled={isFormDisabled} className="flex flex-col gap-6">
          <TextField
            name="name"
            label="Name"
            required
            description="The recipient can change this later from their profile page."
          />

          <TextField
            name="email"
            label="Email"
            required
            description={
              <>
                The owner of this email will receive this invitation, which means they will obtain
                access rights to Jobilla&apos;s admin system. <b>Triple check</b> this email, to
                make sure the right person gets the invitation.
              </>
            }
          />

          <SelectBox name="role" label="Role">
            {roles.map((role) => (
              <option key={role.id} value={role.id ?? ""}>
                {role.userTitle}
              </option>
            ))}
          </SelectBox>

          <SelectBox name="language" label="Language">
            {localesAndLabels.map((locale) => (
              <option key={locale.code} value={locale.code}>
                {locale.label}
              </option>
            ))}
          </SelectBox>

          <div className="mt-6 flex items-center justify-center gap-4">
            <SubmitButton
              size="lg"
              label="Send invitation"
              labelWhenSubmitting="Sending invitation..."
            />

            <SecondaryButton type="button" onClick={goBackToUnitPage} size="lg">
              Cancel
            </SecondaryButton>
          </div>
        </fieldset>
      </Form>
    </Dialog>
  );
}
