import {
  ActionFunctionArgs,
  Form,
  isRouteErrorResponse,
  json,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from "react-router-dom";
import { useCallback } from "react";
import type { DialogProps } from "@/components/dialog";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import { fetchHubspotCompany, linkCompany } from "@/api/company-hubspot";
import { loader as companyDetailsLoader } from "@/routes/companies.$company/route";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.confirm);

  const hubspotCompany = await fetchHubspotCompany(params.confirm);

  if (hubspotCompany.isAssociated) {
    throw json(
      {
        error: "This company is already associated with a JOM company",
      },
      422,
    );
  }

  return {
    hubspotCompany,
  };
}

export async function action({ params }: ActionFunctionArgs) {
  assertParameterExists(params.company);
  assertParameterExists(params.confirm);

  const hubspotCompanyId = params.confirm;
  const jomCompanyId = params.company;

  try {
    await linkCompany(jomCompanyId, hubspotCompanyId);
    return redirect(`/companies/${jomCompanyId}`);
  } catch (e) {
    return { status: 500 };
  }
}

export default function HubspotDialogConfirmLink(
  props: Omit<DialogProps, "title" | "children" | "description">,
) {
  const { company } = useRouteLoaderData("company-details") as Awaited<
    ReturnType<typeof companyDetailsLoader>
  >;
  const { hubspotCompany } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const transition = useNavigation();
  const navigate = useNavigate();
  const params = useParams();

  const actionResult = useActionData() as Awaited<ReturnType<typeof action>>;
  const hasErrors = typeof actionResult?.status === "number" && actionResult.status !== 201;

  // Linking is successful when the action result was a redirect.
  const isSuccessfullyLinked =
    transition.location?.pathname && transition.location.pathname !== location.pathname;

  const isSubmitting = transition.state === "submitting";

  const close = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/companies/${params.company}`);
    }
  }, []);

  return (
    <Dialog {...props} isOpen onClose={close} title="Link to Hubspot">
      <Form method="post">
        <input type="hidden" name="jom_company_id" value={company.id} />
        <input type="hidden" name="hubspot_company_id" value={hubspotCompany.id} />
        <input type="hidden" name="link" value="1" />

        <div className="text-stone-800 space-y-8">
          <p>Ready to link. Make sure these companies correspond to each other.</p>

          <div className="space-y-4 text-center">
            <div>
              <p className="font-bold">{company.name}</p>
              <p>({company.units[0].name})</p>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="inline-block w-12 h-12"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6.904 5.282a1 1 0 0 1 1.327-.077l.087.077.373.374a2 2 0 0 0 .374.293l-2.3 2.301a1.965 1.965 0 0 0 1.003 3.316l1.432.287a2 2 0 0 0 1.286-.173l1.877-.938.468.467a2 2 0 0 0 2.046.484l.95-.317 3.098 3.099a1 1 0 0 1-1.415 1.414l-1.06-1.06a.5.5 0 0 0-.765.637l.058.07 1.06 1.06a1 1 0 0 1-1.327 1.492l-.088-.077-1.06-1.061a.5.5 0 0 0-.637-.058l-.07.058a.5.5 0 0 0-.058.638l.058.069 1.061 1.06a1 1 0 0 1-.848 1.698 1.997 1.997 0 0 0-2.106-2.28 1.992 1.992 0 0 0-.582-1.539 1.99 1.99 0 0 0-1.025-.548 2 2 0 0 0-2.67-2.67 2 2 0 0 0-3.376-1.025l-.352.353-.205-.612a2 2 0 0 0-.358-.644l-.126-.137-.373-.374a1 1 0 0 1-.078-1.327l.078-.087 4.243-4.243zm3.535 14.85a1 1 0 0 1 1.492 1.327l-.077.087a1 1 0 0 1-1.415-1.414zm1.036-13.764 2.486.497a2 2 0 0 0 1.807-.547l1.035-1.036 4.243 4.243-1.121 1.121-.12.13a2 2 0 0 0-.458 1.109l-.009.175-2.098-2.098a2 2 0 0 0-2.046-.483l-.949.316-.468-.467a2 2 0 0 0-2.308-.375l-1.877.938-1.367-.273 3.25-3.25zM8.318 18.01a1 1 0 0 1 1.492 1.327l-.078.088-.707.707a1 1 0 0 1-1.414-1.414l.707-.708zm-1.414-2.828a1 1 0 0 1 1.492 1.327l-.078.087-1.414 1.414a1 1 0 0 1-1.414-1.414l1.414-1.414zm-2.829-1.414a1 1 0 0 1 1.492 1.327l-.077.087-.708.707a1 1 0 1 1-1.414-1.414l.707-.707z"
              />
            </svg>

            <div>
              <p className="font-bold">{hubspotCompany.name}</p>
              <p>(HubSpot ID #{hubspotCompany.id})</p>
            </div>
          </div>

          {hasErrors && <ErrorBlock>An unexpected error occurred. Please try again.</ErrorBlock>}

          <div className="mt-8 flex items-center justify-center space-x-4">
            <SubmitButton
              label="Link Company"
              labelWhenSubmitting={isSuccessfullyLinked ? "✅ Done!" : "Linking..."}
              isSubmitting={isSuccessfullyLinked || isSubmitting}
            />

            <button
              type="button"
              onClick={close}
              className="bg-blue-100 text-blue-600 text-sm font-medium py-3 px-6 rounded-lg"
            >
              Cancel
            </button>
          </div>
        </div>
      </Form>
    </Dialog>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <Dialog isOpen title="Link to Hubspot">
      <div className="text-stone-800 space-y-8">
        <p>{isRouteErrorResponse(error) ? error.data.error : "Something went wrong"}</p>
      </div>
    </Dialog>
  );
}
