import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { OrderUser } from "@/entities/order-user";
import { User } from "@/entities/user";
import { Table, TableColumn, TableRow } from "@/components/table";
import { MoneyDisplay } from "@/components/money-display";

export function OrderSellers() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <Table columns={[{ label: "Seller" }, { label: "Share", minimal: true, rightAligned: true }]}>
      <tbody>
        {order.users.map((user, index) =>
          user.userId ? <SellerRow key={`${user.userId}-${index}`} user={user} /> : undefined,
        )}

        {order.users.length === 0 && (
          <tr>
            <td colSpan={2} className="px-4 py-3 italic">
              This order does not have any sellers.
            </td>
          </tr>
        )}
      </tbody>

      {order.users.length && (
        <tfoot>
          <tr className="border-t">
            <td className="p-4 text-right font-bold">Total</td>
            <td className="p-4 text-right">
              <MoneyDisplay amount={order.itemsTotal} />
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}

function SellerRow({ user }: { user: OrderUser }) {
  const { jobillaUsers } = useLoaderData() as OrderDetailsData;

  return (
    <TableRow>
      <TableColumn>
        <Suspense
          fallback={
            <span>
              #{user.userId} <em>(Loading user data)</em>
            </span>
          }
        >
          <Await resolve={jobillaUsers}>
            {(resolvedJobillaUsers: User[]) => (
              <span>
                {resolvedJobillaUsers.find((jobillaUser) => jobillaUser.id === user.userId)?.name}
              </span>
            )}
          </Await>
        </Suspense>
      </TableColumn>

      <TableColumn rightAligned numeric>
        <MoneyDisplay amount={user.amount} />
      </TableColumn>
    </TableRow>
  );
}
