import { useRouteError } from "react-router-dom";
import { HTTPError } from "ky";
import Error404 from "@/components/error-pages/404";
import ErrorGeneric from "@/components/error-pages/generic";

export function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof HTTPError && error.response.status === 404) {
    return <Error404 title="No such order item" />;
  }

  return <ErrorGeneric error={error} />;
}
