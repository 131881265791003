import { createContext, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ChevronDownIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { v4 as uuid } from "uuid";
import { Table, TableColumn, TableRow } from "@/components/table";
import { OrdersNewPageLoaderData } from "@/routes/orders.create/route";
import SelectBox from "@/components/forms/select-box";
import SecondaryButton from "@/components/secondary-button";
import { Money } from "@/entities/money";
import { Currency } from "@/entities/currency";
import TextField from "@/components/forms/text-field";
import TertiaryButton from "@/components/tertiary-button";
import * as DropdownMenu from "@/components/dropdown-menu";
import { Product } from "@/entities/product";
import { useActionErrors } from "@/hooks/useActionErrors";
import { MoneyDisplay } from "@/components/money-display";
import { bundles, ProductBundle } from "./bundles";

export type OrderItemLine = {
  product: Product;
  price: number;
  id: string;
};

export type OrderItemsContextValue = {
  lines: OrderItemLine[];
  setLines: (value: OrderItemLine[]) => void;
  total: Money;
};

type BundleItemWithProduct = ProductBundle["items"][number] & { product?: Product };

export function useOrderItemsContextValues() {
  const [lines, setLines] = useState<OrderItemLine[]>([]);
  const [total, setTotal] = useState(new Money());

  useEffect(() => {
    setTotal(
      new Money(
        !lines.length ? 0 : lines.reduce((total, line) => total + line.price * 100, 0),
        Currency.EUR,
      ),
    );
  }, [lines]);

  return { lines, setLines, total };
}

export const OrderItemsContext = createContext<OrderItemsContextValue>({
  lines: [],
  setLines: () => {},
  total: new Money(),
});

export function OrderItems() {
  const errors = useActionErrors();
  const { products } = useLoaderData() as OrdersNewPageLoaderData;
  const { lines, setLines, total } = useContext(OrderItemsContext);

  const getDefaultLineData = useCallback(() => {
    const product = products.find((p) => p.isDigitalHeadhunting) ?? products[0];

    return {
      price: product.price.amount / 100,
      id: uuid(),
      product,
    };
  }, [products]);

  const addLine = useCallback(() => {
    setLines([...lines, { ...getDefaultLineData() }]);
  }, [lines]);

  const removeLine = useCallback(
    (lineToRemove: OrderItemLine) => {
      setLines([...lines.filter((line) => line !== lineToRemove)]);
    },
    [lines],
  );

  const addBundle = useCallback(
    (bundle: (typeof bundles)[number]) => {
      const linesFromBundle = bundle.items
        .map(
          (item): BundleItemWithProduct => ({
            ...item,
            product: products.find((p) => p.sku === item.productCode),
          }),
        )
        .filter((item): item is Required<BundleItemWithProduct> => item.product !== undefined)
        .map((item) => ({
          price: item.product.price.amount / 100,
          product: item.product,
          id: uuid(),
        }));

      setLines([...lines, ...linesFromBundle]);
    },
    [lines, setLines],
  );

  return (
    <div>
      <Inputs data={lines} />
      <Table
        columns={[
          { label: "Product" },
          { label: "Price", rightAligned: true },
          { label: "", minimal: true },
        ]}
      >
        <tbody>
          {lines.map((line, index) => (
            <TableRow key={line.id}>
              <TableColumn>
                <SelectBox
                  name={`order_items[${index}].product_id`}
                  value={line.product.id}
                  onChange={(event) => {
                    // It's not possible for this `.find` to return `undefined`
                    // because the options are generated from the same list.
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    line.product = products.find((p) => p.id === event.currentTarget.value)!;
                    line.price = line.product.price.amount / 100;
                    setLines([...lines]);
                  }}
                  errors={errors?.order_items?.[index]?.product_id}
                >
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.title}
                    </option>
                  ))}
                </SelectBox>
              </TableColumn>

              <TableColumn rightAligned>
                <TextField
                  name={`order_items[${index}].price`}
                  inputMode="numeric"
                  pattern="[0-9]+\.[0-9]*"
                  defaultValue={line.price.toString()}
                  value={line.price.toString()}
                  onInput={(event) => {
                    line.price = parseFloat(event.currentTarget.value.replace(",", ".")) || 0;
                    setLines([...lines]);
                  }}
                  errors={errors?.order_items?.[index]?.price}
                />
              </TableColumn>

              <TableColumn>
                <TertiaryButton type="button" onClick={() => removeLine(line)}>
                  <XMarkIcon className="w-4 h-4" />
                </TertiaryButton>
              </TableColumn>
            </TableRow>
          ))}
        </tbody>

        <tfoot>
          <TableRow>
            <TableColumn colSpan={3}>
              {errors?.order_items?.length && typeof errors.order_items[0] === "string"
                ? errors.order_items.map((errorText) => (
                    <p className="text-sm mb-2 text-red-700 font-medium" key={errorText}>
                      {errorText}
                    </p>
                  ))
                : null}

              <div className="flex items-center gap-2">
                <SecondaryButton type="button" onClick={() => addLine()}>
                  <PlusIcon className="w-4 h-4 mr-2" />
                  <span>Add product</span>
                </SecondaryButton>

                <DropdownMenu.Root>
                  <DropdownMenu.Trigger className="inline-flex items-center gap-2 bg-white text-purple-800 ring-1 ring-purple-700 rounded py-1.5 px-4 hover:bg-purple-100 transition duration-150">
                    <PlusIcon className="w-4 h-4" />
                    <span>Add bundle</span>
                    <div className="-my-1.5 mx-1 self-stretch border-l border-purple-700" />
                    <ChevronDownIcon className="w-4 h-4" />
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Content>
                    {bundles.map((bundle) => (
                      <DropdownMenu.ButtonItem
                        key={bundle.name}
                        label={bundle.name}
                        onClick={() => addBundle(bundle)}
                      />
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </TableColumn>
          </TableRow>

          <TableRow>
            <TableColumn rightAligned>
              <span className="font-medium">Totals</span>
            </TableColumn>

            <TableColumn rightAligned numeric>
              <span>
                <MoneyDisplay amount={total} />
              </span>
            </TableColumn>

            <TableColumn />
          </TableRow>
        </tfoot>
      </Table>
    </div>
  );
}

function Inputs({ data }: { data: OrderItemLine[] }) {
  return (
    <>
      {data.map((line, index) => (
        <Fragment key={line.id}>
          <input type="hidden" name={`order_items[${index}].id`} value={line.id} />
        </Fragment>
      ))}
    </>
  );
}
