import { Entity, Type } from "@jobilla/entity";
import { BoosterToken } from "@/entities/booster-token";
import { ContactPerson } from "@/entities/contact-person";
import { Token } from "@/entities/token";

export class CampaignContext extends Entity {
  id!: string;
  jobId!: number | null;
  jobName!: string;
  jobLocation!: string | null;
  orderItemId!: string;
  duration = 14;
  unitId!: string;
  mediaSource!: "customer_website" | "photo_bank" | "direct" | null;
  notes!: string | null;
  createdAt!: string;
  boosterType!: Exclude<Token["type"], "campaign"> | null;

  @Type(BoosterToken)
  public boosterToken?: BoosterToken;

  @Type(ContactPerson)
  public contactPerson?: ContactPerson;
}
