import {
  FormEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useId,
} from "react";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { Label } from "@/components/forms/label";
import { useActionErrors } from "@/hooks/useActionErrors";
import { mergeRefs } from "@/components/forms/merge-refs";
import RequiredAsterisk from "./required-asterisk";

export type TextFieldProps = {
  name: string;
  errors?: string[] | null;
  label?: ReactNode;
  description?: ReactNode;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  acceptDecimalNumbers?: boolean;
} & Exclude<InputHTMLAttributes<HTMLInputElement>, "type">;

export default function TextField({
  name,
  errors,
  label,
  description,
  className,
  inputRef,
  acceptDecimalNumbers = true,
  type,
  onKeyDown,
  onInput,
  ...props
}: TextFieldProps) {
  const inputId = props.id ?? useId();
  const actionErrors = useActionErrors();

  const { register, formState } = useFormContext() || { register: () => {} };

  const { ref, ...rest } = register(name, { value: props.defaultValue }) || {};

  const validationError = formState?.errors?.[name ?? ""]
    ? [formState?.errors?.[name ?? ""]?.message]
    : null;

  errors = errors ?? validationError ?? actionErrors?.[name ?? ""];

  const baseInputClasses = [
    "rounded-md border-0 ring-1 focus:ring-1 focus:ring-purple-500 focus:outline-0 transition duration-200 disabled:bg-stone-50 disabled:cursor-not-allowed disabled:text-stone-500",
    !className || className?.search(/\bw-/) === -1 ? "w-full" : "",
    errors?.length ? "ring-red-600 bg-red-50" : "ring-stone-300 focus:bg-purple-50",
  ];

  const handleKeyDownEvent = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (!acceptDecimalNumbers) {
        preventDecimalPoint(event);
      }

      onKeyDown?.(event);
    },
    [acceptDecimalNumbers, onKeyDown],
  );

  const handleInputEvent = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      if (!acceptDecimalNumbers) {
        removeDecimalPart(event);
      }

      onInput?.(event);
    },
    [acceptDecimalNumbers, onInput],
  );

  return (
    <div className="flex flex-col items-start">
      {label && (
        <Label htmlFor={inputId}>
          {label} {props.required && <RequiredAsterisk />}
        </Label>
      )}

      <input
        {...rest}
        ref={inputRef ? mergeRefs(inputRef, ref) : ref}
        name={name}
        id={inputId}
        type={type ?? "text"}
        className={clsx(baseInputClasses, className)}
        onKeyDown={handleKeyDownEvent}
        onInput={handleInputEvent}
        {...props}
      />

      {errors?.map((errorText) => (
        <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      ))}

      {description && <p className="text-sm mt-2 text-stone-500">{description}</p>}
    </div>
  );
}

function preventDecimalPoint(event: KeyboardEvent<HTMLInputElement>) {
  if (
    (event.key === "." || event.key === ",") &&
    !event.metaKey &&
    !event.ctrlKey &&
    !event.altKey &&
    !event.shiftKey
  ) {
    event.preventDefault();
  }
}

function removeDecimalPart(event: FormEvent<HTMLInputElement>) {
  const input = event.currentTarget;
  input.value = input.value.replace(/([.,]+).*$/g, "");
}
