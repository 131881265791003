import { Entity, Type } from "@jobilla/entity";
import { capitalize } from "lodash";
import { OrderItemSetupStatus } from "./order-item-setup-status";
import { OrderItemStatusHistory } from "./order-item-status-history";
import { OrderItemsTransferDetails } from "./order-item-transfer-details";
import { Money } from "./money";
import { CampaignContext } from "./campaign-context";
import { Order } from "./order";
import { Product } from "./product";
import { OrderItemStatus } from "./order-item-status";

export class OrderItem extends Entity {
  id!: string;
  uuid!: string;
  numericId!: number;
  orderId!: string;
  status: OrderItemStatus = OrderItemStatus.New;
  productId!: string;
  title!: string;
  createdAt!: string;
  isSent?: string;

  @Type(Product)
  product?: Product;

  @Type(Money)
  amount!: Money;

  @Type(() => Order)
  order?: Order;

  @Type(OrderItemStatusHistory)
  public historicStatuses!: OrderItemStatusHistory[];

  @Type(CampaignContext)
  campaignContext!: CampaignContext | null;

  @Type(OrderItemsTransferDetails)
  sentTo!: OrderItemsTransferDetails;
  
  @Type(OrderItemsTransferDetails)
  receivedFrom!: OrderItemsTransferDetails;

  get isDistributed(): boolean {
    return this.status === OrderItemStatus.Transferred;
  }

  get setupStatus(): OrderItemSetupStatus | null {
    if (this.status === "new" && !this.campaignContext) {
      return OrderItemSetupStatus.Available;
    }

    const OrderItemResourceEntries = Object.entries(OrderItemStatus) as [
      keyof typeof OrderItemStatus,
      OrderItemStatus,
    ][];

    const statusKey =
      OrderItemResourceEntries.find(([, value]) => this.status === value)?.[0] ?? null;

    if (!statusKey || statusKey === "Transferred") {
      return null;
    }

    return OrderItemSetupStatus[statusKey];
  }

  get tooltip(): string {
    return `${this.title} - ${capitalize(this.status).replace(/_/g, " ")}`;
  }

  get hasContext() {
    return this.campaignContext && this.campaignContext.id;
  }

  get canBeEdited() {
    return [OrderItemStatus.New, OrderItemStatus.Setup].includes(this.status);
  }
}
