import { LoaderFunctionArgs, ShouldRevalidateFunction } from "react-router-dom";
import { fetchCompanyInventory } from "@/api/inventory-summary";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";
import { fetchCompany, fetchUsersByTeam } from "@/api/company";
import { FailedOptionalResult, optional } from "@/helpers/loader-optional";
import { fetchCompanyOrders } from "@/api/company-order";
import { fetchCompanyTokens } from "@/api/consumable-token";
import { fetchCompanyBillings } from "@/api/billing";
import { fetchCompanyAccountManager } from "@/api/company-account-manager";
import { fetchCompanyNotes } from "@/api/company-notes";
import { fetchAllUnits } from "@/api/unit";
import { fetchOrganizationStructure } from "@/api/organization-structure";
import { fetchUserPermissionFor } from "@/api/permissions";
import {
  preventRevalidationForFailedSubmissions,
  preventRevalidationForModalNavigation,
} from "@/helpers/revalidation-fns";
import { User } from "@/entities/user";

// Exporting loader's types so that it can be referenced in subcomponents.
export type CompanyDetailsData = Awaited<ReturnType<typeof loader>>;

export const shouldRevalidate: ShouldRevalidateFunction = (params) => {
  return (
    preventRevalidationForFailedSubmissions(params) ??
    preventRevalidationForModalNavigation(params) ??
    params.defaultShouldRevalidate
  );
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterIsNumeric(params.company);

  const [
    company,
    orders,
    tokens,
    inventorySummary,
    billings,
    accountManager,
    notes,
    businessUnits,
    organizationStructure,
    canUserViewCompany,
  ] = await Promise.all([
    fetchCompany(Number(params.company)),
    optional(fetchCompanyOrders(params.company, { perPage: 10 })),
    optional(fetchCompanyTokens(Number(params.company))),
    optional(fetchCompanyInventory(Number(params.company))),
    optional(fetchCompanyBillings(Number(params.company))),
    optional(fetchCompanyAccountManager(Number(params.company))),
    optional(fetchCompanyNotes(Number(params.company))),
    optional(fetchAllUnits()),
    optional(fetchOrganizationStructure(Number(params.company))),
    optional(fetchUserPermissionFor("view", "company", params.company)),
  ]);

  let salesUsers: User[] | undefined = [];

  if (!(businessUnits instanceof FailedOptionalResult)) {
    const companyUnit = company.companyUnit?.id;

    if (!companyUnit) {
      salesUsers = await fetchUsersByTeam(
        "sales",
        businessUnits.find((unit) => unit.parentUnitId === null)?.id,
      );
    } else {
      salesUsers = await fetchUsersByTeam("sales", companyUnit);

      const parentUnit = businessUnits.find((unit) => unit.id === companyUnit)?.parentUnitId;

      if (parentUnit) {
        salesUsers = [...salesUsers, ...(await fetchUsersByTeam("sales", parentUnit))];
      }
    }
  }

  return {
    company,
    orders,
    tokens,
    inventorySummary,
    billings,
    accountManager,
    notes,
    salesUsers,
    businessUnits,
    organizationStructure,
    canUserViewCompany,
  };
}
