import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useRootLoaderData } from "@/root";
import MenuSection from "@/components/sidebar/menu/section";

export function OrderPipelines() {
  const { products } = useRootLoaderData();

  const defaultProduct = useMemo(
    // Default product to have in the main link is the first DH product if it exists, otherwise the
    // first product in the list.
    () => products.find((product) => product.sku === "DH") ?? products[0],
    [products],
  );

  const [searchParams] = useSearchParams();

  // If we don't have any products, just don't render pipelines, because there is nothing to show.
  if (products.length === 0) {
    return null;
  }

  return (
    <>
      <MenuSection.Item href={`/order-pipeline/${defaultProduct.id}`}>
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="presentation"
            className="inline-block mr-2 text-blue-700 w-4 h-4 fill-current"
          >
            <path
              d="M18 2a1 1 0 01.993.883L19 3v16a3 3 0 01-2.824 2.995L16 22h-1v-2a2 2 0 00-1.85-1.995L13 18H5V5a3 3 0 012.824-2.995L8 2h10zm-5 17a1 1 0 011 1v2H4a2 2 0 01-2-2v-1h11zm1.5-7.5h-5l-.117.007A1 1 0 009.5 13.5h5l.117-.007A1 1 0 0014.5 11.5zm0-4h-5l-.117.007A1 1 0 009.5 9.5h5l.117-.007A1 1 0 0014.5 7.5zM20 2a2 2 0 012 2v3h-2V2z"
              fillRule="evenodd"
            />
          </svg>
          Order Pipelines
        </div>
      </MenuSection.Item>

      {products.map((product) => (
        <MenuSection.SubItem
          key={product.id}
          href={
            searchParams
              ? `/order-pipeline/${product.id}?${searchParams}`
              : `/order-pipeline/${product.id}`
        }>
          {product.title}
        </MenuSection.SubItem>
      ))}
    </>
  );
}
