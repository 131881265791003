import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { RootLoaderData } from "@/root";

export function Posthog() {
  if (!isPosthogEnabled()) {
    return null;
  }

  const { permissions, units, user } = useRouteLoaderData("root") as RootLoaderData;
  const posthog = usePostHog();
  const { pathname } = useLocation();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [pathname]);

  useEffect(() => {
    const firstUnitId: keyof typeof permissions.units = Object.keys(permissions.units)?.[0];

    posthog.identify(user.id.toString(), {
      jobillaRole: firstUnitId ? permissions.units[firstUnitId][0] : null,
      jobillaUnit: units.find((u) => u.id === firstUnitId)?.name ?? null,
      language: user.language,
    });
  }, [user, posthog]);

  return null;
}

export function isPosthogEnabled() {
  return import.meta.env.PROD;
}

export function ConditionalPostHogProvider({ children }: { children: ReactNode }) {
  if (!isPosthogEnabled()) {
    return <>{children}</>;
  }

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={{
        api_host: import.meta.env.VITE_POSTHOG_HOST,
      }}
    >
      {children}
    </PostHogProvider>
  );
}
