import { HTMLProps, ReactNode } from "react";

export function Label({
  children,
  ...props
}: { children: ReactNode } & HTMLProps<HTMLLabelElement>) {
  return (
    <label className="inline-block font-bold mb-1" {...props}>
      {children}
    </label>
  );
}
