import type { PartialPropsJson, PropsJson } from "@jobilla/entity";
import { EntityBuilder } from "@jobilla/entity";
import { ResponsePromise } from "ky";
import { Company } from "@/entities/company";
import { User } from "@/entities/user";
import { api, Paginated } from "@/api/base";
import { Billing } from "@/entities/billing";
import { BrandingSettings } from "@/entities/branding-settings";

export type CompanyFetchArgs = {
  page?: number;
  name?: string;
};

export async function fetchCompanies(args: CompanyFetchArgs = {}): Promise<Paginated<Company[]>> {
  const response = await api.get("api/v2/companies", {
    searchParams: {
      page: args.page ?? 1,
      "filters[name]": args.name || "",
    },
  });

  const json = await response.json<Paginated<PartialPropsJson<Company>[]>>();

  return {
    ...json,
    data: EntityBuilder.buildMany(Company, json.data),
  };
}

export async function fetchCompany(id: number): Promise<Company> {
  const json = await api.get(`v1/companies/${id}`).json<{ data: PartialPropsJson<Company> }>();

  return EntityBuilder.buildOne(Company, json.data);
}

export async function fetchCompanyBrandingSettings(id: number) {
  const json = await api
    .get(`api/v2/company/${id}/settings/branding`)
    .json<{ data: PropsJson<BrandingSettings> }>();

  return EntityBuilder.buildOne(BrandingSettings, json.data);
}

export async function createCompany(data: PartialPropsJson<Company>) {
  const json = await api
    .post(`api/v2/companies`, {
      json: data,
    })
    .json<{ data: PartialPropsJson<Company> }>();

  return EntityBuilder.buildOne(Company, json.data);
}

export async function updateCompany(
  id: number,
  data: PartialPropsJson<Company>,
): Promise<ResponsePromise> {
  return api.put(`v1/companies/${id}`, {
    json: data,
  });
}

export async function fetchCompanyUsers(id: number): Promise<User[]> {
  const json = await api
    .get(`api/v2/company/${id}/users`)
    .json<{ data: PartialPropsJson<User>[] }>();

  return EntityBuilder.buildMany(User, json.data);
}

export async function fetchUsersByTeam(team: string, unit?: string): Promise<User[]> {
  const response = await api.get(`v1/users/jobilla`, {
    searchParams: {
      team,
      unit: unit ?? "",
    },
  });

  const json = await response.json<{ data: User[] }>();

  return EntityBuilder.buildMany(User, json.data);
}

export async function lookupCompanyDataByVAT(vat: string) {
  const response = await api.get(`v1/companies/lookup/${vat}`).json<{ data: PropsJson<Billing> }>();

  return EntityBuilder.buildOne(Billing, response.data);
}

export async function fetchBillingCountries() {
  const response = await api.get(`v1/companies/countries`).json<{ countries: string[] }>();

  return response.countries;
}
