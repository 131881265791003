import {
  defer,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useLocation,
  useNavigation,
} from "react-router-dom";
import { subMonths } from "date-fns";
import { omitBy } from "lodash";
import { fetchOrders } from "@/api/order";
import OrdersListTable from "@/routes/orders/orders-list-table";
import OrdersIndexFilters from "@/routes/orders/orders-index-filters";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { fetchBillingCountries } from "@/api/company";
import { Order } from "@/entities/order";
import { User } from "@/entities/user";
import { Paginated } from "@/api/base";
import { getAuthTokenData } from "@/services/auth";
import * as Page from "@/components/page";

export type OrderIndexData = {
  orders: Paginated<Order[]>;
  countries: Promise<string[]>;
  jobillaUsers?: Promise<User[]>;
};

function assignInitialSearchParams(searchParams: URLSearchParams) {
  const newSearchParams = new URLSearchParams(searchParams);

  if (!searchParams.has("seller")) {
    newSearchParams.set("seller", getAuthTokenData().user.id.toString());
  }

  if (!searchParams.has("date_from")) {
    newSearchParams.set("date_from", subMonths(new Date(), 6).toISOString().split("T")[0]);
  }

  if (!searchParams.has("date_to")) {
    newSearchParams.set("date_to", new Date().toISOString().split("T")[0]);
  }

  const differences = omitBy(
    Object.fromEntries(newSearchParams),
    (value, key) => searchParams.get(key) === value,
  );

  if (Object.keys(differences).length > 0) {
    throw redirect(`/orders?${newSearchParams.toString()}`);
  }
}

export async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  assignInitialSearchParams(searchParams);

  const orders = await fetchOrders({
    invoice_status: searchParams.get("invoice_status") ?? undefined,
    per_page: 25,
    current_page: Number(searchParams.get("page") ?? 1),
    country: searchParams.get("country") ?? undefined,
    seller: searchParams.get("seller") ?? undefined,
    from: searchParams.get("date_from") ?? "",
    to: searchParams.get("date_to") ?? "",
  });

  return defer({
    orders,
    jobillaUsers: fetchJobillaUsers(),
    countries: fetchBillingCountries(),
  });
}

export default function OrdersIndexPage() {
  const location = useLocation();
  const transition = useNavigation();
  const { orders } = useLoaderData() as OrderIndexData;
  const isLoading =
    transition.state !== "idle" && transition.location?.pathname === location.pathname;

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Orders</Page.Title>
      </Page.Heading>

      <Page.Content>
        <OrdersIndexFilters />
        <OrdersListTable isLoading={isLoading} orders={orders} />
      </Page.Content>
    </Page.Root>
  );
}
