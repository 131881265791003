import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { useCallback } from "react";
import { Dialog } from "@/components/dialog";
import { Billing } from "@/entities/billing";
import type { CompanyDetailsData } from "@/routes/companies.$company/loader";
import { BillingForm } from "@/components/billing-form";

export default function AddBillingDetailsDialog() {
  const { company } = useRouteLoaderData("company-details") as CompanyDetailsData;
  const navigate = useNavigate();

  const onBillingCreate = useCallback(
    (billing: Billing) => {
      navigate(`/companies/${company.id}`, {
        preventScrollReset: true,
        state: { higlightBilling: billing.id },
      });
    },
    [company, navigate],
  );

  const goBackToCompanyDetails = useCallback(() => {
    navigate(`/companies/${company.id}`, { preventScrollReset: true });
  }, [company]);

  return (
    <Dialog
      isOpen
      onClose={goBackToCompanyDetails}
      title="Add billing details"
      description="Provide information about the company's billing details, so that the invoice can be created correctly."
    >
      <BillingForm
        companyId={company.id}
        onSuccess={onBillingCreate}
        onCancel={goBackToCompanyDetails}
      />
    </Dialog>
  );
}
