import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { InvoiceNote } from "@/entities/invoice-note";
import { api } from "@/api/base";

export async function createInvoiceNote(
  invoiceId: string,
  data: Pick<PartialPropsJson<InvoiceNote>, "content">,
) {
  const response = await api
    .post(`v1/invoices/${invoiceId}/notes`, {
      json: {
        content: data.content,
      },
    })
    .json<{ data: PartialPropsJson<InvoiceNote> }>();

  return EntityBuilder.buildOne(InvoiceNote, response.data);
}

export async function deleteInvoiceNote(invoiceNoteId: InvoiceNote["id"]) {
  return api.delete(`v1/invoices/notes/${invoiceNoteId}`);
}
