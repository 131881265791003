import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { HTTPError } from "ky";
import SidebarBox from "@/routes/companies.$company/sidebar-box";
import SidebarBoxAction from "@/routes/companies.$company/sidebar-box-action";
import { User } from "@/entities/user";
import SelectBox from "@/components/forms/select-box";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { updateAccountManager } from "@/api/company-account-manager";
import { StyledAnchor } from "@/components/styled-anchor";
import { ErrorBlock } from "@/components/error-block";
import type { CompanyDetailsData } from "./loader";

export default function CompanyAccountManager() {
  const [isChangeOn, setIsChangeOn] = useState(false);
  const { accountManager } = useLoaderData() as CompanyDetailsData;

  const failedToLoad =
    accountManager instanceof FailedOptionalResult && accountManager.response?.status !== 404;
  const hasNoAccountManager =
    accountManager instanceof FailedOptionalResult && accountManager.response?.status === 404;

  const toggleChangeMode = useCallback(() => {
    setIsChangeOn(!isChangeOn);
  }, [isChangeOn, setIsChangeOn]);

  return (
    <SidebarBox
      title="Account Manager"
      action={
        failedToLoad ? null : (
          <SidebarBoxAction onClick={toggleChangeMode}>
            {isChangeOn ? "Cancel" : "Change"}
          </SidebarBoxAction>
        )
      }
    >
      {failedToLoad ? (
        <ErrorState />
      ) : (
        <CompanyAccountManagerContents
          user={hasNoAccountManager ? undefined : (accountManager as User)}
          isChangeOn={isChangeOn}
          setIsChangeOn={setIsChangeOn}
        />
      )}
    </SidebarBox>
  );
}

function CompanyAccountManagerContents({
  user,
  isChangeOn,
  setIsChangeOn,
}: {
  user?: User;
  isChangeOn: boolean;
  setIsChangeOn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  if (isChangeOn) {
    return <AccountManagerSelectionForm user={user} onUpdate={() => setIsChangeOn(false)} />;
  }

  if (!user) {
    return <NoAccountManager />;
  }

  return <AccountManagerDetails user={user} />;
}

function AccountManagerDetails({ user }: { user: User }) {
  return (
    <>
      <p>{user.name}</p>
      <p className="break-words">
        <StyledAnchor href={`mailto: ${user.email}`}>{user.email}</StyledAnchor>
      </p>
    </>
  );
}

function AccountManagerSelectionForm({ user, onUpdate }: { user?: User; onUpdate: () => void }) {
  const { salesUsers } = useLoaderData() as CompanyDetailsData;

  const usersArray: User[] = salesUsers.slice(0);

  if (user && !usersArray.find((salesUser) => salesUser.id === user.id)) {
    usersArray.push(user);
  }

  const actionResult = useActionData() as ActionResult;
  const transition = useNavigation();
  const [hasBeenSubmitting, setHasBeenSubmitting] = useState(false);

  const hasSuccessfullySubmitted = hasBeenSubmitting && actionResult?.status === 200;
  const hasUnexpectedError = hasBeenSubmitting && actionResult?.status === 500;

  const isSaving = transition.state !== "idle";

  useEffect(() => {
    if (transition.state === "idle" && hasBeenSubmitting && hasSuccessfullySubmitted) {
      onUpdate();
      setHasBeenSubmitting(false);
    }

    if (transition.state === "submitting") {
      setHasBeenSubmitting(true);
    }
  }, [transition.state, onUpdate, hasBeenSubmitting]);

  return (
    <Form method="put">
      <input type="hidden" name="_form" value="account-manager-change" />

      <fieldset disabled={isSaving} className="space-y-4">
        <SelectBox name="id" defaultValue={user?.id} errors={actionResult?.errors?.id}>
          {usersArray.map((salesUser: User) => (
            <option key={salesUser.id} value={salesUser.id}>
              {salesUser.name}
            </option>
          ))}
        </SelectBox>

        {hasUnexpectedError && (
          <ErrorBlock>Unexpected error occurred while saving the account manager.</ErrorBlock>
        )}

        <div className="flex justify-end">
          <button
            className={[
              "bg-blue-200 py-1.5 px-4 text-blue-700 text-sm font-medium rounded-lg leading-none tracking-wide transition duration-200 hover:bg-blue-100",
              isSaving ? "italic opacity-70" : "",
            ].join(" ")}
            type="submit"
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </fieldset>
    </Form>
  );
}

function NoAccountManager() {
  return <p className="text-sm text-slate-700">No key account manager assigned to this company.</p>;
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load account manager.</p>
    </div>
  );
}

type ActionResult = {
  status?: number;
  errors?: { [key: string]: string[] } | undefined;
};

export async function action_accountManager({
  inputData,
  params,
}: {
  inputData: Record<string, string>;
} & ActionFunctionArgs): Promise<ActionResult | null> {
  try {
    await updateAccountManager(Number(params.company), Number(inputData.id));
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: e.response.status,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }

  return {
    status: 200,
  };
}
