export interface ProductBundle {
  name: string;
  items: Array<{
    productCode: string;
  }>;
}

export const bundles: ProductBundle[] = [
  {
    name: "3x Digital Headhunting",
    items: [{ productCode: "DH" }, { productCode: "DH" }, { productCode: "DH" }],
  },
  {
    name: "Career Site Bundle",
    items: [
      { productCode: "EBP-SITE" },
      { productCode: "EBP-VID" },
      { productCode: "EBP-VID" },
      { productCode: "EBP-VID" },
      { productCode: "EBP-ART" },
      { productCode: "EBP-ART" },
      { productCode: "EBP-ART" },
      { productCode: "CM" },
    ],
  },
];
