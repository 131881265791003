import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { EntityBuilder } from "@jobilla/entity";
import { HTTPError } from "ky";
import { Billing } from "@/entities/billing";
import { processYupErrors } from "@/helpers/yup-errors";
import { addCompanyBilling } from "@/api/billing";
import { validationSchema } from "@/components/billing-form";

type ActionResult =
  | { status: 500 }
  | { status: 422; errors?: { [key: string]: string[] } }
  | { status: 200; billing: Billing };

export async function action({ request }: ActionFunctionArgs): Promise<ActionResult> {
  let input;

  try {
    input = await validationSchema.validate(
      Object.fromEntries((await request.formData()).entries()),
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    return { status: 500 };
  }

  const billingEntity = EntityBuilder.buildOne(Billing, {
    ...input,
    company_id: input.company_id,
    address: {
      country: input.address_country.toString(),
      city: input.address_city.toString(),
      postal_code: input.address_postal_code.toString(),
      line_1: input.address_line1.toString(),
    },
  });

  try {
    const billing = await addCompanyBilling(billingEntity);
    return {
      status: 200,
      billing,
    };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return { status: 500 };
  }
}
