import { ActionFunctionArgs, redirect } from "react-router-dom";
import { object, string, ValidationError } from "yup";
import { HTTPError } from "ky";
import { captureException } from "@sentry/react";
import { processYupErrors } from "@/helpers/yup-errors";
import { inviteUser } from "@/api/jobilla-users";
import { updateUserRole, updateUserUnit } from "@/api/user";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.unit);

  let input;

  try {
    input = await validate(request);
  } catch (error) {
    if (error instanceof ValidationError) {
      return { errors: processYupErrors(error), status: 422 };
    }

    throw error;
  }

  try {
    const user = await inviteUser(input);
    await updateUserUnit(user.id, params.unit);
    await updateUserRole(user.id, input.role);

    return redirect(`/units/${params.unit}`);
  } catch (error) {
    if (error instanceof HTTPError && error.response.status === 422) {
      return { errors: (await error.response.json()).errors, status: 422 };
    }

    captureException(error);

    return { status: 500 };
  }
}

async function validate(
  request: Request,
): Promise<{ name: string; email: string; role: string; language: string }> {
  const input = Object.fromEntries(await request.formData());

  return object({
    name: string().required("You must provide the invited person's name."),
    email: string()
      .email("Please enter a valid email address.")
      .required("You must provide the invited person's email address."),
    role: string().required("You must choose a role."),
    language: string().required("Language selection is required."),
  }).validate(input, { abortEarly: false });
}
