import { capitalize } from "lodash";
import { Link, LinkProps } from "react-router-dom";
import type { OrderItem } from "@/entities/order-item";
import { OrderItemSetupStatus } from "@/entities/order-item-setup-status";

type OrderItemProductionStatusPillProps = {
  item: OrderItem;
  linkProps?: Omit<LinkProps, "to">;
};

export function OrderItemProductionStatusPill({
  item,
  linkProps,
}: OrderItemProductionStatusPillProps) {
  const colors = {
    [OrderItemSetupStatus.New]:
      "bg-yellow-100 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800",
    [OrderItemSetupStatus.Active]:
      "bg-cyan-100 text-cyan-600 hover:bg-cyan-200 hover:text-cyan-700",
    [OrderItemSetupStatus.Setup]: "bg-cyan-100 text-cyan-600 hover:bg-cyan-200 hover:text-cyan-700",
    [OrderItemSetupStatus.Available]:
      "bg-zinc-200 text-zinc-600 hover:bg-zinc-300 hover:text-zinc-700",
    [OrderItemSetupStatus.Completed]:
      "bg-green-100 text-green-600 hover:bg-green-300 hover:text-green-700",
    [OrderItemSetupStatus.Cancelled]:
      "bg-zinc-100 text-zinc-600 hover:bg-zinc-300 hover:text-zinc-700",
    other: "bg-cyan-100 text-cyan-600 hover:bg-cyan-200 hover:text-cyan-700",
  }[item.setupStatus ?? "other"];

  return (
    <Link
      to={`/order-items/${item.id}/campaign-request`}
      {...linkProps}
      className={[
        "inline-block rounded-full text-xs py-0.5 px-2 whitespace-nowrap font-bold",
        colors,
      ].join(" ")}
    >
      {capitalize(item.setupStatus ?? "")}
    </Link>
  );
}
