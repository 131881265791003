import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { uniqBy } from "lodash";
import TextField from "@/components/forms/text-field";
import { Company } from "@/entities/company";
import { fetchCompanies } from "@/api/company";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { Label } from "@/components/forms/label";
import RequiredAsterisk from "@/components/forms/required-asterisk";
import { useActionErrors } from "@/hooks/useActionErrors";
import { PrimaryLink } from "@/components/primary-link";
import { TooltipBox } from "@/components/tooltip-box";

export function CompanySelectionField() {
  const errors = useActionErrors();

  const [isCompanySelectionDialogShown, setIsCompanySelectionDialogShown] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesToDisplay, setCompaniesToDisplay] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    setCompaniesToDisplay(
      companies.filter((company) => company.name.toLowerCase().includes(search.toLowerCase())),
    );
  }, [search, companies]);

  useEffect(() => {
    // Only fetch if the dialog is shown.
    if (!isCompanySelectionDialogShown) {
      return;
    }

    // Only fetch if there hasn't been a fetch yet.
    if (!isFirstRender) {
      return;
    }

    setIsFirstRender(true);

    fetchCompanies().then((companies) => {
      setCompanies(companies.data);
    });
  }, [isFirstRender, isCompanySelectionDialogShown]);

  const loadCompaniesDebounced = useDebouncedCallback(
    { wait: 300, trailing: true, leading: false },
    (params: { name: string }) => {
      fetchCompanies(params).then(({ data }) => {
        setCompanies(uniqBy([...companies, ...data], "id"));
      });
    },
    [companies],
  );

  useEffect(() => {
    if (!search.trim().length) {
      return;
    }

    loadCompaniesDebounced({ name: search });
  }, [search]);

  return (
    <div>
      <input type="hidden" name="company_id" value={selectedCompany?.id ?? ""} />

      <Label>
        Company <RequiredAsterisk />
      </Label>

      <div className="flex items-center gap-4">
        <Dialog.Root
          open={isCompanySelectionDialogShown}
          onOpenChange={setIsCompanySelectionDialogShown}
        >
          <Dialog.Trigger asChild>
            <button
              type="button"
              className="block w-full py-2 px-3 text-slate-700 text-left bg-white rounded-md ring-stone-300 border-none ring-1 focus:ring-1 focus:ring-purple-500 focus:outline-0 transition duration-200 disabled:bg-stone-50 disabled:cursor-not-allowed disabled:text-stone-500"
            >
              {selectedCompany ? selectedCompany.name : "Select a company"}
            </button>
          </Dialog.Trigger>

          <AnimatePresence initial={false}>
            {isCompanySelectionDialogShown && (
              <Dialog.Portal forceMount>
                <Dialog.Overlay asChild>
                  <motion.div
                    className="fixed inset-0 z-40 bg-black/30"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, when: "beforeChildren" }}
                  />
                </Dialog.Overlay>

                <Dialog.Content asChild>
                  <motion.div
                    className="fixed z-50 inset-0 overflow-x-hidden overflow-y-auto py-16 flex items-start justify-center"
                    // We use the style explicit here because otherwise Framer-motion adds the Auto value.
                    style={{ pointerEvents: "none" }}
                  >
                    <motion.div
                      className="bg-white rounded-xl shadow-xl min-w-[300px] w-full max-w-[700px]"
                      initial={{ scale: 0.9 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0.9, opacity: 0 }}
                      transition={{
                        duration: 0.25,
                        ease: [0.17, 0.67, 0.26, 1.64],
                      }}
                      style={{ pointerEvents: "auto" }}
                    >
                      <div className="flex items-start justify-between px-12 pt-12">
                        <Dialog.Title className=" text-2xl font-bold text-stone-800">
                          Select a company
                        </Dialog.Title>

                        <Dialog.Close>
                          <XMarkIcon className="w-5 h-5 text-stone-500" />
                        </Dialog.Close>
                      </div>

                      <Dialog.Description className="px-12 pb-4 text-sm text-stone-500">
                        Which company do you want to create an order for?
                      </Dialog.Description>

                      <div className="px-12 pt-6 pb-8">
                        <div className="-mx-3">
                          <TextField
                            name="search"
                            placeholder="Search for a company"
                            autoFocus
                            value={search}
                            onInput={(event) => setSearch(event.currentTarget.value)}
                          />
                        </div>

                        <ScrollArea.Root className="-mx-2 mt-4 h-64">
                          <ScrollArea.Viewport className="w-full h-full">
                            <div className="flex flex-col divide-y pr-8">
                              {companiesToDisplay.map((company) => (
                                <button
                                  key={company.id.toString()}
                                  type="button"
                                  className="block py-2 px-2 text-left truncate rounded hover:bg-indigo-600 hover:text-white"
                                  onClick={() => {
                                    setIsCompanySelectionDialogShown(false);
                                    setSelectedCompany(company);
                                  }}
                                >
                                  {company.name}
                                </button>
                              ))}
                            </div>
                          </ScrollArea.Viewport>

                          <ScrollArea.Scrollbar
                            className="w-2 bg-stone-50 rounded-full"
                            orientation="vertical"
                          >
                            <ScrollArea.Thumb className="bg-stone-300 rounded-full" />
                          </ScrollArea.Scrollbar>
                        </ScrollArea.Root>
                      </div>
                    </motion.div>
                  </motion.div>
                </Dialog.Content>
              </Dialog.Portal>
            )}
          </AnimatePresence>
        </Dialog.Root>

        <span>or</span>

        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild>
            <PrimaryLink to="/companies/create" target="_blank">
              <PlusIcon className="w-5 h-5" />
              <span>Create new</span>
            </PrimaryLink>
          </Tooltip.Trigger>

          <Tooltip.Portal>
            <Tooltip.Content sideOffset={5} align="center">
              <TooltipBox>Opens in new tab</TooltipBox>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </div>

      {errors?.company_id &&
        errors.company_id.map((errorText: string) => (
          <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
            {errorText}
          </p>
        ))}
    </div>
  );
}
