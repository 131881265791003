import { useLoaderData } from "react-router-dom";
import { orderBy } from "lodash";
import { parseISO } from "date-fns";
import { useMemo } from "react";
import * as DL from "@/components/definition-list";
import StyledLink from "@/components/styled-link";
import * as Timeline from "@/components/timeline";
import { statusToName } from "@/entities/invoice-status";
import Date, { DateDisplayType } from "@/components/date";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";
import { MoneyDisplay } from "@/components/money-display";

export function Details() {
  const { order, orderItem, invoice } = useLoaderData() as OrderItemDetailsData;

  const historicStatusesSortedByDate = useMemo(
    () =>
      orderBy(orderItem.historicStatuses, (status) => parseISO(status.createdAt).getTime(), "asc"),
    [orderItem.historicStatuses],
  );

  return (
    <DL.Root>
      <DL.Item label="Order">
        <StyledLink to={`/orders/${order.id}`}>#{order.numericId}</StyledLink>
      </DL.Item>

      <DL.Item label="Order notes">
        {order.notes ?? <span className="italic">No order notes recorded</span>}
      </DL.Item>

      <DL.Item label="Order item">#{orderItem.numericId}</DL.Item>
      <DL.Item label="Invoice">
        {invoice ? (
          <StyledLink to={`/invoices/${invoice.id}`}>#{invoice.numericId}</StyledLink>
        ) : (
          <span className="text-zinc-400">Could not find related invoice</span>
        )}
      </DL.Item>

      <DL.Item label="Product">{orderItem.product?.title ?? <em>Deleted product</em>}</DL.Item>
      {orderItem.product?.isDigitalHeadhunting && (
        <>
          <DL.Item label="Campaign price">
            <MoneyDisplay amount={orderItem.amount} />
          </DL.Item>

          <DL.Item label="Booster Price">
            {orderItem.campaignContext?.boosterToken?.orderItem.amount ? (
              <MoneyDisplay amount={orderItem.campaignContext.boosterToken.orderItem.amount} />
            ) : (
              <span className="italic">No booster attached</span>
            )}
          </DL.Item>
        </>
      )}

      <DL.Item label="Total price">
        <MoneyDisplay
          amount={orderItem.amount.add(orderItem.campaignContext?.boosterToken?.orderItem.amount)}
        />
      </DL.Item>

      <DL.Item label="Status history">
        <Timeline.Root>
          {historicStatusesSortedByDate.map((item, index) => (
            <Timeline.Item
              key={item.createdAt}
              icon={
                index === orderItem.historicStatuses.length - 1
                  ? Timeline.ItemIcon.BlueDot
                  : Timeline.ItemIcon.GrayDot
              }
              size={
                index === orderItem.historicStatuses.length - 1
                  ? Timeline.ItemIconSize.Big
                  : Timeline.ItemIconSize.Regular
              }
            >
              <Timeline.Label>{statusToName(item.status)}</Timeline.Label>
              <Timeline.Subtext>
                <Date date={item.createdAt} display={DateDisplayType.DATE} />
              </Timeline.Subtext>
            </Timeline.Item>
          ))}
        </Timeline.Root>
      </DL.Item>
    </DL.Root>
  );
}
