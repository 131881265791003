import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import { forwardRef } from "react";

export type PrimaryLinkProps = LinkProps & {
  size?: "sm" | "md" | "lg" | "xl";
};

export const PrimaryLink = forwardRef<HTMLAnchorElement, PrimaryLinkProps>(
  function PrimaryLinkWithoutRef({ children, size = "md", ...props }: PrimaryLinkProps, ref) {
    const padding = {
      sm: "py-1.5 px-3",
      md: "py-2 px-4",
      lg: "py-3 px-6",
      xl: "py-4 px-8",
    };

    return (
      <Link
        ref={ref}
        {...props}
        className={clsx(
          "inline-flex items-center justify-center gap-1",
          "bg-violet-600 text-white font-medium text-sm shadow leading-none rounded-lg transition duration-200",
          padding[size],
          "whitespace-nowrap",
          "enabled:hover:bg-gradient-to-bl enabled:hover:from-violet-900 enabled:hover:to-violet-950",
          "disabled:opacity-50 disabled:cursor-not-allowed",
          props?.className ?? "",
        )}
      >
        {children}
      </Link>
    );
  },
);
