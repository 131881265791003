import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { sendNetsuite } from "@/api/invoice";
import { assertParameterExists } from "@/helpers/loader-guards";
import { processYupErrors } from "@/helpers/yup-errors";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  let input;

  try {
    input = await Yup.object()
      .shape({
        invoiceId: Yup.string().required(),
        overrideEinvoice: Yup.boolean().required(),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await sendNetsuite(input.invoiceId, input.overrideEinvoice);

    return {
      status: 200,
    };
  } catch (e) {
    return {
      error: {
        status: 500,
      },
    };
  }
}
