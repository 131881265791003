import { Await, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { CompanyNote } from "@/entities/company-note";
import { StyledHtmlContent } from "@/components/styled-html-content";
import { OrderItemDialogData } from "./route";

export function CompanyNotes() {
  const { companyNote } = useLoaderData() as OrderItemDialogData;

  return (
    <div className="space-y-4">
      <Suspense fallback={<p className="italic">Loading company notes...</p>}>
        <Await resolve={companyNote}>
          {(resolvedCompanyNote: CompanyNote) =>
            !resolvedCompanyNote.content.ops.length ? (
              <p className="italic text-sm text-slate-500">Company has no notes.</p>
            ) : (
              <StyledHtmlContent content={resolvedCompanyNote.htmlContent} />
            )
          }
        </Await>
      </Suspense>
    </div>
  );
}
