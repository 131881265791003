import * as React from "react";
import clsx from "clsx";

type SecondaryButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: keyof typeof sizeBasedStyles;
};

export default React.forwardRef(SecondaryButtonWithoutRef);

function SecondaryButtonWithoutRef(
  { children, size = "md", ...props }: SecondaryButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const baseClasses = [
    "inline-flex items-center justify-center",
    "bg-purple-200 text-violet-900 font-medium rounded-lg leading-none tracking-wide",
    "whitespace-nowrap",
    sizeBasedStyles[size],
    "transition duration-200",
  ];

  return (
    <button
      ref={ref}
      {...props}
      className={clsx(
        baseClasses,
        "enabled:hover:bg-gradient-to-t enabled:hover:from-purple-200 enabled:hover:to-blue-100",
        "disabled:opacity-40 disabled:cursor-not-allowed",
      )}
    >
      {children}
    </button>
  );
}

const sizeBasedStyles = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1.5 px-3 text-sm",
  "sm-square": "py-1.5 px-1.5 text-sm",
  md: "py-2 px-4 text-sm",
  "md-square": "p-2 text-sm",
  lg: "py-3 px-6 text-sm",
  xl: "py-4 px-8",
} as const;
