// Types for quill is incorrect
// eslint-disable-next-line import/no-named-as-default
import Quill, { DeltaStatic } from "quill";
import Delta from "quill-delta";

export function render(delta: Delta) {
  const quillContainer = new DOMParser().parseFromString("<div></div>", "text/html").body;
  const quill = new Quill(quillContainer);
  quill.setContents(delta as unknown as DeltaStatic);
  return quillContainer.querySelector(".ql-editor")?.innerHTML ?? "";
}

const inline = Quill.import("blots/inline");

export class UrlOptions {
  label!: string;
  url!: string;
  isNewWindow!: boolean;
}

class UrlFormat extends inline {
  static blotName = "url";
  static tagName = "A";

  static create(options: UrlOptions) {
    const node = super.create(options.label) as HTMLElement;
    node.setAttribute("href", this.addProtocolIfDoesntExist(options.url));
    node.setAttribute("title", options.label);

    if (options.isNewWindow) {
      node.setAttribute("target", "_blank");
    }

    return node;
  }

  public static addProtocolIfDoesntExist(url: string) {
    return !/^(http:|https:)/.test(url) ? `http://${url}` : url;
  }

  static formats(node: HTMLElement) {
    return {
      label: node.getAttribute("title"),
      url: node.getAttribute("href"),
      isNewWindow: node.getAttribute("target") === "_blank",
    };
  }

  format(name: string, value: unknown) {
    const { domNode } = this;

    if (
      name === "link" &&
      typeof value === "object" &&
      value &&
      "url" in value &&
      "label" in value
    ) {
      domNode.setAttribute("href", value.url);
      domNode.setAttribute("title", value.label);
      domNode.classList.add("text-company-link");

      if ("isNewWindow" in value && value.isNewWindow) {
        domNode.setAttribute("target", "_blank");
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(UrlFormat);
