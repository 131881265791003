import type { ComponentProps } from "react";
import { Link } from "react-router-dom";

export type StyledLinkArgs = ComponentProps<typeof Link>;

export default function StyledLink({ children, ...props }: StyledLinkArgs) {
  const className = [
    "underline decoration-indigo-200 decoration-2 text-violet-900 font-medium underline-offset-1",
    props.className ? props.className : "",
  ].join(" ");

  return (
    <Link {...props} className={className}>
      {children}
    </Link>
  );
}
