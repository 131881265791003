import { Entity, Type } from "@jobilla/entity";
import { Money } from "./money";

export class Product extends Entity {
  id!: string;
  title!: string;
  sku!: string;

  @Type(Money)
  price!: Money;

  get isDigitalHeadhunting() {
    return ["DH", "DH-LITE", "DH-PRO"].includes(this.sku);
  }
}
