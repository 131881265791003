import { useSearchParams } from "react-router-dom";
import { Paginated } from "@/api/base";
import { Invoice } from "@/entities/invoice";
import { Paginator } from "@/components/paginator";
import { Table, TableColumn, TableRow } from "@/components/table";
import StyledLink from "@/components/styled-link";
import { statusToName, InvoiceStatus } from "@/entities/invoice-status";
import Date, { DateDisplayType } from "@/components/date";
import { MoneyDisplay } from "@/components/money-display";

type InvoiceListTableProps = {
  isLoading: boolean;
  invoices: Paginated<Invoice[]>;
};

export default function InvoiceListTable({ isLoading, invoices }: InvoiceListTableProps) {
  const [searchParams] = useSearchParams();

  return (
    <div className="relative">
      <Table
        columns={[
          { label: "ID" },
          { label: "Order" },
          { label: "Sending date" },
          { label: "Status" },
          { label: "Amount" },
          { label: "Customer" },
          { label: "Business unit" },
          { label: "Last Updated" },
        ]}
      >
        <tbody>
          {invoices.data.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableColumn>
                <StyledLink to={`/invoices/${invoice.id}`}>#{invoice.numericId}</StyledLink>
              </TableColumn>
              <TableColumn>
                <StyledLink to={`/orders/${invoice.order.id}`}>
                  #{invoice.order.numericId}
                </StyledLink>
              </TableColumn>
              <TableColumn>
                {invoice.status === InvoiceStatus.Void ? (
                  "-"
                ) : invoice.condition === "sending_date" ? (
                  invoice.date ? (
                    <Date date={invoice.date} display={DateDisplayType.DATE} />
                  ) : (
                    "Not set"
                  )
                ) : invoice.condition === "order_item" ? (
                  "On production"
                ) : invoice.condition === "custom" ? (
                  "Custom condition"
                ) : null}
              </TableColumn>
              <TableColumn>{statusToName(invoice.status)}</TableColumn>
              <TableColumn>
                <MoneyDisplay amount={invoice.amount} />
              </TableColumn>
              <TableColumn>{invoice.order.company?.name}</TableColumn>
              <TableColumn>{invoice.order.company?.units[0]?.name}</TableColumn>
              <TableColumn>
                <Date date={invoice.updatedAt} display={DateDisplayType.RELATIVE} />
              </TableColumn>
            </TableRow>
          ))}
        </tbody>

        {invoices.data.length === 0 && (
          <tfoot>
            <tr className="border-t">
              <TableColumn colSpan={9}>
                <p className="italic text-sm">There are no invoices to show.</p>
              </TableColumn>
            </tr>
          </tfoot>
        )}
      </Table>

      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-40 text-center py-12">
          <p className="font-bold text-2xl">Loading...</p>
        </div>
      )}

      {!!invoices.data.length && (
        <div className="mt-8">
          <Paginator
            currentPage={Number(searchParams.get("page") ?? 1)}
            lastPage={invoices.meta.last_page}
            baseUrl={`/invoices?${searchParams}`}
          />
        </div>
      )}
    </div>
  );
}
