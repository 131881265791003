import React, { InputHTMLAttributes, MutableRefObject, ReactNode, useId, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useActionErrors } from "@/hooks/useActionErrors";
import { mergeRefs } from "@/components/forms/merge-refs";

export type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement> & {
    errors?: string[] | null;
    label?: ReactNode;
    description?: ReactNode;
    name: string;
    id?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
  },
  /*
   * This component currently does not support controlled checkboxes. This omission
   * is intentional to prevent consumers from trying to use this component as a
   * controlled input.
   */
  "checked"
>;

export function Checkbox({
  errors,
  description,
  name,
  label,
  onChange,
  inputRef,
  ...props
}: CheckboxProps) {
  const id = props.id ?? useId();
  const actionErrors = useActionErrors();

  const { register, formState } = useFormContext() || { register: () => {} };

  const {
    ref,
    onChange: rhfOnChange,
    ...rest
  } = register(name, { value: props.defaultValue }) || {};

  const validationError = formState?.errors?.[name ?? ""]
    ? [formState?.errors?.[name ?? ""]?.message]
    : null;

  errors = errors ?? validationError ?? actionErrors?.[name ?? ""];

  const [isChecked, setIsChecked] = useState<boolean>(props.defaultChecked ?? false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange?.(event);
    rhfOnChange?.(event);
  };

  return (
    <div className="flex clear-both">
      <div className="flex items-center my-2.5">
        <input type="hidden" name={name} value={isChecked ? "on" : "off"} />

        <input
          {...rest}
          name={name}
          ref={inputRef ? mergeRefs(inputRef, ref) : ref}
          type="checkbox"
          id={id}
          className="form-checkbox h-5 w-5 text-indigo-600 border-2 border-zinc-400 rounded transition duration-150"
          onChange={handleChange}
          {...props}
        />

        <label htmlFor={id} className="ml-2 block text-sm leading-5 text-gray-900">
          {label}
        </label>
      </div>

      {errors?.map((errorText) => (
        <p className="text-sm mt-1 text-red-700" key={errorText}>
          {errorText}
        </p>
      ))}

      {description && <p className="text-sm mt-2 text-stone-500">{description}</p>}
    </div>
  );
}
