import { Fragment, useContext, useMemo, useState } from "react";
import { format, startOfToday } from "date-fns";
import SelectBox from "@/components/forms/select-box";
import TextField from "@/components/forms/text-field";
import { OrderItemLine, OrderItemsContext } from "@/routes/orders.create/order-items";
import { Table, TableColumn, TableRow } from "@/components/table";
import { Money } from "@/entities/money";
import { Currency } from "@/entities/currency";
import { InvoiceCondition } from "@/entities/invoice-condition";
import { TextareaField } from "@/components/forms/textarea-field";
import { Label } from "@/components/forms/label";
import { useActionErrors } from "@/hooks/useActionErrors";
import { MoneyDisplay } from "@/components/money-display";

export function Invoices() {
  const [invoiceSplit, setInvoiceSplit] = useState("");
  const errors = useActionErrors();

  const todayFormatted = useMemo(() => format(startOfToday(), "yyyy-MM-dd"), []);

  return (
    <>
      <SelectBox
        label="Invoice split"
        required
        name="invoice_split"
        value={invoiceSplit}
        onChange={(event) => setInvoiceSplit(event.currentTarget.value)}
        description="Determines how many invoices we issue for this order, and how they are issued."
        errors={errors?.invoice_split}
      >
        <option disabled value="">
          Select an option
        </option>

        <option value="single">Single — Send one invoice for the entire order</option>

        <option value="order_item">
          Invoice per item — Each item in the order gets its own invoice, with its own conditions.
        </option>
      </SelectBox>

      <TextField
        required
        className="w-auto"
        type="date"
        name="latest_invoicing_date"
        label="Latest invoicing date"
        min={todayFormatted}
        max="2099-12-31"
        description="Unsent invoices should be sent automatically on this date, even if some or all invoiced items haven't been delivered."
        errors={errors?.latest_invoicing_date}
      />

      <InvoiceRepresentations split={invoiceSplit} />
    </>
  );
}

function InvoiceRepresentations({ split }: { split: string }) {
  const { lines } = useContext(OrderItemsContext);

  if (split === "single") {
    return <InvoiceRepresentationItem invoiceIndex={0} lines={lines} />;
  }

  if (split === "order_item") {
    return (
      <div className="flex flex-col gap-4">
        {lines.map((line, index) => (
          <InvoiceRepresentationItem
            key={line.id}
            lines={[line]}
            invoiceIndex={index}
            defaultCondition={InvoiceCondition.OrderItem}
          />
        ))}
      </div>
    );
  }

  return null;
}

function InvoiceRepresentationItem({
  lines,
  invoiceIndex,
  defaultCondition = InvoiceCondition.SendingDate,
}: {
  lines: OrderItemLine[];
  invoiceIndex: number;
  defaultCondition?: InvoiceCondition;
}) {
  const errors = useActionErrors();
  const [condition, setCondition] = useState<InvoiceCondition>(defaultCondition);

  const todayFormatted = useMemo(() => format(startOfToday(), "yyyy-MM-dd"), []);

  return (
    <div className="bg-white rounded border shadow-sm p-4">
      {lines.map((line, index) => (
        <Fragment key={line.id}>
          <input
            type="hidden"
            name={`invoices[${invoiceIndex}].items[${index}].order_item_id`}
            value={line.id}
          />
          <input
            type="hidden"
            name={`invoices[${invoiceIndex}].items[${index}].price`}
            value={line.price}
          />
        </Fragment>
      ))}

      <div className="flex items-center justify-between text-sm pt-1">
        <p className="font-bold">Invoice #{(1 + invoiceIndex).toString()}</p>
        <p>
          <span className="text-slate-400 tracking-tight">Payment term </span>
          <span className="font-medium">14 days</span>
        </p>
      </div>

      <div className="mt-4 flex flex-col items-start gap-4 border-t pt-4">
        <div className="grid grid-cols-[120px,1fr] items-baseline justify-between gap-4">
          <Label>Condition:</Label>

          <SelectBox
            value={condition}
            name={`invoices[${invoiceIndex}].condition`}
            onChange={(event) => setCondition(event.currentTarget.value as InvoiceCondition)}
            errors={errors?.invoices?.[invoiceIndex]?.condition}
          >
            <option value={InvoiceCondition.SendingDate}>
              Sending date — We will send the invoice on a given date.
            </option>

            <option value={InvoiceCondition.OrderItem}>
              Order delivery — We will send the invoice as soon as all of the items in the invoice
              have been delivered to the customer, or latest invoicing date is reached, whichever
              comes first.
            </option>

            <option value={InvoiceCondition.Custom}>
              Custom — Specify your own conditions to be handled manually.
            </option>
          </SelectBox>
        </div>

        {condition === InvoiceCondition.SendingDate && (
          <div className="grid grid-cols-[120px,1fr] items-baseline justify-between gap-4">
            <Label>Send at:</Label>
            <TextField
              type="date"
              min={todayFormatted}
              name={`invoices[${invoiceIndex}].send_at`}
              errors={errors?.invoices?.[invoiceIndex]?.send_at}
            />
          </div>
        )}
        {condition === InvoiceCondition.Custom && (
          <div className="self-stretch">
            <TextareaField
              label="Custom conditions"
              name={`invoices[${invoiceIndex}].custom_condition`}
              errors={errors?.invoices?.[invoiceIndex]?.custom_condition}
            />
          </div>
        )}
      </div>

      <div className="mt-4">
        <Table
          columns={[
            { label: "Order item" },
            {
              label: "Price",
              rightAligned: true,
              minimal: true,
            },
          ]}
        >
          <tbody>
            {lines.map((line) => (
              <TableRow key={line.id}>
                <TableColumn>{line.product.title}</TableColumn>
                <TableColumn rightAligned numeric>
                  <MoneyDisplay amount={new Money(line.price * 100, Currency.EUR)} />
                </TableColumn>
              </TableRow>
            ))}

            <TableRow>
              <TableColumn rightAligned>
                <b>Total</b>
              </TableColumn>
              <TableColumn rightAligned numeric>
                <MoneyDisplay
                  amount={
                    new Money(
                      lines.reduce((sum, line) => sum + line.price * 100, 0),
                      Currency.EUR,
                    )
                  }
                />
              </TableColumn>
            </TableRow>
          </tbody>
        </Table>
      </div>
    </div>
  );
}
