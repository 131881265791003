import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import StyledLink from "@/components/styled-link";
import { Payee } from "@/entities/payee";
import { MoneyDisplay } from "@/components/money-display";

export function OrderSummary() {
  const { order, payee } = useLoaderData() as OrderDetailsData;

  return (
    <section className="bg-white rounded p-6 shadow-sm text-sm">
      <div className="grid grid-cols-3 gap-4">
        <div className="space-y-1">
          <p className="font-bold">Order summary</p>
          <div className="flex flex-col space-y-2 items-start">
            <SummaryPill>{order.items.length} items</SummaryPill>
            {!order.isTransferOrder ? (
              <SummaryPill>
                <MoneyDisplay amount={order.itemsTotal} /> total
              </SummaryPill>
            ) : null}
            <SummaryPill>{order.invoices.length} invoice</SummaryPill>
          </div>
        </div>

        <div className="space-y-1">
          <p className="font-bold">Company details</p>
          <p>
            {order.company ? (
              <StyledLink className="text-sm" to={`/companies/${order.company.id}`}>
                {order.company.name}
              </StyledLink>
            ) : (
              "Could not load company details"
            )}
          </p>
        </div>

        <div className="space-y-1">
          <p className="font-bold">Paid to</p>
          <Suspense
            fallback={
              <p>
                <em>Loading payee data...</em>
              </p>
            }
          >
            <Await resolve={payee}>
              <PayeeInformation />
            </Await>
          </Suspense>
        </div>
      </div>
    </section>
  );
}

function SummaryPill({ children }: { children: React.ReactNode }) {
  return (
    <span className="inline-block bg-blue-50 text-blue-700 py-1 px-2 rounded font-bold text-xs uppercase">
      {children}
    </span>
  );
}

function PayeeInformation() {
  const payee = useAsyncValue() as Payee;

  return (
    <>
      <p>{payee.name}</p>
      <p>
        <br />
      </p>
      <p>{payee.address.line1}</p>
      {payee.address.line2 && <p>{payee.address.line2}</p>}
      <p>{payee.address.city}</p>
      <p>{payee.address.postalCode}</p>
      <p>{payee.address.country}</p>
    </>
  );
}
