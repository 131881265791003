import { useNavigation } from "react-router-dom";
import PrimaryButton, { PrimaryButtonProps } from "@/components/primary-button";
import { SpinnerIcon } from "@/components/icons/spinner";

type SubmitButtonArgs = {
  label?: string | React.ReactElement;
  labelWhenSubmitting?: string | React.ReactElement;
  isSubmitting?: boolean;
} & PrimaryButtonProps;

export default function SubmitButton({
  label = <SubmitButtonSaveLabel />,
  labelWhenSubmitting = <SubmitButtonSavingLabel />,
  isSubmitting,
  disabled,
  ...props
}: SubmitButtonArgs) {
  const transition = useNavigation();

  isSubmitting ??= ["submitting"].includes(transition.state);

  return (
    <PrimaryButton {...props} type="submit" disabled={disabled || isSubmitting}>
      {isSubmitting ? labelWhenSubmitting : label}
    </PrimaryButton>
  );
}

export function SubmitButtonSaveLabel() {
  return (
    <div className="flex items-center space-x-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="inline-block -my-0.5 w-4 h-4"
      >
        <g fill="currentColor">
          <path d="M22 0H5.914A1.986 1.986 0 0 0 4.5.587L.586 4.5A2.009 2.009 0 0 0 0 5.915V22a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-3.5 2a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 17.5 8h-10A1.5 1.5 0 0 1 6 6.5v-4a.5.5 0 0 1 .5-.5zM5 22a.5.5 0 0 1-.5-.5v-8A1.5 1.5 0 0 1 6 12h13a1.5 1.5 0 0 1 1.5 1.5v8a.5.5 0 0 1-.5.5z" />
          <path d="M15 4.251v1.5a.75.75 0 1 0 1.5 0v-1.5a.75.75 0 1 0-1.5 0zm-8 12h6.5a.75.75 0 0 0 0-1.5H7a.75.75 0 0 0 0 1.5zm0 4h11a.75.75 0 0 0 0-1.5H7a.75.75 0 0 0 0 1.5z" />
        </g>
      </svg>

      <span>Save</span>
    </div>
  );
}

export function SubmitButtonSavingLabel({ text = "Saving..." }: { text?: string }) {
  return (
    <div className="flex items-center space-x-2 italic">
      <SpinnerIcon className="animate-spin -my-0.5 h-4 w-4 text-white" />
      <span>{text}</span>
    </div>
  );
}
