import ky, { HTTPError } from "ky";
import {
  enforceReauthorization,
  getAuthToken,
  NoRefreshTokenError,
  refreshAuthToken,
} from "@/services/auth";

export const api = ky.create({
  prefixUrl: import.meta.env.VITE_API_BASE,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const accessToken = getAuthToken();
        if (accessToken) {
          request.headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
          await enforceReauthorization(true);
        }
      },
    ],

    afterResponse: [
      async (request, options, response) => {
        // Don't do anything if we are requesting a new auth token.
        if (request.url.includes("oauth/token")) {
          return;
        }

        // We only care about 401 responses in this hook.
        if (response.status !== 401) {
          return;
        }

        try {
          await refreshAuthToken();
        } catch (error) {
          // If we don't currently have a refresh token, we can't refresh the auth token. So let's
          // just ask user to login again.
          if (error instanceof NoRefreshTokenError) {
            // If the request is already for logout, we don't need to send yet another request to
            // the logout endpoint again. Just yeet user to login page.
            enforceReauthorization(request.url.includes("api/v2/auth/logout"));
          }

          // If the refresh attempt fails, sorry user, you need to login again.
          if (error instanceof HTTPError && error.response.status === 401) {
            enforceReauthorization();
          }

          // If we don't know what it is, just rethrow it, let the error boundary handle it.
          throw error;
        }

        return api(request);
      },
    ],
  },
});

export type Paginated<T> = {
  data: T;
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export type ValidationErrorResponse = {
  errors: {
    [key: string]: string[];
  };
};
