import { EntityBuilder, PartialPropsJson, PropsJson } from "@jobilla/entity";
import { BillingDetailsRequest } from "@/entities/billing-details-request";
import { api } from "@/api/base";

export async function createBillingDetailsRequest(bdr: PartialPropsJson<BillingDetailsRequest>) {
  const json = await api
    .post("v1/billing/billing-detail-requests", { json: bdr })
    .json<{ data: PropsJson<BillingDetailsRequest> }>();

  return EntityBuilder.buildOne(BillingDetailsRequest, json.data);
}
