import { Await, Form, useLoaderData, useSearchParams } from "react-router-dom";
import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { capitalize, uniqBy } from "lodash";
import Select, { SingleValue } from "react-select";
import SelectBox from "@/components/forms/select-box";
import { OrderPipelinesIndexData } from "@/routes/order-pipeline.$product/route";
import { Unit } from "@/entities/unit";
import { Checkbox } from "@/components/forms/checkbox";
import { Label } from "@/components/forms/label";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { fetchCompanies } from "@/api/company";
import { Company } from "@/entities/company";

export default function OrderPipelinesIndexFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { units } = useLoaderData() as OrderPipelinesIndexData;

  const handleChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams.entries()),
      page: "1",
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams.entries()),
      ["hide_completed"]: checked ? "true" : "false",
    }));
  };

  return (
    <Form action="/order-pipeline" className="flex flex-row justify-between space-x-10">
      <div className="flex flex-col space-y-2 w-1/4">
        <SelectBox
          name="unit_id"
          value={searchParams.get("unit_id") ?? ""}
          label="Assigned Unit"
          onChange={handleChange}
        >
          <option value="">All</option>
          {Object.values(units).map((unit: Unit) => (
            <option key={unit.id} value={unit.id}>
              {capitalize(unit.name)}
            </option>
          ))}
        </SelectBox>
        <Checkbox
          name="hide_completed"
          label="Hide completed campaigns"
          defaultChecked={searchParams.get("hide_completed") === "true"}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="w-1/4">
        <CompanySelectBox />
      </div>
    </Form>
  );
}

interface CompanyOption {
  readonly id: number;
  readonly name: string;
}

type SelectValue = {
  label: string;
  value: string;
};

function CompanySelectBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [companiesList, setCompaniesList] = useState<Company[]>([]);

  const { companies } = useLoaderData() as OrderPipelinesIndexData;

  const prefilledCompany: Company | undefined = companies.find(company => company.id === Number(searchParams.get("company_id")));

  const handleSelectionChange = (newSelection: SingleValue<SelectValue | false>) => {
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams.entries()),
      page: "1",
      company_id: (newSelection as SelectValue)?.value ?? "",
      company_name: (newSelection as SelectValue)?.label ?? "",
    }));
  };

  const onInputChange = useDebouncedCallback(
    { wait: 300, trailing: true, leading: false },
    (name: string) => {
      fetchCompanies({ name }).then(({ data }) => {
        setCompaniesList(uniqBy([...companies, ...data], "id"));
      });
    },
    [companiesList],
  );

  useEffect(() => {
    setCompaniesList(companies);
  }, []);

  return (
    <Suspense fallback={<Select isDisabled options={[]} />}>
      <Await resolve={companiesList} errorElement={<Select isDisabled options={[]} />}>
        {(resolvedCompanies) => (
          <div className="flex flex-col justify-stretch">
            <Label>Company Name</Label>
            <Select
              name="companyId"
              aria-label={"Company"}
              isSearchable
              isClearable
              autoFocus
              onInputChange={onInputChange}
              defaultValue={!!prefilledCompany && {
                value: prefilledCompany.id.toString(),
                label: prefilledCompany.name,
              }}
              options={resolvedCompanies.map((company: CompanyOption) => ({
                value: company.id.toString(),
                label: company.name,
              }))}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#f5e9ff",
                  primary: "#e1c1ff",
                },
              })}
              onChange={handleSelectionChange}
            />
          </div>
        )}
      </Await>
    </Suspense>
  );
}
