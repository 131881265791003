import { parseISO, format as formatDate, formatDistanceToNow } from "date-fns";
import { ReactElement, ReactNode, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { LocaleContext } from "@/helpers/locale-context";

export enum DateDisplayType {
  DATE = "date",
  DATE_TIME = "date_time",
  TIME = "time",
  RELATIVE = "relative",
  FORMAT = "format",
}

export type DateArgs = {
  date: string;
  renderContent?: (dateString: string) => ReactNode;
} & (
  | { display: DateDisplayType.FORMAT; format: string }
  | { display: Exclude<DateDisplayType, DateDisplayType.FORMAT>; format?: never }
);

function defaultRenderContent(dateString: string) {
  return dateString;
}

export default function Date({
  date,
  display,
  format,
  renderContent = defaultRenderContent,
}: DateArgs): ReactElement {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const parsedDate = parseISO(date);

  try {
    const result =
      display === DateDisplayType.FORMAT
        ? formatDate(parsedDate, format)
        : display === DateDisplayType.RELATIVE
        ? formatDistanceToNow(parsedDate, { addSuffix: true })
        : getDateFormat(display).format(parsedDate);

    const tooltipContent = getDateFormat(DateDisplayType.DATE_TIME).format(parsedDate);

    return (
      <Tooltip.Root delayDuration={50} open={isTooltipShown} onOpenChange={setIsTooltipShown}>
        <Tooltip.Trigger>{renderContent(result)}</Tooltip.Trigger>

        <AnimatePresence>
          {isTooltipShown && (
            <Tooltip.Portal forceMount>
              <Tooltip.Content side="bottom" asChild forceMount>
                <motion.div
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
                >
                  {tooltipContent}
                </motion.div>
              </Tooltip.Content>
            </Tooltip.Portal>
          )}
        </AnimatePresence>
      </Tooltip.Root>
    );
  } catch (e) {
    if (import.meta.env.DEV) {
      console.error(e);
    } else {
      Sentry.captureException(e, {
        level: "error",
        extra: {
          date,
          format,
          display,
        },
      });
    }

    return <span className="italic">Invalid date.</span>;
  }
}

function getDateFormat(display: DateDisplayType) {
  const { locale } = useContext(LocaleContext);
  const options: Intl.DateTimeFormatOptions = {};

  if (display === DateDisplayType.DATE || display === DateDisplayType.DATE_TIME) {
    options.dateStyle = "short";
  }

  if (display === DateDisplayType.TIME || display === DateDisplayType.DATE_TIME) {
    options.timeStyle = "short";
  }

  return new Intl.DateTimeFormat(locale, options);
}
