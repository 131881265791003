import { HTMLAttributes, PropsWithChildren } from "react";
import clsx from "clsx";

export type TableRowArgs = PropsWithChildren<HTMLAttributes<HTMLTableRowElement>>;

export function TableRow({ children, className, ...props }: TableRowArgs) {
  return (
    <tr className={clsx("border-y hover:bg-indigo-50", className)} {...props}>
      {children}
    </tr>
  );
}
