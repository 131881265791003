import {
  defer,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigation,
} from "react-router-dom";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { User } from "@/entities/user";
import * as Page from "@/components/page";
import { Unit } from "@/entities/unit";
import { fetchAllUnits } from "@/api/unit";
import { listOrderItems } from "@/api/order-item";
import { OrderItem } from "@/entities/order-item";
import OrderPipelineIndexFilters from "@/routes/order-pipeline.$product/order-pipeline-index-filters";
import { Company } from "@/entities/company";
import { fetchCompanies } from "@/api/company";
import { Paginated } from "@/api/base";
import OrderPipelineSwimlanes from "@/routes/order-pipeline.$product/order-pipeline-swimlanes";
import { assertParameterExists } from "@/helpers/loader-guards";
import { fetchPipelineProducts } from "@/api/product";

export type OrderPipelineData = {
  newOrderItems: Paginated<OrderItem[]>;
  setupOrderItems: Paginated<OrderItem[]>;
  activeOrderItems: Paginated<OrderItem[]>;
  completedOrderItems?: Paginated<OrderItem[]>;
};

export type OrderPipelinesIndexData = {
  units: Unit[];
  companyId?: number;
  orderItems: OrderPipelineData;
  jobillaUsers?: Promise<User[]>;
  companies: Company[];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  assertParameterExists(params.product);

  const product = await fetchPipelineProducts().then((products) =>
    products.find((product) => product.id === params.product),
  );

  const searchParams = new URL(request.url).searchParams;
  const prefilledCompanyName: string | null = searchParams.get("company_name");

  const companyId = searchParams.get("companyId") ?? undefined;

  const orderItemsCallback = async (status: string) => {
    return listOrderItems({
      per_page: 25,
      current_page: Number(searchParams.get("page") ?? 1),
      product: params.product,
      unit_id: searchParams.get("unit_id") ?? undefined,
      company_id: searchParams.get("company_id") ?? undefined,
      statuses: [status],
      order_by: product?.isDigitalHeadhunting ? "campaign_created_at" : "created_at",
      order: "asc",
    });
  };

  const orderItemsPromiseList = await Promise.all([
    orderItemsCallback("new"),
    orderItemsCallback("setup"),
    orderItemsCallback("active"),
    searchParams.get("hide_completed") === "false" || !searchParams.has("hide_completed")
      ? orderItemsCallback("completed")
      : undefined,
  ]);

  const orderItems = {
    newOrderItems: orderItemsPromiseList[0],
    setupOrderItems: orderItemsPromiseList[1],
    activeOrderItems: orderItemsPromiseList[2],
    completedOrderItems:
      searchParams.get("hide_completed") === "false" || !searchParams.has("hide_completed")
        ? orderItemsPromiseList[3]
        : undefined,
  };

  const units = await fetchAllUnits();

  const companies = !!prefilledCompanyName
    ? await fetchCompanies({ name: prefilledCompanyName }).then((companies) => companies.data)
    : await fetchCompanies().then((companies) => companies.data);

  return defer({
    orderItems,
    companyId: companyId ? Number(companyId) : undefined,
    units,
    jobillaUsers: fetchJobillaUsers(),
    companies,
  } satisfies OrderPipelinesIndexData);
}

export default function OrderPipelinesIndexPage() {
  const location = useLocation();
  const transition = useNavigation();
  const { orderItems } = useLoaderData() as OrderPipelinesIndexData;
  const isLoading =
    transition.state !== "idle" && transition.location?.pathname === location.pathname;

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>Order Pipeline</Page.Title>
      </Page.Heading>

      <Page.Content>
        <OrderPipelineIndexFilters />
        <OrderPipelineSwimlanes isLoading={isLoading} orderItems={orderItems} />
      </Page.Content>

      <Outlet />
    </Page.Root>
  );
}
