import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HTTPError } from "ky";
import SidebarBox from "@/routes/companies.$company/sidebar-box";
import SidebarBoxAction from "@/routes/companies.$company/sidebar-box-action";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { Company } from "@/entities/company";
import SelectBox from "@/components/forms/select-box";
import { Unit } from "@/entities/unit";
import { updateCompanyBusinessUnit } from "@/api/company-business-unit";
import { ErrorBlock } from "@/components/error-block";
import type { CompanyDetailsData } from "./loader";

export default function BusinessUnit() {
  const [isChangeOn, setIsChangeOn] = useState(false);
  const { company } = useLoaderData() as CompanyDetailsData;

  const toggleChangeMode = useCallback(() => {
    setIsChangeOn(!isChangeOn);
  }, [isChangeOn, setIsChangeOn]);

  return (
    <SidebarBox
      title="Business Unit"
      action={
        <SidebarBoxAction onClick={toggleChangeMode}>
          {isChangeOn ? "Cancel" : "Change"}
        </SidebarBoxAction>
      }
    >
      {isChangeOn ? (
        <CompanyUnitSelectionForm company={company} onUpdate={() => setIsChangeOn(false)} />
      ) : !company.units || !company.units.length ? (
        <NoBusinessUnit />
      ) : (
        <CompanyUnitDetails company={company} />
      )}
    </SidebarBox>
  );
}

function CompanyUnitSelectionForm({
  company,
  onUpdate,
}: {
  company: Company;
  onUpdate: () => void;
}) {
  const { businessUnits } = useLoaderData() as CompanyDetailsData;

  const actionResult = useActionData() as ActionResult;
  const transition = useNavigation();
  const [hasBeenSubmitting, setHasBeenSubmitting] = useState(false);

  const hasSuccessfullySubmitted = hasBeenSubmitting && actionResult?.status === 200;
  const hasUnexpectedError = hasBeenSubmitting && actionResult?.status === 500;

  const isSaving = transition.state !== "idle";

  useEffect(() => {
    if (transition.state === "idle" && hasBeenSubmitting && hasSuccessfullySubmitted) {
      onUpdate();
      setHasBeenSubmitting(false);
    }

    if (transition.state === "submitting") {
      setHasBeenSubmitting(true);
    }
  }, [transition.state, onUpdate, hasBeenSubmitting]);

  const locationUnits = useMemo(() => {
    return businessUnits instanceof FailedOptionalResult
      ? []
      : businessUnits.filter((unit) => unit.type === "location");
  }, [businessUnits]);

  return (
    <Form method="put">
      <input type="hidden" name="_form" value="company-unit-change" />

      <fieldset disabled={isSaving} className="space-y-4">
        {businessUnits instanceof FailedOptionalResult ? (
          <ErrorState />
        ) : (
          <BusinessUnitField items={locationUnits} currentUnit={company.units?.[0]} />
        )}
        <p className="text-gray-600 font-normal">
          The Jobilla unit/subsidiary that is responsible for this company and which users can{" "}
          access it&amp;s data in Jobilla Order Management
        </p>

        {hasUnexpectedError && (
          <ErrorBlock>Unexpected error occurred while saving the business unit.</ErrorBlock>
        )}

        <div className="flex justify-end">
          <button
            className={[
              "bg-blue-200 py-1.5 px-4 text-blue-700 text-sm font-medium rounded-lg leading-none tracking-wide transition duration-200 hover:bg-blue-100",
              isSaving ? "italic opacity-70" : "",
            ].join(" ")}
            type="submit"
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </fieldset>
    </Form>
  );
}

type BusinessUnitFieldProps = {
  currentUnit?: Unit;
  items: Unit[];
};

function BusinessUnitField({ currentUnit, items }: BusinessUnitFieldProps) {
  const isCurrentUnitInList = useMemo(() => {
    return items.find((item) => item.id.toString() === currentUnit?.id.toString());
  }, [items, currentUnit]);

  return (
    <SelectBox name="id" defaultValue={currentUnit?.id ?? ""}>
      {!currentUnit && (
        <option value="" disabled>
          Select business unit
        </option>
      )}

      {currentUnit && !isCurrentUnitInList && (
        <option value={currentUnit.id} disabled>
          {currentUnit.name}
        </option>
      )}

      {items.map((unit) => (
        <option key={unit.id} value={unit.id}>
          {unit.name}
        </option>
      ))}
    </SelectBox>
  );
}

function CompanyUnitDetails({ company }: { company: Company }) {
  return (
    <>
      <p>{company.units?.[0].name}</p>
    </>
  );
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load business unit.</p>
    </div>
  );
}

function NoBusinessUnit() {
  return <p className="text-sm text-slate-700">No business unit assigned to this company.</p>;
}

type ActionResult = {
  status?: number;
  errors?: { [key: string]: string[] } | undefined;
};

export async function action_businessUnit({
  inputData,
  params,
}: {
  inputData: Record<string, string>;
} & ActionFunctionArgs): Promise<ActionResult | null> {
  try {
    await updateCompanyBusinessUnit(String(params.company), inputData.id);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: e.response.status,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }

  return {
    status: 200,
  };
}
