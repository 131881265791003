import { Await, useLoaderData } from "react-router-dom";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import { Suspense } from "react";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { PrimaryLink } from "@/components/primary-link";
import PrimaryAnchor from "@/components/primary-anchor";
import * as DropdownMenu from "@/components/dropdown-menu";
import SecondaryButton from "@/components/secondary-button";

export function OrderHubspotLink() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <div className="bg-white rounded py-5 px-4 shadow-sm">
      {order.isTransferOrder ? <SyntheticOrder /> : order.hubspotDeal ? <Linked /> : <NotLinked />}
    </div>
  );
}

function SyntheticOrder() {
  return (
    <div className="flex items-center justify-between italic">
      <p>
        <strong>This is a synthetic order</strong>, generated automatically from order item
        distribution. It is not assotiated with a Hubspot deal
      </p>
    </div>
  );
}

function NotLinked() {
  const { canUserModify } = useLoaderData() as OrderDetailsData;

  return (
    <div className="flex items-center justify-between">
      <p>This order is not linked with a HubSpot deal.</p>

      <div>
        <Suspense fallback={null}>
          <Await resolve={canUserModify}>
            {(hasPermission) =>
              hasPermission && (
                <PrimaryLink to="hubspot" preventScrollReset>
                  Link to HubSpot
                </PrimaryLink>
              )
            }
          </Await>
        </Suspense>
      </div>
    </div>
  );
}

function Linked() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <div className="flex items-center justify-between">
      <p>This order is linked to a HubSpot deal.</p>

      <div className="flex space-x-2">
        <PrimaryAnchor target="_blank" href={order.hubspotDeal?.url} size="lg">
          See in Hubspot
        </PrimaryAnchor>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <SecondaryButton type="button">
              <Cog8ToothIcon className="w-5 h-5" />
            </SecondaryButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content sideOffset={4}>
            <DropdownMenu.LinkItem
              to="hubspot"
              label="Change Link"
              subtext="Link this order to another HubSpot deal."
            />
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}
