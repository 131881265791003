import { Entity, PartialPropsJson } from "@jobilla/entity";
import { upperFirst } from "lodash";

export class BillingAddress extends Entity {
  city!: string;
  country!: string;
  postalCode!: string;
  line1!: string;
  line2!: string;

  get fullAddress() {
    return `${this.line1 ? `${this.line1}, ` : ""}${this?.city}, ${this?.country}, ${
      this?.postalCode
    }`;
  }

  fromJson(data: PartialPropsJson<this>): this {
    return super.fromJson({
      ...data,
      // This is a workaround for the broken data created by JOM 1.0 where
      // country is sometimes full lowercase string.
      country: upperFirst("country" in data ? (data.country as string) : ""),
    });
  }
}
