import { Suspense, useCallback, useEffect, useState } from "react";
import {
  Await,
  Form,
  useActionData,
  useNavigate,
  useNavigation,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Dialog } from "@/components/dialog";
import SecondaryButton from "@/components/secondary-button";
import { Billing } from "@/entities/billing";
import { SpinnerIcon } from "@/components/icons/spinner";
import { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import { InvoiceSelectBillingActionData } from "@/routes/invoices.$invoice.select-billing/action";
import { BillingForm } from "@/components/billing-form";

export { action } from "./action";

export default function SelectBillingDialog() {
  const { company, invoice } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const actionResult = useActionData() as InvoiceSelectBillingActionData;
  const transition = useNavigation();
  const navigate = useNavigate();
  const [isBillingFormShown, setIsBillingFormShown] = useState(false);
  const revalidator = useRevalidator();

  const isNavigatingToInvoicePage =
    transition.state === "loading" && transition.location?.pathname === `/invoices/${invoice.id}`;

  const goBackToInvoiceDetails = useCallback(
    () => navigate(`/invoices/${invoice.id}`, { preventScrollReset: true }),
    [invoice],
  );

  useEffect(() => {
    if (actionResult?.status === 200) {
      goBackToInvoiceDetails();
    }
  }, [actionResult?.status]);

  return (
    <>
      <Dialog
        isOpen={!isNavigatingToInvoicePage && !isBillingFormShown}
        onClose={goBackToInvoiceDetails}
        title="Select billing details"
        description="You are only able to choose a billing detail with the same currency."
      >
        <BillingSelectionForm isRevalidating={revalidator.state === "loading"} />

        <div className="mt-6 text-center">
          <SecondaryButton type="button" onClick={() => setIsBillingFormShown(true)}>
            <PlusIcon className="w-4 h-4" />
            <p>Add new billing details</p>
          </SecondaryButton>
        </div>

        <div className="mt-6 flex items-center justify-center space-x-4">
          <SecondaryButton type="button" size="lg" onClick={goBackToInvoiceDetails}>
            Cancel
          </SecondaryButton>
        </div>
      </Dialog>

      <Dialog
        isOpen={!isNavigatingToInvoicePage && isBillingFormShown}
        onClose={() => setIsBillingFormShown(false)}
        title="Add new billing details for this invoice"
      >
        <Suspense fallback={null}>
          <Await resolve={company}>
            {(resolvedCompany) => (
              <BillingForm
                companyId={resolvedCompany.id}
                onCancel={() => setIsBillingFormShown(false)}
                onSuccess={() => {
                  revalidator.revalidate();
                  setIsBillingFormShown(false);
                }}
              />
            )}
          </Await>
        </Suspense>
      </Dialog>
    </>
  );
}

function BillingSelectionForm({ isRevalidating }: { isRevalidating: boolean }) {
  const { billingDetails } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const transition = useNavigation();
  const isSubmitting = transition.state === "submitting";
  const submissionData = Object.fromEntries(transition.formData?.entries() ?? []);

  return (
    <Form method="post" noValidate>
      <fieldset disabled={isSubmitting} className="flex flex-col gap-6">
        {/* No fallback here because data starts loading when the page is visited, so it will very likely finish loading once the dialog is opened */}
        <Suspense>
          <Await resolve={billingDetails}>
            {(resolvedBillingDetails: Billing[]) =>
              resolvedBillingDetails.map((billing) => (
                <button
                  className="leading-normal ring-1 ring-zinc-300 text-slate-600 rounded text-left py-2 px-3 hover:bg-purple-50 disabled:opacity-70"
                  type="submit"
                  key={billing.id}
                  name="billing_details_id"
                  value={billing.id}
                >
                  <p className="inline-block w-auto float-right text-right pl-2 pb-2 leading-tight">
                    {billing.paymentTerms.toString()} days
                    <br />
                    <span className="text-sm text-zinc-500 tracking-tight">
                      Default payment terms
                    </span>
                  </p>
                  <p className="font-bold text-slate-800">{billing.name}</p>
                  <p>{billing.email}</p>
                  <p>{billing.vat}</p>

                  <span className="mt-2 flex flex-row items-end justify-between">
                    <span>
                      <p className="text-sm">{billing.address.line1}</p>
                      {billing.address.line2 && <p className="text-sm">{billing.address.line2}</p>}
                      <p className="text-sm">
                        {billing.address.postalCode}, {billing.address.city},{" "}
                        {billing.address.country}
                      </p>
                    </span>

                    {submissionData.billing_details_id === billing.id ? (
                      <div className="flex items-center gap-1">
                        <SpinnerIcon className="w-4 h-4 animate-spin" />
                        <p className="text-sm">Saving as the selected billing details</p>
                      </div>
                    ) : (
                      <p className="text-sm text-zinc-500">Click to choose this billing details</p>
                    )}
                  </span>
                </button>
              ))
            }
          </Await>
        </Suspense>

        {isRevalidating && (
          <div className="flex items-center gap-2 bg-violet-100 py-2 px-3 rounded">
            <SpinnerIcon className="w-4 h-4 animate-spin text-violet-600" />
            <p className="text-sm text-violet-800">
              Please wait, loading the newly created billing details...
            </p>
          </div>
        )}
      </fieldset>
    </Form>
  );
}
