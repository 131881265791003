import { LoaderFunctionArgs, Outlet, useLoaderData } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { PencilIcon } from "@heroicons/react/20/solid";
import * as Page from "@/components/page";
import { fetchOrderItem } from "@/api/order-item";
import { fetchAllUnits } from "@/api/unit";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { fetchOrder } from "@/api/order";
import { Basics } from "@/routes/order-items.$orderItem/basics";
import { Boosters } from "@/routes/order-items.$orderItem/boosters";
import { Creators } from "@/routes/order-items.$orderItem/creators";
import { ContactPerson } from "@/routes/order-items.$orderItem/contact-person";
import { Details } from "@/routes/order-items.$orderItem/details";
import { Revoke } from "@/routes/order-items.$orderItem/revoke";
import { CompanyNotes } from "@/routes/order-items.$orderItem/company-notes";
import { fetchCompanyNotes } from "@/api/company-notes";
import { fetchUserPermissionFor } from "@/api/permissions";
import { PrimaryLink } from "@/components/primary-link";
import { StatusSwitcher } from "@/routes/order-items.$orderItem/status-switcher";
import { fetchJob } from "@/api/job";
import { optional } from "@/helpers/loader-optional";
import { assertParameterExists } from "@/helpers/loader-guards";

export { ErrorBoundary } from "./error-boundary";

export type OrderItemDetailsData = Awaited<ReturnType<typeof loader>>;

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);
  const order = await fetchOrder(orderItem.order?.id ?? orderItem.orderId);

  if (!orderItem.order) {
    Sentry.captureMessage("Order item data does not include order entity", {
      level: "error",
      extra: {
        order_item_id: params.orderItem,
      },
    });

    orderItem.order = order;
  }

  return {
    orderItem,
    order,
    job: orderItem.campaignContext?.jobId
      ? optional(fetchJob(orderItem.campaignContext.jobId))
      : null,
    invoice: order.invoices.find((invoice) =>
      invoice.items.some((item) => item.orderItemId === orderItem.id),
    ),
    permissionToRevokeCampaign: fetchUserPermissionFor(
      "revoke_campaign_request",
      "order",
      order.id,
    ),
    units: fetchAllUnits(),
    jobillaUsers: fetchJobillaUsers(),
    // At least one of those properties will be present. Terrible interface
    // design, I know... We will update these API result types some time in the
    // future to use individual return types for each of these endpoints.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    companyNote: fetchCompanyNotes((order.company?.id ?? order.companyId)!),
  };
}

export default function OrderItem() {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  return (
    <Page.Root>
      <Page.Heading
        backLink={
          <Page.GoBackLink to={`/order-pipeline/${orderItem.product?.id ?? orderItem.productId}`}>
            Back to pipeline
          </Page.GoBackLink>
        }
      >
        <div className="flex justify-between items-center gap-4">
          <Page.Title>
            {orderItem.product?.title ? `Order item: ${orderItem.product.title}` : "Order item"}
          </Page.Title>

          <div className="flex items-end gap-4">
            {orderItem.product?.isDigitalHeadhunting && !orderItem.isDistributed && (
              <PrimaryLink to={`/order-items/${orderItem.id}/campaign-request`} size="lg">
                <PencilIcon className="relative h-3.5 w-3.5 mr-1" />
                Edit
              </PrimaryLink>
            )}

            <StatusSwitcher />
          </div>
        </div>
      </Page.Heading>

      <Page.Content multiColumnLayout>
        <Page.Main>
          <div className="flex flex-col gap-12 bg-white rounded shadow-sm p-6">
            {orderItem.product?.isDigitalHeadhunting && (
              <>
                <Section title="Campaign details">
                  <Basics />
                </Section>

                {orderItem.campaignContext && (
                  <>
                    <Section title="Booster">
                      <Boosters />
                    </Section>

                    <Section title="Campaign creator's details">
                      <Creators />
                    </Section>

                    <Section title="Campaign contact person">
                      <ContactPerson />
                    </Section>
                  </>
                )}
              </>
            )}

            <Section title="Details">
              <Details />
            </Section>
          </div>

          {orderItem.product?.isDigitalHeadhunting && <Revoke />}
        </Page.Main>

        <Page.ColumnSeparator />

        <Page.Sidebar>
          <CompanyNotes />
        </Page.Sidebar>
      </Page.Content>

      <Outlet />
    </Page.Root>
  );
}

function Section({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <section className="flex flex-col gap-6">
      <p className="text-2xl font-bold text-gray-600">{title}</p>
      {children}
    </section>
  );
}
