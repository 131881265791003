import * as Alert from "@radix-ui/react-alert-dialog";
import { ReactNode, useState } from "react";
import { useFetcher } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import SecondaryButton from "@/components/secondary-button";
import { useOnFetcherSuccess } from "@/hooks/fetcher-hooks";
import SubmitButton from "@/components/submit-button";
import { ErrorBlock } from "@/components/error-block";

export type ConfirmationProps = {
  children: ReactNode;
  content?: ReactNode;
  description?: ReactNode;
  formAction: string;
  onSuccess?: () => void;
  submitLabel?: string;
  title?: ReactNode;
};

export function Confirmation({
  children,
  content,
  description,
  formAction,
  onSuccess,
  submitLabel,
  title,
}: ConfirmationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const fetcher = useFetcher();

  const hasOperationFailed = fetcher.data?.status >= 500;

  useOnFetcherSuccess(fetcher, () => {
    setIsOpen(false);
    onSuccess?.();
  });

  return (
    <Alert.Root open={isOpen} onOpenChange={setIsOpen}>
      <Alert.Trigger asChild>{children}</Alert.Trigger>

      <Alert.Portal forceMount>
        <div>
          <AnimatePresence>
            {isOpen && (
              <Alert.Overlay asChild>
                <motion.div
                  className="fixed z-40 inset-0 bg-black/50 grid place-items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <Alert.Content className="bg-white rounded-xl shadow-xl min-w-[300px] w-full max-w-[700px]">
                    <Alert.Title className="px-12 pt-12 text-2xl font-bold text-stone-800">
                      {title ?? "Are you sure?"}
                    </Alert.Title>

                    {description && (
                      <Alert.Description className="mt-6 px-12 pb-4 text-sm text-stone-500">
                        {description}
                      </Alert.Description>
                    )}

                    <fetcher.Form action={formAction} method="post">
                      {content && <div className="mt-6 px-12">{content}</div>}

                      {hasOperationFailed && (
                        <ErrorBlock className="mt-6 mx-12">
                          <p>Operation has failed.</p>
                          {fetcher.data?.message && <p>{fetcher.data.message}</p>}
                        </ErrorBlock>
                      )}

                      <div className="mt-6 px-12 pb-8 flex items-center justify-center gap-4">
                        <SubmitButton
                          isSubmitting={fetcher.state === "submitting"}
                          size="lg"
                          label={submitLabel ?? "Confirm"}
                          labelWhenSubmitting="Please wait..."
                        />

                        <Alert.Cancel asChild>
                          <SecondaryButton type="button" size="lg">
                            Cancel
                          </SecondaryButton>
                        </Alert.Cancel>
                      </div>
                    </fetcher.Form>
                  </Alert.Content>
                </motion.div>
              </Alert.Overlay>
            )}
          </AnimatePresence>
        </div>
      </Alert.Portal>
    </Alert.Root>
  );
}
