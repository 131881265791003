import { ActionFunctionArgs, redirect } from "react-router-dom";
import invariant from "tiny-invariant";
import { mixed, number, object, string, ValidationError } from "yup";
import { HTTPError } from "ky";
import { add, formatISO, startOfDay } from "date-fns";
import { cloneDeep } from "lodash";
import { init as initI18n, t } from "i18next";
import { v4 as uuid } from "uuid";
import { PartialPropsJson, PropsJson } from "@jobilla/entity";
import Delta from "quill-delta";
import * as Sentry from "@sentry/react";
import { languages } from "@/helpers/public-languages";
import { processYupErrors } from "@/helpers/yup-errors";
import { createForm, createJob } from "@/api/job";
import { JobStatus } from "@/entities/job-status";
import { fetchTranslationsForLanguage } from "@/api/translations";
import { fetchCompany, fetchCompanyBrandingSettings } from "@/api/company";
import { Company } from "@/entities/company";
import { Job } from "@/entities/job";
import { Form } from "@/entities/form";
import { BrandingSettings } from "@/entities/branding-settings";
import { createLandingPage, linkJobListsToLandingPage } from "@/api/landing-page";
import { LandingPage } from "@/entities/landing-page";
import { fetchCompanyJobLists } from "@/api/job-list";
import { createSubscription } from "@/api/subscription";
import { fetchOrderItem, updateOrderItem } from "@/api/order-item";
import { OrderItemStatus } from "@/entities/order-item-status";
import { updateCampaignRequest } from "@/api/campaign";
import { PipelineStage, PipelineStageType } from "@/entities/pipeline-stage";
import { assertParameterExists } from "@/helpers/loader-guards";
import formTemplate, {
  withCommuteQuestions,
  withContactConsentQuestions,
  withContactQuestions,
  withCurrentSituationQuestions,
  withEducationQuestions,
  withExperienceQuestions,
  withIntroductoryQuestions,
  withPersonalityQuestions,
  withResultScreens,
  withSpecificExperienceQuestions,
} from "./form-template";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);

  const { error: validationError, input } = await validate(request);

  if (validationError) {
    return validationError;
  }

  const [company, branding, jobLists, orderItem, translations] = await Promise.all([
    fetchCompany(input.company_id),
    fetchCompanyBrandingSettings(input.company_id),
    fetchCompanyJobLists(input.company_id),
    fetchOrderItem(params.orderItem),
    fetchTranslationsForLanguage(input.language).catch(() => fetchTranslationsForLanguage("en-GB")),
  ]);

  invariant(orderItem.campaignContext, "Order item does not have a campaign request.");

  await initI18n({
    // The language name doesn't matter, as we fetch the translations manually
    // and attach them to resources anyway.
    lng: input.language,
    ns: "namespace",
    interpolation: {
      prefix: "{",
      suffix: "}",
      escapeValue: false,
    },
    resources: {
      [input.language]: {
        namespace: translations,
      },
    },
  });

  try {
    const stages = getDefaultStages();

    if (["Benelux", "Apeldoorn"].includes(company.units[0].name)) {
      stages.splice(3, 0, {
        id: uuid(),
        name: "WhatsApp Gestuurd",
        type: PipelineStageType.Review,
        position: 3,
      });
    }

    stages.forEach((stage, index) => {
      stage.position = index;
    });

    const job = await createJob({
      version: 2,
      name: input.title,
      location: input.location,
      status: JobStatus.Draft,
      company_id: input.company_id,
      is_hidden: true,
      active_from: formatISO(startOfDay(new Date())),
      active_to: formatISO(startOfDay(add(new Date(), { weeks: 3 }))),
      stages,
      is_from_campaign_request: true,
      order_item_id: params.orderItem,
    });

    const formData = await prepareFormData({
      company,
      job,
      language: input.language,
    });

    const form = await createForm(formData);

    const landingPageData = await prepareLandingPageData({
      branding,
      form,
      job,
    });

    const landingPage = await createLandingPage(landingPageData);

    await Promise.all([
      linkJobListsToLandingPage(
        landingPage.id,
        jobLists.map((jl) => jl.id),
      ),
      ...(input.assignees?.map((assignee) =>
        createSubscription({
          user_id: assignee,
          pipeline_id: job.id,
        }),
      ) ?? []),
      updateOrderItem(params.orderItem, {
        status: OrderItemStatus.Setup,
      }),
      updateCampaignRequest(orderItem.campaignContext.id, {
        ...orderItem.campaignContext.toJson(),
        job_id: +job.id,
      }),
    ]);

    // Opens the recruitment in new tab instead of redirecting so the user
    // can come back to order item page easily.
    window.open(
      `${import.meta.env.VITE_TAS_APP_URL}/c/${company.id}/jobs/${job.id}/settings/details`,
      "_blank",
      "noopener noreferrer",
    );

    return redirect(`/order-items/${params.orderItem}`);
  } catch (e) {
    if (!(e instanceof HTTPError)) {
      Sentry.captureException(e);

      return {
        status: 500,
        message: e instanceof Error ? e.message : "Unexpected error",
      };
    }

    if (e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors as Record<string, string[]>,
      };
    }

    return { status: 500, message: (await e.response.json()).message ?? "" };
  }
}

type ValidateResult<T> = Promise<
  | {
      error: {
        status: number;
        errors?: Record<string, string[]>;
        message?: string;
      };
      input: undefined;
    }
  | {
      error: undefined;
      input: T;
    }
>;

const validationSchema = object({
  company_id: number().required(),
  language: mixed<(typeof languages)[number]["key"]>()
    .oneOf(languages.map((l) => l.key))
    .required("You must select a language."),
  title: string().required("You must provide a job name."),
  location: string().optional(),
  assignees: mixed<string[]>(),
});

async function validate(
  request: Request,
): ValidateResult<(typeof validationSchema)["__outputType"]> {
  const formData = await request.formData();

  // Because `Object.fromEntries` does not automatically resolve multiple
  // values of the same input name into an array, we have to do it manually.
  const rawData = {
    ...Object.fromEntries(formData.entries()),
    assignees: formData.getAll("assignees"),
  };

  let input;

  try {
    input = await validationSchema.validate(rawData, { abortEarly: false });
  } catch (e) {
    if (e instanceof ValidationError) {
      return {
        error: {
          status: 422,
          errors: processYupErrors(e),
        },
        input: undefined,
      };
    }

    throw e;
  }

  return { error: undefined, input };
}

type FormPreparationArgs = {
  company: Company;
  job: Job;
  language: string;
};

export async function prepareFormData({ company, job, language }: FormPreparationArgs) {
  const form = cloneDeep(formTemplate);

  form.job_id = +job.id;
  form.company_id = company.id;
  form.lang = language;
  form.name = job.name;

  const translationParams = {
    company: company ? company.name : "",
    location: job.location ?? "",
    name: job.name ?? "",
    lang: form.lang,
  };

  form.welcome.title = t(form.welcome.title, translationParams);
  form.welcome.subtitle = t(form.welcome.subtitle);
  form.welcome.subtext = JSON.stringify([
    {
      attributes: {
        italic: true,
      },
      insert: t("recruitment_presets.forms.welcome.subtext", translationParams),
    },
  ]);

  // #region Questions
  if (language === "nl-NL") {
    withIntroductoryQuestions(form);
    withCurrentSituationQuestions(form);
    withExperienceQuestions(form);
    withPersonalityQuestions(form);
    withEducationQuestions(form);
    form.welcome.description = JSON.stringify([
      {
        insert: "Dan is deze baan als ",
      },
      {
        attributes: {
          bold: true,
        },
        insert: translationParams.name,
      },
      {
        insert: " bij ",
      },
      {
        attributes: {
          bold: true,
        },
        insert: translationParams.company,
      },
      {
        insert: " echt iets voor jou!\n\nWij bieden:",
      },
      {
        attributes: {
          header: 2,
        },
        insert: "\n",
      },
      {
        insert: "✅ \n✅ \n✅ \n✅ \n✅ \n\nBenieuwd of dit bij je past?\n",
      },
      {
        attributes: {
          bold: true,
        },
        insert: 'Druk nu op "Start" om de test te beginnen!',
      },
      {
        insert: "\n",
      },
    ]);
  }

  if (language === "de-DE") {
    withCurrentSituationQuestions(form);
    withPersonalityQuestions(form);
    withEducationQuestions(form);
    withExperienceQuestions(form);
    withCommuteQuestions(form);

    form.welcome.description = JSON.stringify([
      {
        attributes: { color: "#000000", italic: true },
        insert: "Hier findest Du Informationen über den Job und uns als Unternehmen:",
      },
      { insert: "\n\n" },
      { attributes: { color: "#000000" }, insert: "Das bieten wir Dir" },
      { attributes: { header: 2 }, insert: "\n" },
      { attributes: { color: "#000000" }, insert: "🏝️ " },
      { insert: "\n" },
      { attributes: { color: "#000000" }, insert: "" },
      { insert: "\n\n\n" },
      { attributes: { color: "#000000" }, insert: "Deine Aufgaben in Kürze" },
      { attributes: { header: 2 }, insert: "\n" },
      { attributes: { list: "bullet" }, insert: "\n" },
      { insert: "\n" },
      { attributes: { color: "#000000" }, insert: "Über uns" },
      { attributes: { header: 2 }, insert: "\n" },
    ]);
  }

  if (["nb-NO", "sv-SE", "da-DK"].includes(language)) {
    withSpecificExperienceQuestions(form);
    withExperienceQuestions(form);
    withPersonalityQuestions(form);
    withEducationQuestions(form);
  }

  if (language === "fi-FI") {
    withSpecificExperienceQuestions(form);
    withExperienceQuestions(form);
    withEducationQuestions(form);
  }

  if (language === "en-GB") {
    withExperienceQuestions(form);
    withEducationQuestions(form);
    withCommuteQuestions(form);
  }

  // Required for everyone!
  withContactConsentQuestions(form);
  withContactQuestions(form);

  form.questions.forEach((question, index) => {
    question.uuid = uuid();
    question.label = t(question.label, translationParams);
    question.position = index + 1;

    if (question.options) {
      question.options.forEach((option) => {
        option.label = t(option.label);
      });
    }
  });
  // #endregion

  withResultScreens(form);
  form.results.forEach((result) => {
    result.title = t(result.title, translationParams);
    result.description = t(result.description, translationParams);
    result.buttonLabel = t(result.buttonLabel, translationParams);
  });

  return form;
}

type LandingPagePreparationArgs = {
  branding: BrandingSettings;
  form: Form;
  job: Job;
};

async function prepareLandingPageData({
  branding,
  form,
  job,
}: LandingPagePreparationArgs): Promise<PartialPropsJson<LandingPage>> {
  return {
    company_id: form.companyId,
    job_id: form.jobId,
    name: "Default Landing Page",
    slug: job.name
      .trim()
      .replace(/ /g, "-")
      .toLowerCase()
      .replace(/[^a-z\d\xBF-\xFF\-]/g, ""),
    locale: form.lang,
    is_published: !job.isHidden,
    content: {
      ops: [
        { insert: "\n" },
        {
          insert: {
            FormLinkEmbed: {
              color: branding.color,
              formUuid: form.uuid,
              onclick: null,
              text: "Apply now",
            },
          },
        },
      ],
    } as Delta,
  };
}

export function getDefaultStages(): PropsJson<PipelineStage>[] {
  return [
    {
      id: uuid(),
      name: t("ats.pipeline.new"),
      type: PipelineStageType.Entry,
      position: 0,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.to_be_called"),
      type: PipelineStageType.Call,
      position: 1,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.not_reached"),
      type: PipelineStageType.Call,
      position: 2,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.called"),
      type: PipelineStageType.Review,
      position: 3,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.short_meeting"),
      type: PipelineStageType.Interview,
      position: 4,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.interview"),
      type: PipelineStageType.Interview,
      position: 5,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.hire"),
      type: PipelineStageType.Hire,
      position: 6,
    },
    {
      id: uuid(),
      name: t("ats.pipeline.reject"),
      type: PipelineStageType.Rejection,
      position: 7,
    },
  ];
}
