import { Entity } from "@jobilla/entity";
import Delta from "quill-delta";
import { render } from "@/services/delta-renderer";

export type DeltaData = Delta[];

export class CompanyNote extends Entity {
  companyId!: number;
  content!: Delta;

  get htmlContent() {
    return render(this.content);
  }
}
