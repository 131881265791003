import { ResponsePromise } from "ky";
import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { User } from "@/entities/user";

export async function fetchCompanyAccountManager(companyId: number): Promise<User> {
  const json = await api
    .get(`v1/companies/${companyId}/key-account-manager`)
    .json<{ data: PartialPropsJson<User> }>();

  return EntityBuilder.buildOne(User, json.data);
}

export async function updateAccountManager(
  companyId: number,
  accountManagerId: number,
): Promise<ResponsePromise> {
  return api.put(`v1/companies/${companyId}/key-account-manager`, {
    json: {
      key_account_manager_id: accountManagerId,
    },
  });
}
