import {
  ButtonHTMLAttributes,
  createContext,
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import * as Popover from "@radix-ui/react-popover";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

const DropdownMenuContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => {
    /* noop */
  },
});

export const Trigger = Popover.Trigger;

export function Root(props: Popover.PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenuContext.Provider value={{ isOpen, setIsOpen }}>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen} {...props} />
    </DropdownMenuContext.Provider>
  );
}

export function Content({
  children,
  ...props
}: { children: ReactNode } & Popover.PopoverContentProps) {
  return (
    <Popover.Portal>
      <Popover.Content
        side="bottom"
        align="end"
        className="bg-white divide-y border rounded shadow-lg p-1.5 min-w-[160px] max-w-[320px]"
        {...props}
      >
        {children}
        <Popover.Arrow className="fill-current text-gray-200" />
      </Popover.Content>
    </Popover.Portal>
  );
}

export type ItemProps = {
  label: string;
  subtext?: string;
  icon?: ReactNode;
};

export const LinkItem = forwardRef<HTMLAnchorElement, ItemProps & LinkProps>(
  function LinkItemWithoutRef({ label, subtext, icon, className, ...props }, ref) {
    return (
      <Link
        ref={ref}
        className={clsx(
          "flex items-start gap-2 w-full py-4 px-6 text-left rounded hover:bg-purple-50",
          // Unnecessary hack but I wanted to try it out — Hakan Aktas.
          !className || className.search(/\btext-\w+-\d{3}/) === -1 ? "text-violet-700" : "",
          className,
        )}
        {...props}
      >
        {icon && <div className="-ml-2 mt-0.5">{icon}</div>}
        <div>
          <p className="text-sm font-medium">{label}</p>
          {subtext && <p className="mt-1 text-xs text-zinc-600">{subtext}</p>}
        </div>
      </Link>
    );
  },
);

export const ButtonItem = forwardRef<
  HTMLButtonElement,
  ItemProps & ButtonHTMLAttributes<HTMLButtonElement> & { autoClose?: boolean }
>(function ButtonItemWithoutRef(
  { autoClose, label, subtext, icon, className, onClick, ...props },
  ref,
) {
  const { setIsOpen } = useContext(DropdownMenuContext);

  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      autoClose !== false && setIsOpen(false);
    },
    [onClick],
  );

  return (
    <button
      ref={ref}
      className={clsx(
        "flex items-start gap-2 w-full py-4 px-6 text-left rounded hover:bg-purple-50",
        // Unnecessary hack but I wanted to try it out — Hakan Aktas.
        !className || className.search(/\btext-\w+-\d{3}/) === -1 ? "text-violet-700" : "",
        className,
      )}
      onClick={clickHandler}
      {...props}
    >
      {icon && <div className="-ml-2">{icon}</div>}
      <div>
        <p className="text-sm font-medium">{label}</p>
        {subtext && <p className="mt-1 text-xs text-zinc-600">{subtext}</p>}
      </div>
    </button>
  );
});
