import { Suspense, useEffect, useRef, useState } from "react";
import { Await, useFetcher, useLoaderData } from "react-router-dom";
import { Order } from "@/entities/order";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { OrderItem } from "@/entities/order-item";
import { OrderItemSetupStatusPill } from "@/components/order-item-setup-status-pill";
import { OrderItemProductionStatusPill } from "@/components/order-item-production-status-pill";
import PencilIcon from "@/components/icons/pencil";
import { SaveIcon } from "@/components/icons/save";
import TertiaryButton from "@/components/tertiary-button";
import TextField from "@/components/forms/text-field";
import { SpinnerIcon } from "@/components/icons/spinner";
import StyledLink from "@/components/styled-link";
import { Table, TableColumn, TableRow } from "@/components/table";
import { MoneyDisplay } from "@/components/money-display";
import { OrderItemStatus } from "@/entities/order-item-status";

export function OrderItems() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <Table
      columns={[
        { label: "Item #" },
        { label: "Invoice" },
        { label: "Setup status" },
        { label: "Production status" },
        { label: "Product" },
        { label: "Price", rightAligned: true },
        { label: "" },
      ]}
    >
      <tbody>
        {order.items.map((item) => (
          <OrderItemRow key={item.id} item={item} order={order} />
        ))}

        {order.items.length === 0 && (
          <tr>
            <td colSpan={5} className="p-4 italic">
              This order does not have any items.
            </td>
          </tr>
        )}
      </tbody>

      {order.items.length && (
        <tfoot>
          <tr className="bg-white border-t">
            <td colSpan={5} className="p-4 text-right font-bold">
              Totals
            </td>

            <td className="p-4 text-right">
              <MoneyDisplay amount={order.itemsTotal} />
            </td>
            <td className="p-4"></td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}

function OrderItemRow({ item, order }: { item: OrderItem; order: Order }) {
  const { canUserModify } = useLoaderData() as OrderDetailsData;
  // using [...order.invoices] to reverse array copy and avoid mutating original array. findLast() or toReversed() methods are supported in TS from 5+ version
  const invoice = [...order.invoices]
    .reverse()
    .find((invoice) => invoice.items.find((invoiceItem) => invoiceItem.orderItemId === item.id));

  const fetcher = useFetcher();
  const formRef = useRef<HTMLFormElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (fetcher.data?.status === 201) {
      setIsEditing(false);
    }
  }, [fetcher.data]);

  return (
    <TableRow>
      <TableColumn>
        <span className="font-bold">#{item.numericId}</span>
      </TableColumn>

      <TableColumn>
        {invoice?.numericId ? (
          <StyledLink className="font-bold" to={`/invoices/${invoice?.id}`}>
            #{invoice.numericId}
          </StyledLink>
        ) : (
          "-"
        )}
      </TableColumn>

      <TableColumn>
        {item.status == OrderItemStatus.Cancelled ? (
          "-"
        ) : item.isDistributed ? (
          <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
            Distributed to {item.sentTo ? item.sentTo.companyName : "related company"}
          </div>
        ) : (
          item.product?.isDigitalHeadhunting && (
            <OrderItemSetupStatusPill
              linkProps={{
                state: { back_to: { order: { id: order.id, numericId: order.numericId } } },
              }}
              item={item}
            />
          )
        )}
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center">
          {item.isDistributed ? (
            <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">-</div>
          ) : (
            item.product?.isDigitalHeadhunting && (
              <OrderItemProductionStatusPill
                linkProps={{
                  state: { back_to: { order: { id: order.id, numericId: order.numericId } } },
                }}
                item={item}
              />
            )
          )}
        </div>
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
          {item.product?.title ?? <em>Deleted product</em>}
        </div>
      </TableColumn>

      <TableColumn numeric rightAligned>
        {isEditing ? (
          <fetcher.Form ref={formRef} action={`/orders/${order.id}/item-price`} method="post">
            <input type="hidden" name="order_item_id" value={item.id} />
            <TextField
              inputMode="numeric"
              pattern="[0-9]+\.[0-9]*"
              name="amount"
              defaultValue={item.amount.amount / 100}
              disabled={fetcher.state !== "idle"}
              className="w-28"
              errors={fetcher.data?.errors?.amount}
              onKeyDown={(event) => {
                if (event.key === "Escape") {
                  setIsEditing(false);
                }
              }}
            />
          </fetcher.Form>
        ) : (
          <MoneyDisplay amount={item.amount} />
        )}
      </TableColumn>

      <TableColumn>
        <Suspense fallback={null}>
          <Await resolve={canUserModify}>
            {(hasPermission) => (
              <TertiaryButton
                title={!hasPermission ? "You don't have permission to edit this item" : undefined}
                disabled={
                  !hasPermission ||
                  !item.canBeEdited ||
                  !invoice?.canBeEdited ||
                  fetcher.state !== "idle"
                }
                onClick={() => (isEditing ? fetcher.submit(formRef.current) : setIsEditing(true))}
              >
                {fetcher.state !== "idle" ? (
                  <SpinnerIcon className="animate-spin w-4 h-4" />
                ) : isEditing ? (
                  <SaveIcon className="w-4 h-4" />
                ) : (
                  <PencilIcon className="w-4 h-4" />
                )}
              </TertiaryButton>
            )}
          </Await>
        </Suspense>
      </TableColumn>
    </TableRow>
  );
}
