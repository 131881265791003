import * as React from "react";
import { useCallback, useEffect } from "react";
import { useSearchParams, useSubmit } from "react-router-dom";
import { debounce } from "lodash";
import TextField from "@/components/forms/text-field";

export default function CompaniesIndexFilters() {
  const [query] = useSearchParams();
  const submit = useSubmit();
  const debouncedSubmit = debounce(submit, 500, { leading: false, trailing: true });

  const onTextChange = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      debouncedSubmit(event.currentTarget.form);
    },
    [submit],
  );

  const onFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      debouncedSubmit.cancel();
      submit(event.currentTarget);
    },
    [submit],
  );

  // We are only doing this DOM manipulation because we don't need to make the search input a
  // controlled input just for clearing it on navigation. This works completely fine, and is not
  // breaking any React functionality.
  useEffect(() => {
    if (!query.get("search")) {
      const input = document.querySelector('input[name="search"]') as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    }
  }, [query]);

  return (
    <form action="/companies" onSubmit={onFormSubmit} className="flex items-stretch">
      <div className="flex-1 relative">
        <div className="absolute inset-y-0 left-3 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-zinc-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <TextField
          type="text"
          name="search"
          autoFocus
          defaultValue={query.get("search") ?? ""}
          className="pl-10 rounded-r-none"
          onInput={onTextChange}
        />
      </div>

      <button className="relative block px-6 text-white font-medium rounded-r-md bg-purple-700 ring-1 ring-purple-700 text-sm shadow-sm transition-all duration-150 hover:shadow-md focus:z-10 focus:outline-none focus:ring focus:ring-indigo-300">
        Search
      </button>
    </form>
  );
}
