import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { Subscription } from "@/entities/subscription";
import { api } from "@/api/base";

export async function createSubscription(data: Omit<PropsJson<Subscription>, "id">) {
  const response = await api
    .post("notifications/subscriptions", {
      json: data,
    })
    .json<{ data: PropsJson<Subscription> }>();

  return EntityBuilder.buildOne(Subscription, response.data);
}
