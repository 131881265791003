import { defer, LoaderFunctionArgs } from "react-router-dom";
import { Invoice } from "@/entities/invoice";
import { Company } from "@/entities/company";
import { Billing } from "@/entities/billing";
import { BillingDetailsRequest } from "@/entities/billing-details-request";
import { OrderItem } from "@/entities/order-item";
import { Payee } from "@/entities/payee";
import { Product } from "@/entities/product";
import { Job } from "@/entities/job";
import { User } from "@/entities/user";
import { fetchBillingDetailRequest, fetchInvoice } from "@/api/invoice";
import { fetchProducts } from "@/api/product";
import { fetchJob } from "@/api/job";
import { fetchCompany } from "@/api/company";
import { fetchCompanyBillings } from "@/api/billing";
import { fetchPayees } from "@/api/payee";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { fetchOrder } from "@/api/order";
import { assertParameterExists } from "@/helpers/loader-guards";

// Exporting loader's types so that it can be referenced in subcomponents.
export type InvoiceDetailsData = {
  invoice: Invoice;
  company: Promise<Company>;
  billingDetails: Promise<Billing[]>;
  billingDetailsRequest: Promise<BillingDetailsRequest>;
  orderItems: OrderItem[];
  payees: Promise<Payee[]>;
  products: Product[];
  relatedJobs?: Promise<(Job | null)[]>;
  jobillaUsers: Promise<User[]>;
};

export async function loader({ params }: LoaderFunctionArgs): Promise<InvoiceDetailsData> {
  assertParameterExists(params.invoice);

  const [invoice, products] = await Promise.all([fetchInvoice(params.invoice), fetchProducts()]);

  // We know that `companyId` will be present here in this specific API response.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const companyId = invoice.order.companyId!;

  const order = await fetchOrder(invoice.orderId);

  const orderItems = invoice.items
    .filter((item) => item.orderItemId)
    .map((invoiceItem) => order.items.find((orderItem) => orderItem.id === invoiceItem.orderItemId))
    .filter((item): item is OrderItem => item !== undefined);

  const data = {
    invoice,
    orderItems,
    products,
    relatedJobs: Promise.all(
      orderItems.map(async (item) => {
        return item.campaignContext?.jobId ? fetchJob(item.campaignContext.jobId) : null;
      }),
    ).then((resolvedOrderItems) => resolvedOrderItems.filter((item): item is Job => item !== null)),
    company: fetchCompany(companyId),
    billingDetails: fetchCompanyBillings(companyId),
    billingDetailsRequest: fetchBillingDetailRequest(invoice.id),
    payees: fetchPayees(),
    jobillaUsers: fetchJobillaUsers(),
  };

  // We only use a separate variable for the type safety of the return value.
  // If in the future `defer` function gets a proper dynamic type, we can
  // inline `data` variable.
  return defer(data) as unknown as typeof data;
}
