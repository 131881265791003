import { ActionFunctionArgs } from "react-router-dom";
import { object, string, ValidationError } from "yup";
import { HTTPError } from "ky";
import { processYupErrors } from "@/helpers/yup-errors";
import { updateInvoiceBilling } from "@/api/invoice";
import { assertParameterExists } from "@/helpers/loader-guards";

export type InvoiceSelectBillingActionData = Awaited<ReturnType<typeof action>>;

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  const rawInput = Object.fromEntries((await request.formData()).entries());
  let input;

  try {
    input = await object({
      billing_details_id: string().required("You must choose a billing detail."),
    }).validate(rawInput, { abortEarly: false });
  } catch (e) {
    if (e instanceof ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateInvoiceBilling(params.invoice, input.billing_details_id);
    return { status: 200 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: e.response.json,
      };
    }

    return { status: 500 };
  }
}
