import { Entity } from "@jobilla/entity";
import Delta from "quill-delta";

export class LandingPage extends Entity {
  id!: number;
  companyId!: number;
  jobId!: number;
  slug!: string;
  name!: string;
  publishedAt!: string;
  isPublished = true;
  content!: Delta;
  locale!: string;
  stringIdentifier!: string;
}
