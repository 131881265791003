import { DependencyList, useCallback } from "react";
import { debounce } from "lodash";

type DebounceOptions = Parameters<typeof debounce>[2] & { wait?: number };

export function useDebouncedCallback<T extends (...args: never[]) => unknown>(
  options: DebounceOptions,
  callback: T,
  deps: DependencyList,
): ReturnType<typeof debounce<T>> {
  return useCallback(debounce(callback, options.wait, options), deps);
}
