import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { createInvoiceNote } from "@/api/invoice-note";
import { processYupErrors } from "@/helpers/yup-errors";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  let input;

  try {
    const requestData = Object.fromEntries((await request.formData()).entries());

    input = await Yup.object()
      .shape({
        content: Yup.string().required("You must provide a note"),
      })
      .validate(requestData, { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    const note = await createInvoiceNote(params.invoice, {
      content: input.content,
    });

    return {
      status: 200,
      data: note,
    };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }
}
