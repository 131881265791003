import { useLoaderData } from "react-router-dom";
import StyledLink from "@/components/styled-link";
import Date, { DateDisplayType } from "@/components/date";
import { InvoiceDetailsData } from "./loader";

export function InvoiceStatusVoid() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;

  return (
    <section className="bg-white rounded p-6 shadow-sm text-sm">
      <p className="font-bold">Invoice status</p>
      <div className="text-red-600 font-bold my-2">VOID</div>
      <p>This invoice is no longer editable or payable.</p>
      <p className="mt-6 flex justify-between">
        Voided on: {<Date date={invoice.updatedAt} display={DateDisplayType.DATE} />}
      </p>
      <p className="flex justify-between">
        Items distributed:
        {invoice.transferDetails?.transferredOrderItems.map((item) => (
          <StyledLink key={item.id} to={`/order-items/${item.id}`}>
            #{item.numericId}
          </StyledLink>
        ))}
      </p>
      <p className="flex justify-between">
        Recipient:
        <StyledLink className="ml-6" to={`/companies/${invoice.transferDetails?.toCompany?.id}`}>
          {invoice.transferDetails?.toCompany?.name}
        </StyledLink>
      </p>
      <p className="flex justify-between">
        Replacement invoice:
        {!invoice.transferDetails?.newInvoice ? (
          <span>None</span>
        ) : (
          <StyledLink className="ml-6" to={`/invoices/${invoice.transferDetails?.newInvoice?.id}`}>
            #{invoice.transferDetails?.newInvoice?.numericId}
          </StyledLink>
        )}
      </p>
      <p className="flex justify-between">
        Recipient order:
        <StyledLink className="ml-6" to={`/orders/${invoice.transferDetails?.toOrder?.id}`}>
          #{invoice.transferDetails?.toOrder?.numericId}
        </StyledLink>
      </p>
    </section>
  );
}
