import {
  LoaderFunctionArgs,
  Outlet,
  ShouldRevalidateFunction,
  useLoaderData,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { ReactNode } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import * as Page from "@/components/page";
import { fetchBusinessUnitUsers } from "@/api/unit";
import { RootLoaderData } from "@/root";
import { Table, TableColumn, TableRow } from "@/components/table";
import { User } from "@/entities/user";
import Date, { DateDisplayType } from "@/components/date";
import SecondaryLink from "@/components/secondary-link";
import { teamsAndRoles } from "@/helpers/teams-and-roles";
import {
  preventRevalidationForFailedSubmissions,
  preventRevalidationForModalClose,
  preventRevalidationForModalNavigation,
} from "@/helpers/revalidation-fns";
import { UserDropdownMenu } from "@/routes/units.$unit/user-dropdown";
import { assertParameterExists } from "@/helpers/loader-guards";

export type UnitPageLoaderData = {
  users: User[];
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.unit);

  return {
    users: await fetchBusinessUnitUsers(params.unit),
  } satisfies UnitPageLoaderData;
}

export function shouldRevalidate(params: Parameters<ShouldRevalidateFunction>[0]) {
  return (
    preventRevalidationForModalClose(params, "unit") ??
    preventRevalidationForModalNavigation(params, "unit") ??
    preventRevalidationForFailedSubmissions(params) ??
    params.defaultShouldRevalidate
  );
}

export default function UnitPage() {
  const { units } = useRouteLoaderData("root") as RootLoaderData;
  const { users } = useLoaderData() as UnitPageLoaderData;
  const params = useParams();

  const unit = units.find((unit) => unit.id === params.unit);

  if (!unit) {
    throw { status: 404 };
  }

  return (
    <Page.Root>
      <Page.Heading>
        <Page.Title>{unit.name}</Page.Title>
      </Page.Heading>

      <Page.Main>
        {teamsAndRoles.map((team) => (
          <UnitTeam key={team.id} name={team.name} teamId={team.id ?? undefined}>
            {team.roles.map((role) => (
              <UnitTeamRole
                key={role.id}
                title={role.name}
                roleName={role.userTitle ?? ""}
                users={users.filter((user) => user.role === role.id)}
              />
            ))}
          </UnitTeam>
        ))}
      </Page.Main>

      <Outlet />
    </Page.Root>
  );
}

function UnitTeam({
  children,
  name,
  teamId,
}: {
  children: ReactNode;
  name: string;
  teamId?: string;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <p className="text-2xl font-medium">{name}</p>

        {teamId && (
          <SecondaryLink to={`invite?team=${teamId}`} preventScrollReset>
            <PlusIcon className="w-5 h-5" />
            <span>Invite a new user</span>
          </SecondaryLink>
        )}
      </div>

      <Table>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
}

function UnitTeamRole({
  roleName,
  title,
  users,
}: {
  roleName: string;
  title: string;
  users: User[];
}) {
  const { units } = useRouteLoaderData("root") as RootLoaderData;
  const params = useParams();

  // We can safely assume that the unit exists, because we already checked it in the parent component.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const unit = units.find((unit) => unit.id === params.unit)!;

  return (
    <>
      <TableRow>
        <td colSpan={4} className="p-4 bg-gray-100">
          <p className="font-bold">{title}</p>
        </td>
      </TableRow>

      {users.map((user) => (
        <TableRow key={user.id}>
          <TableColumn>
            <div className="flex items-center gap-2">
              <div
                className={clsx(
                  "flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full tracking-tight font-bold",
                  user.initials.length > 2 ? "text-xs" : "text-sm",
                )}
              >
                {user.initials}
              </div>

              <div>
                <p className="font-semibold">{user.name}</p>
                <p className="text-sm text-slate-500">{user.email}</p>
              </div>
            </div>
          </TableColumn>

          <TableColumn>{roleName}</TableColumn>

          <TableColumn>
            {user.setupCompletedAt && (
              <>
                {user.lastSeen && (
                  <>
                    Seen <Date date={user.lastSeen} display={DateDisplayType.RELATIVE} />
                  </>
                )}
                <p className="text-xs text-zinc-500">
                  Joined <Date date={user.setupCompletedAt} display={DateDisplayType.DATE} />
                </p>
              </>
            )}

            {user.isPendingInvite && <span className="text-amber-600">Invitation pending</span>}
            {user.isExpiredInvite && <span className="text-red-500">Invitation expired</span>}
          </TableColumn>

          <TableColumn rightAligned>
            <UserDropdownMenu user={user} unit={unit} />
          </TableColumn>
        </TableRow>
      ))}

      {users.length === 0 && (
        <TableRow>
          <TableColumn colSpan={4}>
            <p className="py-6 italic text-gray-400">This list is empty.</p>
          </TableColumn>
        </TableRow>
      )}
    </>
  );
}
