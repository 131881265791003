import { useContext } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { useRouteLoaderData } from "react-router-dom";
import { UserDropdownMenuContext } from "@/routes/units.$unit/user-dropdown";
import { Confirmation } from "@/components/confirmation";
import * as DropdownMenu from "@/components/dropdown-menu";
import SelectBox from "@/components/forms/select-box";
import { RootLoaderData } from "@/root";

export function ChangeUnit() {
  const { user } = useContext(UserDropdownMenuContext);

  return (
    <Confirmation
      formAction={`/api/users/${user.id}/unit`}
      title="Move to another unit"
      content={<DialogContents />}
      submitLabel={`Move ${user.name} to selected unit`}
    >
      <DropdownMenu.ButtonItem
        autoClose={false}
        icon={<ChevronDoubleRightIcon className="w-4 h-4" />}
        label="Move to another unit..."
        subtext="Send this user to another unit. Their role will remain the same."
      />
    </Confirmation>
  );
}

function DialogContents() {
  const { units } = useRouteLoaderData("root") as RootLoaderData;

  return (
    <SelectBox label="Destination unit" name="destination">
      {units.map((unit) => (
        <option key={unit.id} value={unit.id}>
          {unit.name}
        </option>
      ))}
    </SelectBox>
  );
}
