import { useSearchParams } from "react-router-dom";
import type { Company } from "@/entities/company";
import { Paginated } from "@/api/base";
import { Paginator } from "@/components/paginator";
import { Table, TableColumn, TableRow } from "@/components/table";
import EyeIcon from "@/components/icons/eye";
import StyledLink from "@/components/styled-link";
import SecondaryLink from "@/components/secondary-link";

type CompanyListTableProps = {
  isLoading: boolean;
  companies: Paginated<Company[]>;
};

export default function CompanyListTable({ isLoading, companies }: CompanyListTableProps) {
  const [searchParams] = useSearchParams();

  return (
    <div className="relative">
      <Table
        columns={[{ label: "Company" }, { label: "Actions", minimal: true, rightAligned: true }]}
      >
        <tbody>
          {companies.data.map((company) => (
            <TableRow key={company.id}>
              <TableColumn>
                <StyledLink
                  to={`/companies/${company.id}${
                    searchParams.has("search")
                      ? `?company_search=${searchParams.get("search")}`
                      : ""
                  }`}
                >
                  {company.name}
                </StyledLink>
              </TableColumn>

              <TableColumn rightAligned>
                <div className="flex items-center justify-center gap-4">
                  <SecondaryLink to={`/companies/${company.id}`} size="sm-square">
                    <EyeIcon className="w-4 h-4 inline-block" />
                  </SecondaryLink>
                </div>
              </TableColumn>
            </TableRow>
          ))}

          {!companies.data.length && (
            <tfoot>
              <tr className="border-t">
                <TableColumn colSpan={2}>
                  {searchParams.has("search") ? (
                    <p className="italic text-sm">
                      Could not find any companies with the given criteria.
                    </p>
                  ) : (
                    <p className="italic text-sm">There are no companies to show</p>
                  )}
                </TableColumn>
              </tr>
            </tfoot>
          )}
        </tbody>
      </Table>

      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-40 text-center py-12">
          <p className="font-bold text-2xl">Loading...</p>
        </div>
      )}

      {companies.data.length > 0 && (
        <div className="mt-8">
          <Paginator
            currentPage={Number(searchParams.get("page") ?? 1)}
            lastPage={companies.meta.last_page}
            baseUrl="/companies"
          />
        </div>
      )}
    </div>
  );
}
