import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from "react-router-dom";
import { EntityBuilder } from "@jobilla/entity";
import { HTTPError } from "ky";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { Dialog } from "@/components/dialog";
import type { CompanyDetailsData } from "@/routes/companies.$company/loader";
import SubmitButton from "@/components/submit-button";
import SecondaryButton from "@/components/secondary-button";
import TextField from "@/components/forms/text-field";
import { updateCompany } from "@/api/company";
import { Company } from "@/entities/company";
import { processYupErrors } from "@/helpers/yup-errors";
import { useActiveActionData } from "@/hooks/useActiveActionData";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterIsNumeric } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterIsNumeric(params.company);

  let input;

  try {
    const requestData = Object.fromEntries((await request.formData()).entries());

    input = await Yup.object()
      .shape({
        name: Yup.string().required(),
      })
      .validate(requestData, { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    const companyEntity = EntityBuilder.buildOne(Company, input);

    await updateCompany(Number(params.company), companyEntity);

    return redirect(`/companies/${params.company}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }
}

export default function NameDialog() {
  const { company } = useRouteLoaderData("company-details") as CompanyDetailsData;
  const transition = useNavigation();
  const navigate = useNavigate();
  const actionResult = useActiveActionData() as Exclude<
    Awaited<ReturnType<typeof action>>,
    Response
  >;
  const [inputValue, setInputValue] = useState(company.name);

  const goBackToCompanyDetails = useCallback(() => navigate(`/companies/${company.id}`), []);

  // Linking is successful when the action result was a redirect.
  const isSuccessfullyLinked =
    transition.location?.pathname && transition.location.pathname !== location.pathname;

  const isSubmitting = transition.state === "submitting";
  const hasServerError = actionResult?.status === 500;

  return (
    <Dialog isOpen onClose={goBackToCompanyDetails} title="Edit Company">
      <Form method="post">
        <input type="hidden" name="_form" value="update-company-name" />

        <fieldset disabled={isSubmitting} className="flex flex-col space-y-1">
          <label htmlFor="edit_form_company_name" className="font-bold">
            Company Name
          </label>
          <TextField
            name="name"
            id="edit_form_company_name"
            defaultValue={inputValue}
            onInput={(e) => setInputValue(e.currentTarget.value)}
            errors={actionResult?.errors?.name}
          />
        </fieldset>

        {hasServerError && <ErrorBlock>An unexpected error occurred. Please try again.</ErrorBlock>}

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton
            size="lg"
            labelWhenSubmitting={isSuccessfullyLinked ? "✅ Done!" : "Saving..."}
            isSubmitting={isSuccessfullyLinked || isSubmitting}
            disabled={inputValue === company.name}
          />

          <SecondaryButton type="button" size="lg" onClick={goBackToCompanyDetails}>
            Close
          </SecondaryButton>
        </div>
      </Form>
    </Dialog>
  );
}
