import { useLoaderData } from "react-router-dom";
import * as DL from "@/components/definition-list";
import { StyledAnchor } from "@/components/styled-anchor";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";

export function ContactPerson() {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  return (
    <DL.Root>
      <DL.Item label="Name">{orderItem.campaignContext?.contactPerson?.name}</DL.Item>

      <DL.Item label="Email address">
        {orderItem.campaignContext?.contactPerson?.email && (
          <StyledAnchor href={`mailto: ${orderItem.campaignContext?.contactPerson?.email}`}>
            {orderItem.campaignContext?.contactPerson?.email}
          </StyledAnchor>
        )}
      </DL.Item>

      <DL.Item label="Phone number">{orderItem.campaignContext?.contactPerson?.phone}</DL.Item>
    </DL.Root>
  );
}
