import { useLoaderData } from "react-router-dom";
import { AvailabilitySummary } from "@/entities/inventory-summary";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { Token } from "@/entities/token";
import type { CompanyDetailsData } from "./loader";

export default function Booster() {
  const { tokens, inventorySummary } = useLoaderData() as CompanyDetailsData;

  return (
    <section>
      {
        tokens instanceof FailedOptionalResult
       || inventorySummary instanceof FailedOptionalResult
      ? (
        <ErrorState />
      ) : (
        <div className="grid grid-cols-3 gap-2">
          <BoosterBox
            tokens={tokens.filter((token) => token.type === "range_booster")}
            boosterSummary={inventorySummary.rangeBooster}
            title="Range boosters"
          />
          <BoosterBox
            tokens={tokens.filter((token) => token.type === "duration_booster")}
            boosterSummary={inventorySummary.durationBooster}
            title="Duration boosters"
          />
          <BoosterBox
            tokens={tokens.filter((token) => token.type === "contact_booster")}
            boosterSummary={inventorySummary.contactBooster}
            title="Contacting boosters"
          />
        </div>
      )}
    </section>
  );
}

export function BoosterBox({ title, tokens, boosterSummary}: {
  title: string,
  tokens: Token[],
  boosterSummary?:  AvailabilitySummary,
}) {
  return (
    <div className="flex flex-col bg-white shadow-sm rounded-lg pt-6 pb-8 px-6 w-full mr-xxs">
      <p className="flex-1 font-bold text-lg">{title}</p>
      <p className={`mt-8 font-medium text-2xl ${tokens.length === 0 ? "text-red-600" : ""}`}>
        {boosterSummary?.available ?? 0}
      </p>
      <p className={boosterSummary?.available=== 0 ? "text-red-600" : "text-slate-500"}>boosters remaining</p>
    </div>
  );
}

export function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load booster.</p>
    </div>
  );
}
