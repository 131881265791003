import { Entity, Type } from "@jobilla/entity";
import { PayeeAddress } from "./payee-address";

export class Payee extends Entity {
  id!: string;
  name!: string;
  iban!: string;
  bic!: string;
  vat!: string;
  vatPercentage!: number;
  bankName!: string;

  @Type(PayeeAddress)
  address!: PayeeAddress;
}
