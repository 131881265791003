import type { AnchorHTMLAttributes } from "react";

export type StyledAnchorArgs = AnchorHTMLAttributes<HTMLAnchorElement>;

export function StyledAnchor({ children, ...props }: StyledAnchorArgs) {
  const className = [
    "underline decoration-indigo-200 decoration-2 text-violet-900 font-medium underline-offset-1",
    props.className ? props.className : "",
  ].join(" ");

  return (
    <a {...props} className={className}>
      {children}
    </a>
  );
}
