import { Link, LinkProps } from "react-router-dom";

type TertiaryButtonProps = LinkProps & {
  disabled?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
};

export default function TertiaryLink({
  children,
  size = "md",
  disabled,
  ...props
}: TertiaryButtonProps) {
  const padding = {
    sm: "p-1.5",
    md: "p-2",
    lg: "p-3",
    xl: "p-4",
  };

  const baseClasses = [
    "inline-block",
    padding[size],
    "bg-zinc-200 text-zinc-700 text-sm font-medium rounded-lg leading-none",
    "transition duration-200",
    "hover:bg-zinc-300",
  ];

  if (disabled) {
    return (
      <span className={[...baseClasses, "opacity-40 cursor-not-allowed"].join(" ")}>
        {children}
      </span>
    );
  }

  return (
    <Link {...props} className={[...baseClasses].join(" ")}>
      {children}
    </Link>
  );
}
