import { Entity } from "@jobilla/entity";

export class BillingDetailsRequest extends Entity {
  invoiceId!: string;
  billingDetailsId!: string;
  type!: string;
  lang!: string;
  sourceId!: string;
  sourceType!: string;
  confirmed!: boolean;
  validityDays = 30;
  createdAt!: string;
  expiresAt!: string;
}
