import { EntityBuilder, PartialPropsJson, PropsJson } from "@jobilla/entity";
import { OrderItem } from "@/entities/order-item";
import { api, Paginated } from "@/api/base";

export interface OrderItemsFilter {
  product?: string;
  unit_id?: string;
  company_id?: string;
  statuses?: string[];
  per_page?: number;
  current_page?: number;
  order_by?: "created_at" | "campaign_created_at";
  order?: "asc" | "desc";
}

export async function fetchOrderItem(id: string): Promise<OrderItem> {
  const json = await api.get(`v1/orders/items/${id}`).json<{ data: PropsJson<OrderItem> }>();

  return EntityBuilder.buildOne(OrderItem, json.data);
}

export async function updateOrderItem(
  id: string,
  data: PartialPropsJson<OrderItem> & { amount?: number },
) {
  return api.put(`v1/orders/items/${id}`, { json: data });
}

export async function listOrderItems(filters: OrderItemsFilter): Promise<Paginated<OrderItem[]>> {
  const searchParams: Record<string, string | number> = {
    per_page: filters.per_page ?? 25,
  };

  if (filters?.current_page) {
    searchParams["page"] = filters.current_page;
  }
  if (filters?.company_id) {
    searchParams["company_id"] = filters.company_id;
  }
  if (filters?.unit_id) {
    searchParams["unit_id"] = filters.unit_id;
  }
  if (filters?.product) {
    searchParams["product"] = filters.product;
  }
  if (filters?.order_by) {
    searchParams["order_by"] = filters.order_by;
  }
  if (filters?.order) {
    searchParams["order"] = filters.order;
  }
  filters?.statuses?.forEach((status, index) => {
    searchParams[`statuses[${index}]`] = status;
  });
  const response = await api
    .get("v1/orders/items", { searchParams: searchParams })
    .json<Paginated<PropsJson<OrderItem>[]>>();

  return {
    ...response,
    data: EntityBuilder.buildMany(OrderItem, response.data),
  };
}
