import { useLoaderData } from "react-router-dom";
import { Children, ReactNode } from "react";
import type { CompanyDetailsData } from "@/routes/companies.$company/loader";
import { FailedOptionalResult } from "@/helpers/loader-optional";

export default function UnspentCampaigns() {
  const {
    tokens,
    orders,
    inventorySummary,
  } = useLoaderData() as CompanyDetailsData;

  if (
    tokens instanceof FailedOptionalResult
   || orders instanceof FailedOptionalResult
   || inventorySummary instanceof FailedOptionalResult
  ) {
    return <ErrorState />;
  }

  const campaignTokens = tokens.filter((token) => ["campaign", "campaign_pro", "campaign_lite"].includes(token.type));

  return (
    <ComponentSkeleton>
      <div className={`mt-8 ${!campaignTokens.length && "text-red-600"}`}>
        <p className="font-bold text-4xl">{inventorySummary.campaign.availableCampaigns}</p>
        <p className="mt-2 opacity-75">Campaign{inventorySummary.campaign.availableCampaigns !== 1 && "s"} remaining</p>
      </div>
    </ComponentSkeleton>
  );
}

function ComponentSkeleton({ children }: { children: ReactNode }) {
  return (
    <section className="bg-white shadow-sm rounded-lg pt-6 pb-8 px-6">
      <p className="font-bold text-lg">Unspent campaigns</p>
      {Children.toArray(children)}
    </section>
  );
}

function ErrorState() {
  return (
    <ComponentSkeleton>
      <div className="mt-8 text-red-600">
        <p className="font-bold text-4xl">N/A</p>
        <p className="mt-2 opacity-75">Could not load tokens.</p>
      </div>
    </ComponentSkeleton>
  );
}
