import type { PartialPropsJson, PropsJson } from "@jobilla/entity";
import { EntityBuilder } from "@jobilla/entity";
import { Invoice } from "@/entities/invoice";
import { BillingDetailsRequest } from "@/entities/billing-details-request";
import { api, Paginated } from "@/api/base";
import { Billing } from "@/entities/billing";

export type InvoiceFetchArgs = {
  current_page?: number;
  status?: string;
  per_page?: number;
  unit?: string;
  order_direction?: string | null;
};

export async function fetchInvoices(args: InvoiceFetchArgs): Promise<Paginated<Invoice[]>> {
  const searchParams: Record<string, string | number> = {
    page: args.current_page ?? 1,
    per_page: args.per_page ?? 25,
    order_direction: args.order_direction ?? "desc",
  };
  if (args.unit) {
    searchParams["units[0]"] = args.unit;
  }
  if (args.status) {
    searchParams["status"] = args.status;
  }
  const response = await api
    .get("v1/invoices", {
      searchParams,
    })
    .json<Paginated<PropsJson<Invoice>[]>>();

  return {
    ...response,
    data: EntityBuilder.buildMany(Invoice, response.data),
  };
}

export async function fetchInvoice(id: string): Promise<Invoice> {
  const json = await api.get(`v1/invoices/${id}`).json<{ data: PropsJson<Invoice> }>();

  return EntityBuilder.buildOne(Invoice, json.data);
}

export async function fetchBillingDetailRequest(id: string): Promise<BillingDetailsRequest> {
  const json = await api
    .get(`v1/invoices/${id}/billing-details-request`)
    .json<{ data: PropsJson<BillingDetailsRequest> }>();
  return EntityBuilder.buildOne(BillingDetailsRequest, json.data);
}

export async function updateInvoiceStatus(invoiceId: string, status: PropsJson<Invoice>["status"]) {
  return api.put(`v1/invoices/${invoiceId}/status`, {
    json: {
      status,
    },
  });
}

export async function updateInvoicePayee(invoiceId: string, payeeId: string) {
  return api.put(`v1/invoices/${invoiceId}/payee`, {
    json: {
      payee: {
        id: payeeId,
      },
    },
  });
}

export async function updateInvoicePaymentTerm(
  invoiceId: string,
  paymentTerm: PropsJson<Invoice>["payment_term"],
) {
  return api.put(`v1/invoices/${invoiceId}/payment-term`, {
    json: {
      payment_term: paymentTerm,
    },
  });
}

export async function updateInvoiceBilling(invoiceId: string, billingId: Billing["id"]) {
  return api.put(`v1/invoices/${invoiceId}/customer`, {
    json: {
      customer: {
        id: billingId,
      },
    },
  });
}

export async function updateInvoice(invoiceId: string, data: PartialPropsJson<Invoice>) {
  return api.patch(`v1/invoices/${invoiceId}`, {
    json: data,
  });
}

export async function sendNetsuite(invoiceId: string, overrideEinvoice: boolean) {
  return api.put(`v1/invoices/${invoiceId}/netsuite`, {
    json: {
      override_einvoice: overrideEinvoice,
    },
  });
}
