import { useLoaderData } from "react-router-dom";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { TrashCan } from "@/components/icons/trash-can";
import SecondaryLink from "@/components/secondary-link";

export function OrderCancellation() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <section className="bg-white rounded shadow-sm p-6">
      <div className="flex justify-between items-center">
        <p className="flex items-center text-lg font-medium space-x-2">
          <TrashCan className="w-4 h-4 text-zinc-500" />
          <span>Cancel this order</span>
        </p>

        <div>
          <SecondaryLink
            disabled={!order.isCancellable}
            to={`/orders/${order.id}/cancel`}
            preventScrollReset
          >
            Cancel order
          </SecondaryLink>
        </div>
      </div>

      <p className="text-sm mt-6">
        {order.isCancellable
          ? "Cancels this order and removes the bought services from customers inventory."
          : "Order cannot be canceled because some of its items are already consumed or distributed to another company."}
      </p>
    </section>
  );
}
