import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { User } from "@/entities/user";
import { UserInvite } from "@/entities/user-invite";

export type FetchJobillaUsersFilters = {
  has_order?: boolean;
  search?: string;
  team?: string;
  unit?: string;
};

export async function fetchJobillaUsers(filters: FetchJobillaUsersFilters = {}) {
  const response = await api
    .get("v1/users/jobilla", {
      searchParams: filters,
    })
    .json<{ data: User[] }>();

  return EntityBuilder.buildMany(User, response.data);
}

export async function inviteUser(data: Pick<PropsJson<User>, "name" | "email" | "language">) {
  const response = await api
    .post("api/v2/user/jobilla/invite", {
      json: data,
    })
    .json<{ data: PropsJson<UserInvite> }>();

  return EntityBuilder.buildOne(UserInvite, response.data);
}

export async function reinviteUser(id: User["id"]) {
  const response = await api
    .post(`api/v2/user/jobilla/reinvite/${id}`)
    .json<{ data: PropsJson<UserInvite> }>();

  return EntityBuilder.buildOne(UserInvite, response.data);
}

export async function cancelUserInvite(id: User["id"]) {
  return api.delete(`api/v2/user/jobilla/${id}/invites`);
}
