import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

export function Root({ children }: PropsWithChildren<NonNullable<unknown>>) {
  return (
    <div className="@container">
      <div
        className={clsx(
          "w-full text-sm",
          "flex flex-col @sm:grid @sm:items-start",
          "gap-3",
          "grid-cols-[[left]_clamp(150px,_40%_,250px)_[center]_1fr_[right]]",
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function Item({ label, children }: PropsWithChildren<{ label: ReactNode }>) {
  return (
    <div className="@sm:contents">
      <div className="text-zinc-700 font-medium">{label}</div>
      <div>{children}</div>
    </div>
  );
}
