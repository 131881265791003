import { EntityBuilder, PartialPropsJson, PropsJson } from "@jobilla/entity";
import { Order } from "@/entities/order";
import { api, Paginated } from "./base";

export type OrderFetchArgs = {
  current_page?: number;
  invoice_status?: string;
  seller?: string | number;
  per_page?: number;
  country?: string;
  to?: string;
  from?: string;
};

export async function fetchOrders(args: OrderFetchArgs): Promise<Paginated<Order[]>> {
  const searchParams: Record<string, string | number> = {
    per_page: args.per_page ?? 25,
  };
  if (args.current_page) {
    searchParams["page"] = args.current_page;
  }
  if (args.country) {
    searchParams["country"] = args.country;
  }
  if (args.seller) {
    searchParams["seller"] = args.seller;
  }
  if (args.invoice_status) {
    searchParams["invoice_status"] = args.invoice_status;
  }
  if (args.from && args.to) {
    searchParams["date[from]"] = args.from;
    searchParams["date[to]"] = args.to;
  }
  const response = await api
    .get("v1/orders", {
      searchParams,
    })
    .json<Paginated<PropsJson<Order>[]>>();

  return {
    ...response,
    data: EntityBuilder.buildMany(Order, response.data),
  };
}

export async function fetchOrder(id: string): Promise<Order> {
  const json = await api.get(`v1/orders/${id}`).json<{ data: PropsJson<Order> }>();

  return EntityBuilder.buildOne(Order, json.data);
}

export async function createOrder(data: PartialPropsJson<Order>): Promise<Order> {
  const json = await api.post(`v1/orders`, { json: data }).json<{ data: PropsJson<Order> }>();
  return EntityBuilder.buildOne(Order, json.data);
}

export async function updateOrder(id: string, data: PartialPropsJson<Order>) {
  return api.patch(`v1/orders/${id}`, { json: data });
}

export async function cancelOrder(id: string) {
  return api.delete(`v1/orders/${id}`);
}
