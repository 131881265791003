import { StyledAnchor } from "@/components/styled-anchor";
import { PrimaryLink } from "@/components/primary-link";

export type Error404Props = {
  title: string;
};

export default function Error404({ title }: Error404Props) {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="max-w-3xl mx-8 text-center flex flex-col gap-4">
        <p className="text-9xl font-black text-slate-200 animate-pulse">404</p>
        <p className="text-3xl font-black text-slate-800">{title}</p>
        <p className="text-slate-600">We could not find any data for this URL.</p>
        <p className="text-slate-600">
          If you think there should definitely be something here,
          <br />
          please let us know in{" "}
          <StyledAnchor
            href="slack://channel?team=T0CELLC6A&id=C02Q5AFRMCZ"
            className="whitespace-nowrap"
          >
            #product-forum-help
          </StyledAnchor>
          , and we will look into it.
        </p>

        <div className="mt-8">
          <PrimaryLink to="/" size="lg">
            Go to home page
          </PrimaryLink>
        </div>
      </div>
    </div>
  );
}
