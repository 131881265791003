import * as Tooltip from "@radix-ui/react-tooltip";
import JobillaLogo from "@/components/jobilla-logo-white";
import { UserPopover } from "@/components/sidebar/toolbar/user-popover";
import { TooltipBox } from "@/components/tooltip-box";
import { LoadingIndicator } from "@/components/loading-indicator";

export default function Toolbar() {
  return (
    <div className="w-16 flex flex-col bg-blue-600 space-y-4">
      <Tooltip.Root delayDuration={0} disableHoverableContent>
        <Tooltip.Trigger asChild>
          <a
            href={import.meta.env.VITE_TAS_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="block text-white h-16 w-16 p-4 transition duration-150 hover:bg-opacity-40 hover:bg-white"
          >
            <JobillaLogo />
          </a>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content asChild side="right" sideOffset={2}>
            <TooltipBox>Open TAS in new tab</TooltipBox>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>

      <LoadingIndicator />

      <div className="flex-1" />

      <UserPopover />
    </div>
  );
}
