import {
  ActionFunctionArgs,
  Await,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { HTTPError } from "ky";
import * as Yup from "yup";
import { Suspense, useCallback } from "react";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import SelectBox from "@/components/forms/select-box";
import { fetchPayees } from "@/api/payee";
import SecondaryButton from "@/components/secondary-button";
import { updateInvoicePayee } from "@/api/invoice";
import type { InvoiceDetailsData } from "@/routes/invoices.$invoice/loader";
import { Payee } from "@/entities/payee";
import { ErrorBlock } from "@/components/error-block";
import { ValidatedForm } from "@/components/forms/validated-form";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function loader() {
  return {
    payees: await fetchPayees(),
  };
}

const formSchema = Yup.object()
  .shape({
    payee: Yup.string().required().uuid(),
  })
  .defined();

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.invoice);

  const data = Object.fromEntries(await request.formData()) as Yup.InferType<typeof formSchema>;

  try {
    await updateInvoicePayee(params.invoice, data.payee);

    return redirect(`/invoices/${params.invoice}`);
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return {
      status: 500,
    };
  }
}

export default function PayeeDialog() {
  const { invoice } = useRouteLoaderData("invoice-details") as InvoiceDetailsData;
  const { payees } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const actionResult = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;

  const goBackToInvoiceDetails = useCallback(() => navigate(`/invoices/${invoice.id}`), []);
  const hasUnexpectedErrors = actionResult?.status === 500;

  return (
    <Dialog isOpen onClose={goBackToInvoiceDetails} title="Change invoice payee">
      <ValidatedForm method="post" formSchema={formSchema}>
        <fieldset disabled={false} className="flex flex-col space-y-1">
          <Suspense
            fallback={
              <SelectBox name="payee" disabled label="Payee">
                <option>Loading options...</option>
              </SelectBox>
            }
          >
            <Await resolve={payees}>
              {(resolvedPayees: Payee[]) => (
                <SelectBox name="payee" label="Payee" defaultValue={invoice.payeeId}>
                  {resolvedPayees.map((payee) => (
                    <option key={payee.id} value={payee.id}>
                      {payee.name}
                    </option>
                  ))}
                </SelectBox>
              )}
            </Await>
          </Suspense>
        </fieldset>

        {hasUnexpectedErrors && (
          <ErrorBlock className="mt-6">
            Could not change the payee of the invoice. Please try again. If the problem persists,
            contact support.
          </ErrorBlock>
        )}

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton size="lg" />

          <SecondaryButton type="button" onClick={goBackToInvoiceDetails} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </ValidatedForm>
    </Dialog>
  );
}
