import { ActionFunctionArgs } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { HTTPError } from "ky";
import { deleteInvoiceNote } from "@/api/invoice-note";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.note);

  if (request.method !== "DELETE") {
    return {
      status: 405,
    };
  }

  try {
    await deleteInvoiceNote(params.note);
    return { status: 201 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return { status: 422, errors: await e.response.json() };
    }

    Sentry.captureException(e);

    return { status: 500 };
  }
}
