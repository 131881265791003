import { Entity, Type } from "@jobilla/entity";
import { Unit } from "./unit";

export class User extends Entity {
  id!: number;
  name!: string;
  email!: string;
  language!: string;
  cityId!: number;
  source!: string;
  profileImage!: string;
  createdAt!: string;
  lastSeen!: string | null;
  role?: string;
  setupCompleted = false;
  setupCompletedAt?: string;
  isInvitationExpired = false;

  hubspotId!: number;

  @Type(Unit)
  unit?: Unit;

  get initials() {
    return this.name
      .split(" ")
      .map((word) => word[0])
      .slice(0, 3)
      .join("")
      .toUpperCase();
  }

  get displayName() {
    return this.name ?? this.email;
  }

  get isPendingInvite() {
    return !this.isInvitationExpired && !this.setupCompletedAt;
  }

  get isExpiredInvite() {
    return this.isInvitationExpired && !this.setupCompletedAt;
  }
}
