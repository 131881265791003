import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import useMeasure from "react-use-measure";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Label } from "@/components/forms/label";
import TextField from "@/components/forms/text-field";
import { RichRadio } from "@/components/forms/rich-radio";
import { CampaignRequestPageLoaderData } from "@/routes/order-items.$orderItem.campaign-request/route";
import { useActionErrors } from "@/hooks/useActionErrors";

export function AdditionalInformation() {
  const { orderItem } = useLoaderData() as CampaignRequestPageLoaderData;
  const actionErrors = useActionErrors();
  const [isOpen, setIsOpen] = useState(false);
  const controls = useAnimationControls();
  const [measureRef, { height }] = useMeasure();

  useEffect(() => {
    controls.start({
      height: isOpen ? height + 24 /* 8px py + 16px margin hack */ : 0,
    });
  }, [isOpen]);

  return (
    <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
      <Collapsible.Trigger asChild>
        <div className="flex items-baseline space-x-2 cursor-pointer">
          <span className="inline-block text-lg [[data-state=open]_&]:rotate-90 transition duration-200">
            &#x25B8;
          </span>
          <span className="[[data-state=open]_&]:hidden">Additional information</span>
          <span className="[[data-state=closed]_&]:hidden">Hide additional information</span>
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content asChild forceMount>
        <motion.div className="overflow-hidden p-1 -m-1" initial={{ height: 0 }} animate={controls}>
          {/* This is a small margin hack. We use this instead of an actual margin so that slide animation does not jump during sliding the top margin */}
          <div className="h-4" />

          <div ref={measureRef}>
            <Label>Contact person</Label>
            <p className="text-stone-500 text-sm">
              Who do we send the campaign draft to for review?
            </p>

            <div className="mt-6 flex flex-col space-y-6">
              <TextField
                label="Name"
                name="contact_name"
                defaultValue={orderItem.campaignContext?.contactPerson?.name}
              />

              <TextField
                label="Email address"
                type="email"
                name="contact_email"
                defaultValue={orderItem.campaignContext?.contactPerson?.email}
              />

              <TextField
                label="Phone number"
                name="contact_phone"
                defaultValue={orderItem.campaignContext?.contactPerson?.phone}
              />

              <div>
                <Label>Media source</Label>

                <div className="mt-1 space-y-3">
                  <RichRadio
                    name="media_source"
                    value=""
                    defaultChecked={!orderItem.campaignContext?.mediaSource}
                  >
                    <p className="font-bold">None</p>
                    <p>No media source is available.</p>
                  </RichRadio>

                  <RichRadio
                    name="media_source"
                    value="customer_website"
                    defaultChecked={orderItem.campaignContext?.mediaSource === "customer_website"}
                  >
                    <p className="font-bold">Customer website</p>
                    <p>Source the ad picture(s) from the customer&apos;s website.</p>
                  </RichRadio>

                  <RichRadio
                    name="media_source"
                    value="photo_bank"
                    defaultChecked={orderItem.campaignContext?.mediaSource === "photo_bank"}
                  >
                    <p className="font-bold">Photo bank</p>
                    <p>Use pictures that are related to the job from a photo bank.</p>
                  </RichRadio>

                  <RichRadio
                    name="media_source"
                    value="direct"
                    defaultChecked={orderItem.campaignContext?.mediaSource === "direct"}
                  >
                    <p className="font-bold">Provided by customer</p>
                    <p>
                      Pictures are provided by customers and delivered to Jobilla customer service
                      email.
                    </p>
                  </RichRadio>
                </div>

                {actionErrors?.media_source?.map((errorText: string) => (
                  <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
                    {errorText}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
