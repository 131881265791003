import { useMemo } from "react";
import { Unit } from "@/entities/unit";
import { languages } from "@/helpers/public-languages";

type UnitDefaults = {
  locale: (typeof languages)[number]["key"];
  recruitmentCareerSiteVisibility: boolean;
};

const listOfUnitDefaults: Record<Unit["name"], UnitDefaults> = {
  Helsinki: {
    locale: "fi-FI",
    recruitmentCareerSiteVisibility: false,
  },
  Apeldoorn: {
    locale: "nl-NL",
    recruitmentCareerSiteVisibility: true,
  },
  Frankfurt: {
    locale: "de-DE",
    recruitmentCareerSiteVisibility: true,
  },
  Cologne: {
    locale: "de-DE",
    recruitmentCareerSiteVisibility: true,
  },
  Berlin: {
    locale: "de-DE",
    recruitmentCareerSiteVisibility: true,
  },
};

const fallbackUnitDefaults: UnitDefaults = {
  locale: "en-GB",
  recruitmentCareerSiteVisibility: false,
};

export function useUnitDefaults(unit?: Unit) {
  return useMemo(() => getUnitDefaults(unit), [unit]);
}

function getUnitDefaults(unit?: Unit) {
  if (!unit) {
    // We don't normally do permanent debug logs, but I want to give it a try here and see how it
    // works with Sentry reports and Datadog and whatnot. — Hakan.
    console.debug("No unit provided, using fallback unit defaults.");
    return fallbackUnitDefaults;
  }

  const unitDefaults = listOfUnitDefaults[unit.name];

  if (!unitDefaults) {
    console.debug(`No unit defaults found for unit "${unit.name}", using fallback unit defaults.`);
    return fallbackUnitDefaults;
  }

  console.debug(`Using unit defaults for unit "${unit.name}".`, unitDefaults);
  return unitDefaults;
}
