import { useEffect, useState } from "react";
import { Link, useFetcher, useLoaderData, useParams } from "react-router-dom";
import { uniqBy } from "lodash";
import * as Tooltip from "@radix-ui/react-tooltip";
import Date, { DateDisplayType } from "@/components/date";
import { OrderItemPill } from "@/components/order-item-pill";
import { InvoicePill } from "@/components/invoice-pill";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { Order } from "@/entities/order";
import { Paginated } from "@/api/base";
import StyledLink from "@/components/styled-link";
import { Table, TableColumn, TableRow } from "@/components/table";
import { MoneyDisplay } from "@/components/money-display";
import type { CompanyDetailsData } from "./loader";

export default function Orders() {
  const { orders } = useLoaderData() as CompanyDetailsData;

  return (
    <section>
      <p className="font-medium text-2xl">Orders</p>

      <div className="mt-4">
        {orders instanceof FailedOptionalResult ? <ErrorState /> : <OrderList />}
      </div>
    </section>
  );
}

function OrderList() {
  const { orders: initialOrders } = useLoaderData() as CompanyDetailsData & {
    orders: Exclude<CompanyDetailsData["orders"], FailedOptionalResult>;
  };

  const fetcher = useFetcher<{ orders: Paginated<Order[]> }>();
  const [orders, setOrders] = useState<Paginated<Order[]>>(initialOrders);

  useEffect(() => {
    if (!fetcher.data) {
      return;
    }

    setOrders({
      ...fetcher.data.orders,
      data: uniqBy([...orders.data, ...fetcher.data.orders.data], "id"),
    });
  }, [fetcher.data]);

  return (
    <Table
      columns={[
        { label: "ID" },
        { label: "Sale date" },
        { label: "Items" },
        { label: "Invoices" },
        { label: "Amount", rightAligned: true },
      ]}
    >
      <tbody>
        {orders.data.map((order) => (
          <TableRow key={order.id}>
            <TableColumn>
              <StyledLink to={`/orders/${order.id}`}>#{order.numericId}</StyledLink>
            </TableColumn>

            <TableColumn>
              {order.isTransferOrder ? (
                <Tooltip.Root delayDuration={300}>
                  <Tooltip.Trigger asChild>
                    <span>Synthetic</span>
                  </Tooltip.Trigger>

                  <Tooltip.Portal>
                    <Tooltip.Content sideOffset={4}>
                      <div className="w-60 inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2">
                        <span>{order.notes}</span>
                      </div>
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              ) : (
                <Date date={order.createdAt} display={DateDisplayType.DATE} />
              )}
            </TableColumn>

            <TableColumn>
              <div className="flex flex-wrap gap-2 items-center">
                {order.items.map((item) =>
                  item.isDistributed || !item.product?.isDigitalHeadhunting ? (
                    <OrderItemPill key={`order-${order.id}-item-${item.id}`} item={item} />
                  ) : (
                    <Link key={`order-${order.id}-item-${item.id}`} to={`/order-items/${item.id}`}>
                      <OrderItemPill item={item} />
                    </Link>
                  ),
                )}
              </div>
            </TableColumn>

            <TableColumn>
              <div className="flex flex-wrap gap-2 items-center">
                {order.invoices.map((invoice) => (
                  <InvoicePill key={`order-${order.id}-item-${invoice.id}`} invoice={invoice} />
                ))}
              </div>
            </TableColumn>

            <TableColumn numeric rightAligned>
              {order.isTransferOrder ? <span>-</span> : <MoneyDisplay amount={order.itemsTotal} />}
            </TableColumn>
          </TableRow>
        ))}

        {orders.meta.total === 0 && (
          <TableRow>
            <TableColumn colSpan={5}>
              <p className="italic text-sm">This company does not have any orders.</p>
            </TableColumn>
          </TableRow>
        )}
      </tbody>

      {orders.meta.total > 0 && <LoadMoreButton meta={orders.meta} fetcher={fetcher} />}
    </Table>
  );
}

function LoadMoreButton({
  meta,
  fetcher,
}: {
  meta: Paginated<Order[]>["meta"];
  fetcher: ReturnType<typeof useFetcher>;
}) {
  const params = useParams();

  return (
    <tfoot>
      <tr className="text-center border-t">
        <td colSpan={6}>
          {meta.current_page >= meta.last_page ? (
            <p className="block p-4 w-full bg-zinc-50 font-medium text-zinc-600">
              All orders shown
            </p>
          ) : (
            <fetcher.Form method="get" action={`/companies/${params.company}/orders`}>
              <input type="hidden" name="page" value={meta.current_page + 1} />
              <button
                type="submit"
                className="block p-4 w-full bg-zinc-50 font-medium text-zinc-600 disabled:opacity-60 enabled:hover:bg-cyan-50 enabled:hover:text-cyan-800"
                style={{ fontVariantCaps: "small-caps" }}
                disabled={fetcher.state !== "idle"}
              >
                {fetcher.state !== "idle" ? "Loading..." : "Load more"}
              </button>
            </fetcher.Form>
          )}
        </td>
      </tr>
    </tfoot>
  );
}

function ErrorState() {
  return (
    <div className="mt-8 text-red-600">
      <p className="font-bold text-4xl">N/A</p>
      <p className="mt-2 opacity-75">Could not load orders.</p>
    </div>
  );
}
