import { Suspense, useCallback } from "react";
import {
  ActionFunctionArgs,
  Await,
  Form,
  redirect,
  useActionData,
  useAsyncValue,
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import * as Yup from "yup";
import { Dialog } from "@/components/dialog";
import SubmitButton from "@/components/submit-button";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import SecondaryButton from "@/components/secondary-button";
import SelectBox from "@/components/forms/select-box";
import { User } from "@/entities/user";
import { updateOrder } from "@/api/order";
import { processYupErrors } from "@/helpers/yup-errors";
import { ErrorBlock } from "@/components/error-block";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.order);

  let input;

  try {
    const inputValues = Object.fromEntries((await request.formData()).entries());
    input = await Yup.object()
      .shape({
        seller1: Yup.string().required("You must choose a first seller."),
        seller2: Yup.string().notRequired(),
        seller3: Yup.string().notRequired(),
      })
      .validate(inputValues, { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    let dealOwnerAmount = +input.amount * 0.6;
    if (!input.seller2) {
      dealOwnerAmount += +input.amount * 0.25;
    }
    if (!input.seller3) {
      dealOwnerAmount += +input.amount * 0.15;
    }
    const users = [
      {
        user_id: +input.seller1,
        amount: { amount: dealOwnerAmount, currency: input.currency },
      },
    ];

    if (input.seller2) {
      users.push({
        user_id: +input.seller2,
        amount: { amount: +input.amount * 0.25, currency: input.currency },
      });
    }
    if (input.seller3) {
      users.push({
        user_id: +input.seller3,
        amount: { amount: +input.amount * 0.15, currency: input.currency },
      });
    }

    await updateOrder(params.order, {
      users,
    });

    return redirect(`/orders/${params.order}`);
  } catch (e) {
    return { status: 500 };
  }
}

export default function OrderSalesSplitDialog() {
  const { order, jobillaUsers } = useRouteLoaderData("order-details") as OrderDetailsData;
  const actionResult = useActionData() as Awaited<ReturnType<typeof action>> | undefined;

  const transition = useNavigation();
  const navigate = useNavigate();
  const params = useParams();

  const hasUnexpectedErrors = actionResult?.status === 500;
  const isSubmitting = transition.state === "submitting";

  const navigateToOrder = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/orders/${params.order}`);
    }
  }, []);

  return (
    <Dialog isOpen title={`Change sellers of order #${order.numericId}`}>
      <Form method="post">
        <fieldset disabled={isSubmitting}>
          <Suspense fallback={<div>Loading user list...</div>}>
            <Await resolve={jobillaUsers}>
              <SellersForm />
            </Await>
          </Suspense>

          {hasUnexpectedErrors && (
            <ErrorBlock className="mt-6">
              Could not change the sellers of the order. Please try again. If the problem persists,
              contact support.
            </ErrorBlock>
          )}

          <div className="mt-8 flex items-center justify-center space-x-4">
            <SubmitButton label="Change split" labelWhenSubmitting="Changing..." size="lg" />

            <SecondaryButton type="button" onClick={navigateToOrder} size="lg">
              Cancel
            </SecondaryButton>
          </div>
        </fieldset>
      </Form>
    </Dialog>
  );
}

function SellersForm() {
  const { order } = useRouteLoaderData("order-details") as OrderDetailsData;
  const actionResult = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;
  const jobillaUsers = useAsyncValue() as User[];
  const orderTotal = order.itemsTotal.amount;
  const currency = order.itemsTotal.currency;

  return (
    <div className="space-y-6">
      <SelectBox
        label="Deal owner (60%)"
        id="seller1"
        name="seller1"
        defaultValue={order.users[0].userId}
        errors={actionResult?.errors?.seller1}
      >
        {jobillaUsers.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
      </SelectBox>

      <SelectBox
        label="Executive (25%)"
        id="seller2"
        name="seller2"
        defaultValue={order.users[1]?.userId}
        errors={actionResult?.errors?.seller2}
      >
        <option value="">None</option>
        {jobillaUsers.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
      </SelectBox>

      <SelectBox
        label="SDR (15%)"
        id="seller3"
        name="seller3"
        defaultValue={order.users[2]?.userId}
        errors={actionResult?.errors?.seller3}
      >
        <option value="">None</option>
        {jobillaUsers.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
      </SelectBox>

      <input type="hidden" name="amount" value={orderTotal} />
      <input type="hidden" name="currency" value={currency} />
    </div>
  );
}
