import { json } from "react-router-dom";

export function assertParameterExists(parameter: string | undefined): asserts parameter is string {
  if (parameter === undefined) {
    throw json(null, 404);
  }
}

export function assertParameterIsNumeric(
  parameter: string | undefined,
): asserts parameter is string {
  assertParameterExists(parameter);

  if (!isFinite(+parameter)) {
    throw json(null, 404);
  }
}
