import { Form, useSubmit } from "react-router-dom";
import { FormHTMLAttributes, ReactNode } from "react";
import { FieldValues, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export type ValidatedFormProps = {
  children: ReactNode;
  formSchema: Yup.ObjectSchema<FieldValues>;
} & FormHTMLAttributes<typeof Form>;

export function ValidatedForm(
  { formSchema, children, className }: ValidatedFormProps,
  ...props: Parameters<typeof Form>
) {
  type FormData = Yup.InferType<typeof formSchema>;
  const resolver = yupResolver(formSchema);
  const { handleSubmit, ...methods } = useForm<FormData>({ resolver: resolver });

  const submit = useSubmit();

  return (
    <FormProvider handleSubmit={handleSubmit} {...methods}>
      <Form
        className={className}
        onSubmit={(event) => {
          const target = event.currentTarget;
          handleSubmit(() => {
            submit(target, { method: "post" });
          })(event);
        }}
        {...props}
      >
        {children}
      </Form>
    </FormProvider>
  );
}
