import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { object, string } from "yup";
import { HTTPError } from "ky";
import { processYupErrors } from "@/helpers/yup-errors";
import { updateUserRole } from "@/api/user";
import { assertParameterExists } from "@/helpers/loader-guards";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.user);
  let input;

  try {
    input = await validationSchema.validate(Object.fromEntries(await request.formData()), {
      abortEarly: false,
    });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    return { status: 500 };
  }

  try {
    await updateUserRole(+params.user, input.role);

    // Make the user wait just a bit extra to make sure the async update has
    // been processed. Otherwise user's role ends up `undefined` in the UI.
    await new Promise((resolve) => setTimeout(resolve, 5000));

    return { status: 200 };
  } catch (e) {
    if (!(e instanceof HTTPError)) {
      return {
        status: 500,
        message: e instanceof Error ? e.message : "Unexpected error",
      };
    }

    const responseData = await e.response.json();

    if (e.response.status === 422) {
      return {
        status: 422,
        errors: responseData.errors,
      };
    }

    return { status: 500, message: responseData.message };
  }
}

const validationSchema = object({
  role: string().required(),
}).required();
