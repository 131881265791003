import { PropsWithChildren } from "react";
import { clsx } from "clsx";

export function ErrorBlock(props: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={clsx(
        props.className,
        "bg-red-100 text-red-600 text-sm font-medium py-3 px-4 rounded-lg",
      )}
    >
      {props.children}
    </div>
  );
}
