import { Entity } from "@jobilla/entity";

export class NetsuiteAttachment extends Entity {
  id!: string;
  reference!: string;
  url!: string;
  type!: string;
  source!: string;
  createdAt!: string;
}
